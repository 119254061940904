import styled from "styled-components";
import Scaffold from "../../../scaffold/scaffold.component";
import { device } from "../../../../utils/styled/breakpoints";
import PromoBox from "../../../promo-box/promo-box.component";
import { grabbitColor } from "../../../../utils/styled/colors";

export const BottomContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;

  @media ${device.inBetweenMax}, ${device.inBetween}, ${device.tablet} {
    flex-direction: column;
  }

  @media ${device.laptopL} {
    flex-direction: row;
    padding: 16px;
  }
    
`

export const FourthScaffold = styled(Scaffold)`
    width: 100%;
    height: 100%;
    flex: 0 1.6 auto;
`

export const GrabbPromo = styled(PromoBox)`
    width: 100%;
    height: 100%;
    display: flex;
    flex: 0 1.6 auto;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    background-color: ${grabbitColor.enduranceDarkGray};
    padding: 16px;

    @media ${device.tablet} {
        margin: 0;
    }

    @media ${device.laptopL} {
        margin: 16px 16px 0 16px ;
    }
`