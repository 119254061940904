import React, { useState, useEffect } from 'react';
import moment from 'moment-timezone';
import { TimerContainer, Hours, Minutes, Seconds } from './countdown-timer.styles';

export const TIMER_FORMATS = {
  DAYS: 'DAYS',
  HOURS: 'HOURS',
  MINUTES: 'MINUTES',
  SECONDS: 'SECONDS',
};

const timerFormat = (days = 0, hours = 0, min = 0, sec = 0, mil = 0, format = TIMER_FORMATS.MINUTES) => {
  switch (format) {
    case 'DAYS':
      return (
        <div>
          <Hours>{days}</Hours>:
          <Hours>{hours}</Hours>:
          <Minutes>{min}</Minutes>:
          <Seconds>{sec}</Seconds>:
          <Seconds>{mil}</Seconds>
        </div>
      );
    case 'HOURS':
      return (
        <div>
          <Hours>{hours}</Hours>:
          <Minutes>{min}</Minutes>:
          <Seconds>{sec}</Seconds>:
          <Seconds>{mil}</Seconds>
        </div>
      );
    case 'MINUTES':
      return (
        <div>
          <Minutes>{min}</Minutes>:
          <Seconds>{sec}</Seconds>:
          <Seconds>{mil}</Seconds>
        </div>
      );
    case 'SECONDS':
      return (
        <div>
          <Seconds>{sec}</Seconds>:
          <Seconds>{mil}</Seconds>
        </div>
      );
    default:
      return (
        <div>
          <Minutes>{min}</Minutes>:
          <Seconds>{sec}</Seconds>:
          <Seconds>{mil}</Seconds>
        </div>
      );
  }
};

const CountdownTimer = ({ endTime, format, ...otherProps }) => {
  const [timeLeft, setTimeLeft] = useState(0);
  const intervalDelay = 110;

  useEffect(() => {
    const intervalId = setInterval(() => {
      const newTimeLeft = Math.max(moment(endTime).tz(Intl.DateTimeFormat().resolvedOptions().timeZone).valueOf() - Date.now(), 0);
      setTimeLeft(newTimeLeft);

      // Stop the timer once timeLeft reaches 0
      if (newTimeLeft <= 0) {
        clearInterval(intervalId);
      }
    }, intervalDelay);

    return () => clearInterval(intervalId);
  }, [endTime]);

  const days = Math.floor(timeLeft / (1000 * 60 * 60 * 24));
  const hours = Math.floor((timeLeft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  const minutes = Math.floor((timeLeft % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((timeLeft % (1000 * 60)) / 1000).toString().padStart(2, '0');
  const milliseconds = (timeLeft % 100).toString().padStart(2, '0');

  return (
    <TimerContainer {...otherProps}>
      {timeLeft >= 1 ? timerFormat(days, hours, minutes, seconds, milliseconds, format) : ''}
    </TimerContainer>
  );
};

export default CountdownTimer;