import styled from "styled-components";
import { ReactComponent as GrabbitLogo } from '../../assets/logo.svg';
import { grabbitColor } from "../../utils/styled/colors";

export const SpinnerBackground = styled.div `
  height: 100vh;
  width: auto;
`;

export const SpinnerOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9000;
`;

export const SpinnerContainer = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border-top-color: ${grabbitColor.enduranceLightGray};
  animation: pulse 1s ease-in-out infinite, spin 1s linear infinite;
  -webkit-animation: pulse 1s ease-in-out infinite, spin 1s linear infinite;
  position: relative;
  z-index: 10000;

  svg {
    width: 100%;
    height: 100%;
  }

  @keyframes pulse {
    0% {
      transform: scale(1);
    }
    25% {
      transform: scale(2);
    }
    50% {
      transform: scale(1);
    }
    75% {
      transform: scale(2);
    }
    100% {
      transform: scale(1);
    }
  }
  @-webkit-keyframes pulse {
    0% {
      transform: scale(1);
    }
    25% {
      transform: scale(1.3);
    }
    50% {
      transform: scale(1);
    }
    75% {
      transform: scale(1.3);
    }
    100% {
      transform: scale(1);
    }
  }

  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }

  @-webkit-keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }
`;

export const Logo = styled(GrabbitLogo)`
  width: auto;
  height: 500px;
  fill: ${({ color }) => color || `${grabbitColor.warpWhite}`};
`
