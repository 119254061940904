//React Components

//Modal Components
import SoldOutDisplay from "./displays/sold-out-display/sold-out-display.component";
import PauseDisplay from "./displays/pause-display/pause-display.component";
import NoGrabbsDisplay from "./displays/no-grabbs-display/no-grabbs-display.component";
import InactiveDisplay from "./displays/inactive-dispaly/inactive-sale-display.component";
import CountDownDisplay from "./displays/countdown-display/countdown-display.component";
import DefaultDisplay from "./displays/default-display/default-grabb-display.component";

//Price Components
import { useRealtimeData } from "../../utils/firebase/realtime-database.utils";
import { logger } from "../../utils/logger/logger.utils";
import { RenderDisplayWrapper } from "./grabb-card.styles";
import { useSelector } from "react-redux";
import { selectCartCount } from "../../store/cart/cart.selector";


const GrabbCard = () => {

  const cartCount = useSelector(selectCartCount);

  //Realtime Data subscriptions
  const active = useRealtimeData({collectionName: "currentsale", documentName: "active"})
  const status = useRealtimeData({collectionName: "currentsale", documentName: "status/currentStatus"})

  const loaded = active && status
  
  const renderDisplay = () => {
    if(!loaded) return <DefaultDisplay />;

    else if (!active) return <InactiveDisplay />

    else if (status === "soldOut") return <SoldOutDisplay />;
    
    else if (status === "countdown") return <CountDownDisplay />

    else if (status === "pause" &&  cartCount < 1) return <PauseDisplay />;
    
    else return <DefaultDisplay />;
  }

  return <RenderDisplayWrapper>{renderDisplay()}</RenderDisplayWrapper>;
};

export default GrabbCard;