import React, {useEffect} from 'react'
import { ScaffoldingObject } from './scaffold.styles';
import { logger } from '../../utils/logger/logger.utils';

const ScaffoldRenderSwitch = ({ className, ...props }) => {
    switch (props.type) {
        case 'text':
            return (
                <ScaffoldingObject className={className} width={'100%'} height={'24px'} radius={'8px'} margin={props.margin} />
            );
        case 'heading':
            return (
                <ScaffoldingObject className={className} width={'100%'} height={'40px'} radius={'8px'} margin={props.margin} />
            );
        case 'button':
                return (
                    <ScaffoldingObject className={className} width={'100%'} height={'55px'} radius={'0px'} margin={props.margin ? props.margin : '16px auto'} />
                );
        case 'circle':
            return (
                <ScaffoldingObject className={className} width={props.size} height={props.size} radius={'100%'} margin={props.margin} />
            );
        case 'paragraph':
            return (
                <>
                    <ScaffoldingObject className={className} width={'100%'} height={'24px'} align={props.align} radius={'8px'} margin={props.margin} />
                    <ScaffoldingObject className={className} width={'80%'} height={'24px'} align={props.align} radius={'8px'} margin={props.margin} />
                    <ScaffoldingObject className={className} width={'90%'} height={'24px'} align={props.align} radius={'8px'} margin={props.margin} />
                </>
            );
        case 'blank':
            return (
                <ScaffoldingObject className={className} style={props.style} type={'blank'} backgroundColor={'transparent'} width={props.width} height={props.height} margin={props.margin}  >
                    {props.children}
                </ScaffoldingObject>
            );
        default:
            return (
                <ScaffoldingObject className={className} style={props.style} width={props.width} height={props.height} margin={props.margin} >
                    {props.children}
                </ScaffoldingObject>
            );
    }
}

const Scaffold = ({ className, ...props }) => {
    
    return (
        <ScaffoldRenderSwitch 
            type={props.type} 
            width={props.width} 
            height={props.height} 
            radius={props.radius} 
            align={props.align} 
            children={props.children}
            margin={props.margin}
            style={props.style}
            className={className}
        />
    )
}

export default Scaffold