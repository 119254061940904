import styled from "styled-components";
import { grabbitColor } from "../../utils/styled/colors";
import Button from "../../components/button/button.component";
import { device } from "../../utils/styled/breakpoints";

export const SneakPeekContainer = styled.div`
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 50vw;
  margin: 0rem 2rem 2rem;
  border: 1px solid ${grabbitColor.flashYellow};


  @media ${device.tabletMax} {
    margin-top: 3rem;
    width: 80vw;
  }
`;

export const SneakPeekiFrame = styled.div`
  display: block;
  border: 1.5px solid ${grabbitColor.flashYellow};
  border-radius: 1rem;
  z-index: 100;
  margin: 1rem;
  position: relative;
  top: -5rem;
  position: relative;
  width: 70%;
  height: 0;
  padding-bottom: 70.25%;
  overflow: hidden;

`

export const VideoiFrame = styled.iframe`
  position: absolute;  
  width: 100%;
  height: 100%;

`;

export const SneakInfoContainer = styled.div`
  margin: -5rem 2rem 0rem;
  text-align: center;

  h1 {
    font-size: 2rem;
    margin: .5rem 0rem .5rem;
  }
  
  span {
    font-size: .9rem;
  }

`;

export const SneakJoinButton = styled(Button)`
  z-index: 10;
  position: relative;
  bottom: -4rem;
  width: 70%;
  font-size: .7rem;
  margin-top: 0rem;

  @media ${device.tablet} {
    width: 30vw;
  }

  @media ${device.mobileL} {
    font-size: 1rem;
  }

`;