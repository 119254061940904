import styled from "styled-components";
import { grabbitColor } from "../../../../utils/styled/colors";
import { grabbitFont } from "../../../../utils/styled/fonts";

export const DisplayContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 350px;
  height: 55vh;
  margin: -3rem 0rem -1rem;
  text-align: center;
  align-items: center;
  justify-content: center;
  background: none;
`;

export const CountDownDisplayWrapper = styled.div`
  background: ${grabbitColor.warpWhite};
  border-radius: 0px 0px 30px 30px;
  background: ${grabbitColor.flashYellow};
  color: ${grabbitColor.concentration};
  flex: 3;
  padding: 1.5rem 5.5rem 0rem;
  
  div {
    text-align: center;
    margin: auto;
    font-weight: 700;
    margin-bottom: -2rem;
  }

  span {
    display: block;
    text-align: center;
    margin: auto;
    font-size: 1.15rem;
    margin-bottom: 2rem;
  }
`;

export const CountDownDisplayHeader = styled.h2`
  font-size: 1.25rem;
  color: ${grabbitColor.concentration};
  margin-bottom: -4rem;
  font-weight: 700;
`;

export const CountDownNumber = styled.div`
  @keyframes countdownRotate {
    0% {
      transform: rotate(-90deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }

  animation-name: countdownRotate;
  animation-duration: 5s;
  animation-fill-mode: forwards;
  animation-timing-function: linear;
  font-size: 14rem;
  font-family: ${grabbitFont.mainGrabbitNumberFont};
  color: ${grabbitColor.ultrapatiencePurple};
  -webkit-text-fill-color: ${grabbitColor.flashYellow};
  text-shadow: -3px -3px 0 ${grabbitColor.ultrapatiencePurple}, 3px -3px 0 ${grabbitColor.ultrapatiencePurple}, -3px 3px 0 ${grabbitColor.ultrapatiencePurple}, 3px 3px 0 ${grabbitColor.ultrapatiencePurple};
`;

export const CountDownBottom = styled.div`
  color: ${grabbitColor.concentration};
  font-size: 1.25rem;
  margin-bottom: 2rem;
  width: 70%;
`;

export const BottomInfoWrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding-top: 1.5rem;
  justify-content: center;
  background: ${grabbitColor.flashYellow};
  border-radius: 30px 30px 0px 0px;
  flex: 1;
  width: 100%;
  
  svg {
    color: black;
  }
`;