import styled from "styled-components";
import { grabbitFont } from "../../../utils/styled/fonts";
import { grabbitColor } from "../../../utils/styled/colors";
import {device} from "../../../utils/styled/breakpoints"
import Scaffold from "../../scaffold/scaffold.component";

export const WatcherContainer = styled.div`
  display: flex;
  width: 50%;
  flex-direction: column;
  align-items: center;
  position: relative;
  font-family: ${grabbitFont.mainGrabbitNumberFont};
  margin: 10px 0px 25px 0px;
  padding: 16px;
  background: ${grabbitColor.enduranceDarkGray};
  border-radius: 24px;

  h3 {
    margin: 0 0;
    width: 50%;
    padding-left: 1rem;
  }

  .watchers {
    margin: 0px;
    font-size: 35px;
    font-weight: bold;

    @media ${device.mobileMax} {
      font-size: 24px;
  }
  }

  @media ${device.mobileM}, ${device.mobileMax} {
    width: 100%;
    justify-content: center;
    text-align: center;
  }
  @media ${device.tablet}, ${device.mobileM}, ${device.mobileMax} {
    flex-direction: row;
    width: 100%;
  }
  @media ${device.mobileMax} {
    font-size: 16px;
  }
`;

export const ButtonContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    h5 {
        margin: 0;
    }


`

export const ButtonsScaffold = styled(Scaffold)`
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0;
    border-radius: 0;

    h5 {
        margin: 0;
    }
`
