import PageOne from '../../components/onboard-pages/page-one.component';
import PageTwo from '../../components/onboard-pages/page-two.component';
import PageThree from '../../components/onboard-pages/page-three.component';
import PageFour from '../../components/onboard-pages/page-four.component';

import { 
  OnboardContainer,
} from './onboard-flow.styles';

import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setOnBoardingStatus } from '../../store/user/user.reducer';
import { useNavigate } from 'react-router';
import MultiStepProgressBar from '../../components/multistep-progress/multistep-progress.component';

const OnboardFlow = () => {
  const [page, setPage] = useState("pageone");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const nextPage = (page) => {
    setPage(page);
  };

  const onBoardComplete = () => {
    dispatch(setOnBoardingStatus(true));
    navigate("/");
  };

  const nextPageNumber = (pageNumber) => {
    switch (pageNumber) {
      case "1":
        setPage("pageone");
        break;
      case "2":
        setPage("pagetwo");
        break;
      case "3":
        setPage("pagethree");
        break;
      case "4":
        setPage("pagefour");
        break;
      default:
        setPage("1");
    }
  };

  useEffect(() => {
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
  }, []);

  return (
    <OnboardContainer>
        <MultiStepProgressBar page={page} onPageNumberClick={nextPageNumber} />
      <div>
      {
        {
          pageone: <PageOne onButtonClick={nextPage} onBoardComplete={onBoardComplete} />,
          pagetwo: <PageTwo onButtonClick={nextPage} onBoardComplete={onBoardComplete} />,
          pagethree: <PageThree onButtonClick={nextPage} onBoardComplete={onBoardComplete} />,
          pagefour: <PageFour onBoardComplete={onBoardComplete}/>,
        }[page]
      }
    </div>
    </OnboardContainer>
  )
}

export default OnboardFlow;