import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useSelector, useDispatch } from 'react-redux';
import { selectCurrentUser } from '../../../store/user/user.selector';

import { buyNow, addProductToCart } from '../utility-functions/cart.utils';
import { useRealtimeData } from '../../../utils/firebase/realtime-database.utils';

import Button, { BUTTON_TYPE_CLASSES } from '../../button/button.component';

import ViewCounter from '../../view-counter/view-counter.component';

import { ButtonContainer, ButtonsScaffold } from './top-info.styles';
import { logger } from '../../../utils/logger/logger.utils';
import Scaffold from '../../scaffold/scaffold.component';
import FetchTheProductImages from '../utility-functions/image.utils';

const TopInfoContainer = () => {

  const [isLoading, setIsLoading] = useState(true);
  const [imageUrl,setImageUrl] = useState('');
  const active = true
  const currentSaleProduct = useRealtimeData({collectionName: "currentsale", documentName: "product"})
  const user = useSelector(selectCurrentUser);
  const currentPrice = useRealtimeData({collectionName:'currentsale', documentName:'currentprice'});

  const { 
    name,
    cart_image,
    taxCode,
    id,
    checkout_fields,
    shippingCost,
    buyItNowPrice } = currentSaleProduct || {};

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchImages = async () => {
      try {
        const result = await FetchTheProductImages(cart_image);
        setImageUrl(result);
      } catch (error) {
        logger.error('Error fetching images:', error);
      }
    };
    fetchImages();
  }, [cart_image]);

  const handleBuyNowClick = () => {
    buyNow(user, dispatch, name, buyItNowPrice, imageUrl, taxCode, id, checkout_fields, shippingCost);
  };

  const handleAddProductToCart = () => {
    addProductToCart(navigate, dispatch, name, currentPrice, imageUrl, taxCode, id, checkout_fields, shippingCost);
  };

  //Short load timer to prevent layout shift
  useEffect(() => {
    setIsLoading(true);
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 1250);
    return () => clearTimeout(timer);
  }, []);

  return (
    <>
    {isLoading ? 
    <ButtonsScaffold type={'blank'} >
    <Scaffold type={'button'} />
    <h5>— OR —</h5>
    <Scaffold type={'button'} />
    <Scaffold width={'100%'} height={'115px'} margin={'2rem'} padding={'0 24px 24px 24px'}/>
  </ButtonsScaffold>
        :
    <ButtonContainer>
          {active && (
            <>
              {buyItNowPrice ? (
                <>
                  <Button
                    onClick={currentPrice >= buyItNowPrice ? handleBuyNowClick : undefined}
                    buttonType={currentPrice >= buyItNowPrice ? BUTTON_TYPE_CLASSES.secondary : BUTTON_TYPE_CLASSES.disabled}
                    width={'100%'}>
                    Buy Now for ${buyItNowPrice}
                  </Button>
                  <h5>— OR —</h5>
                  <Button
                    onClick={currentPrice <= buyItNowPrice ? handleAddProductToCart : undefined}
                    buttonType={currentPrice <= buyItNowPrice ? BUTTON_TYPE_CLASSES.base : BUTTON_TYPE_CLASSES.disabled}
                    width={'100%'}>
                    {currentPrice <= buyItNowPrice ? 'GRABBIT!' : 'wait to Grabbit & save more'}
                  </Button>
                </>
              ) : (
                <Button onClick={handleAddProductToCart} width={'100%'}>GRABBIT!</Button>
              )}
            </>
          )}
          {active && <ViewCounter />}
    </ButtonContainer>
}
    </>

  );

};

export default TopInfoContainer;
