import { createSlice } from '@reduxjs/toolkit';

const MODAL_INITIAL_STATE = {
  timeoutModal: false,
  menuIsOpen: false,
};

export const modalsSlice = createSlice({
    name: 'modal',
    initialState: MODAL_INITIAL_STATE,
    reducers: {
      toggleModalState: (state, action) => {
        const modalName = action.payload;
        if (state.hasOwnProperty(modalName)) {
          state[modalName] = !state[modalName];
        } else {
          throw new Error(`Modal ${modalName} does not exist in state`);
        }
      },
      setModalClosed: (state, action) => {
        const modalName = action.payload;
        if (state.hasOwnProperty(modalName)) {
          state[modalName] = false;
        } else {
          throw new Error(`Modal ${modalName} does not exist in state`);
        }
      },
      setModalOpen: (state, action) => {
        const modalName = action.payload;
        if (state.hasOwnProperty(modalName)) {
          state[modalName] = true;
        } else {
          throw new Error(`Modal ${modalName} does not exist in state`);
        }
      }
    },
  });
  
  
export const {
  toggleModalState,
  setModalClosed,
  setModalOpen
} = modalsSlice.actions;

export const modalsReducer = modalsSlice.reducer;
