import { ConfirmationContainer } from "./email-link-confirmation.styles"
import { WhiteLogo } from "../authentication.styles";

const EmailLinkConfirmation = () => {
  let email = window.localStorage.getItem('emailForSignIn');

  return (
    <ConfirmationContainer>
      <WhiteLogo />
      <h1>Success!</h1>
      <p>
        We sent an email sign-in link to <b>{email}.</b> It may take a few minutes to appear or you may need to check your junk mail folder. Click the link in the email to sign in.
      </p>
    </ConfirmationContainer>
  )
}

export default EmailLinkConfirmation