import React, { useState, useEffect } from 'react';
import { DescriptionContainer, DescriptionScaffold, DescriptionScrollPanel, Heading } from './grabb-description.styles';
import TabView from '../../tab-view/tab-view.component';
import { ScrollPanel } from 'primereact/scrollpanel';
import ReactMarkdown from 'react-markdown';
import { useSelector } from 'react-redux/es/hooks/useSelector';
import Scaffold from '../../scaffold/scaffold.component';
import { useRealtimeData } from '../../../utils/firebase/realtime-database.utils';

const MarkdownRenderer = ({ content }) => {
    return <ReactMarkdown>{content}</ReactMarkdown>;
  };

const GrabbDescription = ({descriptionData}) => {
  const [isLoading, setIsLoading] = useState(true);
  const currentSaleProduct = useRealtimeData({collectionName: "currentsale", documentName: "product"})

  
  const {
    descriptionHeading, 
    description, 
    specs
  } = currentSaleProduct || {};

  const tabData = [
    {
      heading: 'Description',
      body: <>
            <Heading>{descriptionData ? descriptionData.descriptionHeading : descriptionHeading}</Heading>
            <MarkdownRenderer content={descriptionData ? descriptionData.description : description} />
            </>
    },
    ...(specs ? [{ heading: 'Specs', body: <MarkdownRenderer content={descriptionData ? descriptionData.specs : specs} /> }] : [])
  ];

    //Short load timer to prevent layout shift
    useEffect(() => {
      setIsLoading(true);
      const timer = setTimeout(() => {
        setIsLoading(false);
      }, 1250);
      return () => clearTimeout(timer);
    }, []);

  return (
    <DescriptionContainer>
      {isLoading ? 
      <DescriptionScaffold type={'blank'} >
      <DescriptionScaffold />
    </DescriptionScaffold>
      
      :
      <>
        {tabData ? <TabView tabData={tabData} /> : ''}
      </>
    }
    </DescriptionContainer>
  );
};

export default GrabbDescription;
