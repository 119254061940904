import { initializeApp } from 'firebase/app';
import {
  getAuth,
  getIdTokenResult,
  signInWithRedirect,
  signInWithPopup,
  GoogleAuthProvider,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
  onAuthStateChanged,
  sendPasswordResetEmail,
  confirmPasswordReset,
  sendSignInLinkToEmail,
  isSignInWithEmailLink,
  signInWithEmailLink,
  linkWithCredential,
  EmailAuthProvider,
} from 'firebase/auth';

import {
  getFirestore,
  doc,
  getDoc,
  setDoc,
  updateDoc,
  collection,
  query,
  getDocs,
} from 'firebase/firestore';

import { addToMailchimpAudience } from '../email/mailchimp.utils';
import { logger } from '../logger/logger.utils';

export const USER_ROLES = {
  guest: 1,
  user: 2,
  admin: 3,
  super: 4,
};


// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: "752720519034",
  appId: process.env.REACT_APP_APP_ID,
  measurementId: "G-4NE9Q3C3BQ"
};

export const firebaseApp = initializeApp(firebaseConfig);

const googleProvider = new GoogleAuthProvider();

googleProvider.setCustomParameters({
  prompt: 'select_account',
});

export const auth = getAuth();
export const db = getFirestore();

export const signInWithGooglePopup = () =>
  signInWithPopup(auth, googleProvider);

export const signInWithGoogleRedirect = () =>
  signInWithRedirect(auth, googleProvider);


export const getCollectionAndDocuments = async (collectionKey) => {
  const collectionRef = collection(db, collectionKey);
  const q = query(collectionRef);

  const querySnapshot = await getDocs(q);

  return querySnapshot.docs.map(docSnapshot => ({
    id: docSnapshot.id,
    collectionName: collectionKey,
    ...docSnapshot.data()
  }));
}


export const createUserDocumentFromAuth = async (
    userAuth,
    additionalInformation = {}
  ) => {
    if (!userAuth) return;
  
    const userDocRef = doc(db, 'users', userAuth.uid);
    const userSnapshot = await getDoc(userDocRef);

    if (!userSnapshot.exists()) {
      const { displayName, email } = userAuth;
      const createdAt = new Date();
      const { firstName, lastName } = additionalInformation;
  
      try {
        await setDoc(userDocRef, {
          displayName,
          email,
          createdAt,
          role: 'user',
          grabbsLeft: 3,
          onBoarding: false,
          cart: [],
          ...additionalInformation,
        })        
        
        await addToMailchimpAudience(email, firstName, lastName)
  
      } catch (error) {
        logger.log('error creating the user', error.message);
      }
    }
  
    // Return userSnapshot again to ensure you always return the document
    return await getDoc(userDocRef);
};
  

export const createAuthUserWithEmailAndPassword = async (email, password) => {
  if (!email || !password) return;

  return await createUserWithEmailAndPassword(auth, email, password);
};

export const signInAuthUserWithEmailAndPassword = async (email, password) => {
  if (!email || !password) return;

  const result = await signInWithEmailAndPassword(auth, email, password);
  return result;
};


export const signOutUser = async () => await signOut(auth);

export const onAuthStateChangedListener = (callback) =>
  onAuthStateChanged(auth, callback);

export const getCurrentUser = () => {
  return new Promise((resolve, reject) => {
    const unsubscribe = onAuthStateChanged(
      auth,
      (userAuth) => {
        unsubscribe();
        resolve(userAuth);
      },
      reject
    );
  });
};


export const updateUserDocument = async (auth, field, value) => {
  try {
    const userDocRef = doc(db, 'users', auth.uid);
    // const userSnapshot = await getDoc(userDocRef);

    await updateDoc(userDocRef, {
      [field]: value
    });
    logger.log("Document updated successfully");
  } catch (error) {
    logger.error("Error updating document: ", error);
    throw error; // re-throw the error so it can be handled by the caller
  }
};


export const sendResetPassword = (email) => {
  sendPasswordResetEmail(auth, email)
    .then(() => {
      logger.log("Reset password sent successfully");
    })
    .catch((error) => {
      logger.log("Reset password failed:", error.code, error.message);
    });
};


export const CustomResetPassword = async (actionCode, password) => {
  try {
    return await confirmPasswordReset(auth, actionCode, password);
  } catch (error) {
    return(`Error: ${error.message}`);
  }
};

// Function to check user role
export const checkUserRole = async () => {
  const user = auth.currentUser;

  if (user) {
    const idTokenResult = await getIdTokenResult(user);
    const role = idTokenResult.claims.role;

    if (role === 4) {
      logger.log('User is an super admin');
    } else if (role === 3) {
      logger.log('User is an admin');
    } else if (role === 2) {
      logger.log('User is a regular user');
    } else if (role === 1) {
      logger.log('User is a guest');
    } else {
      logger.log('Role is not set');
    }
  } else {
    logger.log('No user is signed in');
  }
};

// Function to send a sign-in link to the provided email
export const sendSignInLinkByEmail = async (email) => {
    const actionCodeSettings = {
      url: `${process.env.REACT_APP_BASE_URL}/auth`,
      handleCodeInApp: true,
    };
    window.localStorage.setItem('emailForSignIn', email);

    try {
      await sendSignInLinkToEmail(auth, email, actionCodeSettings);
      logger.log('Sign-in link sent successfully.');

    } catch (error) {
      logger.error('Error sending sign-in link:', error);
    }
  };
  
  // Function to handle sign-in with the email link
  export const handleSignInWithEmailLink = async () => {
    let email = window.localStorage.getItem('emailForSignIn');

    const isValid = isSignInWithEmailLink(auth, window.location.href);
    logger.log(isValid);

    if (isValid) {
        try {
          const userAuth = await signInWithEmailLink(auth, email, window.location.href);
          const currentUser = auth.currentUser;
    
          // Check if the email link credential is already linked
          if (currentUser && !currentUser.email) {
            await linkEmailCredential(email, window.location.href);
            logger.log('User signed in with email link:', userAuth.user);
          } else {
            logger.log('Email link credential is already linked.');
          }

          return currentUser;
        } catch (error) {
          logger.error('Error signing in with email link:', error);
        }
      }
    };
  
  // Function to link an email link credential to the current user
  export const linkEmailCredential = async (email, link) => {
    try {
      const credential = EmailAuthProvider.credentialWithLink(email, link);
      const currentUser = auth.currentUser;
  
      if (currentUser) {
        await linkWithCredential(currentUser, credential);
        logger.log('Email link credential linked successfully.');
      } else {
        logger.error('No user is currently signed in.');
      }
    } catch (error) {
      logger.error('Error linking email link credential:', error);
    }
  };