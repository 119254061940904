import styled from "styled-components";
import { grabbitColor } from "../../utils/styled/colors";

export const PressReleaseContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    max-width: 1256px;
    padding: 5rem;
    border: 1px solid ${grabbitColor.warpWhite};
    border-radius: 24px;
    text-align: left;
    margin: 5rem auto;

    p{
        max-width: 900px;
    }
`