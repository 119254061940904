import styled from "styled-components";

export const NotFoundContainer = styled.div`
padding-top: 5rem;
min-height: 100vh;
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;

h1 {
    font-size: 80px;
}

p{
    font-size: 24px;
}
`