import { useState } from "react";
import emailjs from '@emailjs/browser';

import {
  SupplierContainer,
  InputContainer,
  SupplierInput,
  SignUpButton,
} from "./suppliers.styles";

import {
  ModalContainer,
  ModalContent,
  CloseButtonWrapper,
} from './suppliers.styles';

import CloseButton from "../../components/close-button/close-button.component";


import grabbitlogo from "../../assets/grabbit_logo_stacked.svg";
import { logger } from "../../utils/logger/logger.utils";

const SupplierForm = () => {
  const defaultFormFields = {
    displayName: '',
    companyName: '',
    email: '',
    phone: '',
    message: '',
  };

  const [formFields, setFormFields] = useState(defaultFormFields);
  const { displayName, companyName, email, phone, message } = formFields;
  const [showModal, setShowModal] = useState(false);

  const SERVICE_ID = process.env.REACT_APP_EMAILJS_SERVICE_ID;
  const TEMPLATE_ID = process.env.REACT_APP_EMAILJS_TEMPLATE_ID;
  const PUBLIC_KEY = process.env.REACT_APP_EMAILJS_PUBLIC_KEY;

  const resetFormFields = () => {
    setFormFields(defaultFormFields);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormFields({ ...formFields, [name]: value });
  };
  
  const sendEmail = () => {

    emailjs.send(
      SERVICE_ID,
      TEMPLATE_ID,
      {
      from_name: 'A Possible New Grabbit Vendor',
      to_name: "Grabbit Vendor Management",
      displayName: formFields.displayName,
      companyName: formFields.companyName,
      phone: formFields.phone,
      message: formFields.message,
      from_email: formFields.email,
      },
      {publicKey: PUBLIC_KEY,}
      );      
  }
  
  const handleSubmit = async (event) => {
    event.preventDefault();
    setShowModal(true);
    resetFormFields();
    try {
      logger.log('trying');
      sendEmail();
    } catch (error) {
      if (error.code === 'auth/email-already-in-use') {
        alert('Cannot create user, email already in use');
      } else {
        logger.log('user creation encountered an error', error);
      }
    }
  }

  const handleCloseModal = () => {
    setFormFields(defaultFormFields);
    setShowModal(false);
    window.location.reload();
  };

  return (
    <SupplierContainer onSubmit={handleSubmit}>
      <InputContainer>
        <SupplierInput
          placeholder='What shall we call you?'
          type='text'
          required
          onChange={handleChange}
          name='displayName'
          value={displayName}
        />
        <SupplierInput
          placeholder='What’s the name of your company?'
          type='text'
          required
          onChange={handleChange}
          name='companyName'
          value={companyName}
        />
        <SupplierInput
          placeholder='May we have your email?'
          type='email'
          required
          onChange={handleChange}
          name='email'
          value={email}
        />
        <SupplierInput
          placeholder='Can we get your phone number?'
          type='tel'
          required
          onChange={handleChange}
          name='phone'
          value={phone}
        />
        <SupplierInput
          placeholder='Tell us a bit about what you’re thinking.'
          type='textarea'
          required
          onChange={handleChange}
          name='message'
          value={message}
        />
      </InputContainer>
      <SignUpButton type='submit'>SEND IT!</SignUpButton>
      {showModal && (
        <ModalContainer>
          <ModalContent>
            <CloseButtonWrapper><CloseButton callbackMethod={handleCloseModal} /></CloseButtonWrapper>
            <img src={grabbitlogo} alt="#" />
            <h2>Thanks for connecting. We look forward to following up with you soon!</h2>
          </ModalContent>
        </ModalContainer>
      )}
    </SupplierContainer>
  )
};

export default SupplierForm;