import { useLocation } from 'react-router-dom';
import BasicPage from "../../components/basic-page/basic-page.component";

const Policies = () => {

  const page = useLocation();
  const subPage = page.pathname.substring(10)
  return (
      <BasicPage pageSlug={subPage}/>
  );
}

export default Policies;
