import styled from "styled-components";
import { device } from "../../utils/styled/breakpoints";
import Button from "../button/button.component";
import Scaffold from "../scaffold/scaffold.component";

export const PromoBoxContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 24px;
    text-align: center;
    background-color: ${({ promoData }) => promoData.primary_color};

    h2{
        color: ${({ promoData }) => promoData.main_text_color ? promoData.main_text_color : ''};
        margin: 0 0;
        text-transform: uppercase;
    }
    h3{
        color: ${({ promoData }) => promoData.secondary_text_color ? promoData.secondary_text_color : ''};
        margin: 8px 0;
    }

`

export const PromoImage = styled.img`
    width: 40%;
    height: auto;
    border-radius: 24px;
    margin-top: 8px;

    @media ${device.inBetweenMax}{
        width: 100%;
    }
`

export const PromoImageScaffold = styled(Scaffold)`
    width: 40%;
    height: auto;
    border-radius: 24px;
    margin-top: 8px;
    aspect-ratio: 1/1;

    @media ${device.inBetweenMax}{
        width: 100%;
        height: auto;
    }
`

export const PromoTextScaffold = styled(Scaffold)`
    
`

export const PromoButton = styled(Button)`

`