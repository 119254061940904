import styled from "styled-components";
import { grabbitColor } from "../../utils/styled/colors";
import { grabbitFont } from "../../utils/styled/fonts";
import { Close } from "@styled-icons/material";

export const DrawerContainer = styled.div`
    position: fixed;
    display: flex;
    flex-direction: column;
    z-index: 999999;
    width: 450px;
    height: ${(props) => props.open ? '100%' : '50px'};
    padding: 24px;
    background-color: ${grabbitColor.enduranceLightGray};
    left: ${(props) => props.open ? '0vw' : '-400px'};
    top: 70px;
    transition: all ${(props) => props.open ? '1s' : '500ms'} ease-in-out;

    h1, h2, h3, h4, h5, p{
        transition: opacity ${(props) => props.open ? '2.5s' : '250ms'} ease-in-out;
        opacity: ${(props) => props.open ? '1' : '0'};
    }
`

export const OpenToggle = styled(Close)`
    transform: ${(props) => props.open ? 'rotate(0deg)' : 'rotate(45deg)'};
    width: 50px;
    height: 50px;
    position: absolute;
    top: 0;
    right: 0;
    transition: all 100ms ease-in-out;
`

export const Line = styled.div`
    display: flex;
    flex-direction: row;
    align-items: baseline;
    justify-content: flex-start;
    width: 100%;
    
    *{
        padding-right: 12px;
    }
`