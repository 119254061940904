import styled from "styled-components";
import { grabbitColor } from "../../utils/styled/colors";
import { ReactComponent as GrabbitLogo } from '../../assets/logo.svg';


export const WhiteLogo = styled(GrabbitLogo)`
    fill: ${grabbitColor.warpWhite};
    width: 100px;
    height: auto;
    justify-self: center;
    align-self: center;
`
