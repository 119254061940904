import { 
  DarkThemeSwitcherButton,
  LightThemeSwitcherButton,
  ThemeSwitcherButtonContainer,
 } from "./theme-switcher.styles";

import { useDispatch, useSelector } from 'react-redux';
import { setTheme } from '../../store/theme/theme.reducer.js';
import { selectTheme } from '../../store/theme/theme.selector.js';
import { ThemeProvider } from "styled-components";
import { lightColors, darkColors } from '../../utils/styled/themeColors';


const ThemeSwitcher = () => {

  const dispatch = useDispatch();
  // const currentTheme = useSelector(selectTheme);
  // const theme = currentTheme === 'light' ? lightColors : darkColors;
  const handleThemeChange = (theme) => {
    dispatch(setTheme(theme));
  };


  return (
   <ThemeSwitcherButtonContainer>
    <DarkThemeSwitcherButton
      // onClick={() => handleThemeChange('dark')}
      // disabled={currentTheme === 'dark'}
    >
      Dark
    </DarkThemeSwitcherButton>
    <LightThemeSwitcherButton
      // onClick={() => handleThemeChange('light')}
      // disabled={currentTheme === 'light'}
    >
      Light
    </LightThemeSwitcherButton>
  </ThemeSwitcherButtonContainer>
  )
};

export default ThemeSwitcher;