import React from 'react'
import { NotFoundContainer } from './not-found.styles'
import { useState, useEffect } from 'react';
import { useLocation } from 'react-router';
import Spinner from '../../components/spinner/spinner.component';

const NotFound = () => {
    const location = useLocation();
    const [isLoading, setIsLoading] = useState(true);

    //Loading Listener
    useEffect(() => {
        const handleUnload = () => setIsLoading(true);
        window.addEventListener("beforeunload", handleUnload);
        return () => window.removeEventListener("beforeunload", handleUnload);
    }, []);

    useEffect(() => {
        const handleLoad = () => setIsLoading(false);
        window.addEventListener("load", handleLoad);
        return () => window.removeEventListener("load", handleLoad);
    }, []);

    useEffect(() => {
        setIsLoading(true);
        const timer = setTimeout(() => {
            setIsLoading(false);
        }, 1250);
        return () => clearTimeout(timer);
    }, [location]);

    return (
        <NotFoundContainer>
            {isLoading ? <Spinner /> : (
                <>
                    <h1>404: Page Not Found</h1>
                    <p>Sorry, the page you are looking for does not exist.</p>
                </>
            )
            }
        </NotFoundContainer>
    )
}

export default NotFound