import { useEffect, useState } from 'react';

import {
  ScrollContent,
  ScrollItem,
} from './scrolling-text.styles'

const ScrollingText = ({ children, text = 'wait for it…'}) => {
  const [itemCount, setItemCount] = useState(0);
  const horizontalCount = 8;
  const verticalCount = 20;

  useEffect(() => {
      const itemHeight = 200; // Set the height of the ScrollItem
      const containerHeight = window.innerHeight;
      const newItemCount = Math.ceil(containerHeight / itemHeight);
      setItemCount(newItemCount);
  }, []);

  const items = Array.from({ length: horizontalCount }, (_, i) => i);
  const containers = Array.from({ length: 5 }, (_, i) => i);

  return (
      containers.map((container) => (
          <ScrollContent key={container}>
              {items.map((item) => (
                  <ScrollItem key={item} animateReverse={container % 2 !== 0}>{text}</ScrollItem>
              ))}
          </ScrollContent>
      ))

  )
};

export default ScrollingText;