import styled from "styled-components";
import SocialIcons from '../../../social-icons/social-icons.component';
import { grabbitColor } from "../../../../utils/styled/colors";

export const DisplayContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 350px;
  height: 65vh;
  margin: -3rem 0rem -1rem;
  text-align: center;
  align-items: center;
  justify-content: center;
  background: none;
`;

export const DisplayWrapper = styled.div`
  background: ${grabbitColor.warpWhite};
  border-radius: 0px 0px 30px 30px;
  background: ${grabbitColor.flashYellow};
  color: ${grabbitColor.concentration};
  flex: 3;
  padding: 2rem 1.5rem 0rem;
`;

export const DisplayHeader = styled.h2`
  font-size: 2.5rem;
  color: ${grabbitColor.concentration};
  margin-bottom: .5rem;
`;

export const SpanWrapper = styled.div`
  margin: 0 0 2rem 0;
  font-size: 1.25rem;
`;

export const BottomInfoWrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding-top: 1rem;
  justify-content: center;
  background: ${grabbitColor.flashYellow};
  border-radius: 30px 30px 0px 0px;
  flex: 1;
  width: 100%;

  svg {
    color: ${grabbitColor.concentration};
  }
`;

export const SocialLogo = styled(SocialIcons)`
  width: 80%;
  max-width: 300px;
  height: auto;
  margin: 1rem auto;
  z-index: 10;
`;