import React from "react";
import { 
  OnboardPageContainer,
  OnboardButton,
  OnboardImage,
  OnboardText,
  SkipOnboard,
  OnboardButtonContainer,
} from './onboard-pages.styles.jsx';

import onboard3 from "../../assets/onboard3.png";

const PageThree = ({ onButtonClick, onBoardComplete }) => {

  return (
    <OnboardPageContainer>
      {/* <OnboardImage>
        <img src={onboard3} alt="#"/>
      </OnboardImage> */}
      <OnboardText>
        <h1>Hesitate too long and you might miss THAT DEAL because SOMEONE Else GRABBED iT.</h1>
      </OnboardText>
      <OnboardButtonContainer>
        <OnboardButton onClick={() => onButtonClick("pagefour")}>NEXT</OnboardButton>
        <SkipOnboard onClick={() => onBoardComplete()}>SKIP</SkipOnboard>
      </OnboardButtonContainer>
    </OnboardPageContainer>
  );
};

export default PageThree;