import React from 'react';
import { ViewCounterContainer } from './view-counter.styles';
import { useRealtimeData } from '../../utils/firebase/realtime-database.utils';
import ViewHandler from '../view-count-handler/view-count-handler.component';
import AnimatedNumbers from 'react-animated-numbers';

const ViewCounter = () => {
    const viewersData = useRealtimeData({collectionName: "currentsale", documentName: "viewers"})
    const bonusViewersData = useRealtimeData({collectionName: "currentsale", documentName: "bonusviewers"})

    const viewers = viewersData ? Object.keys(viewersData).length : 0;
    const bonusViewers = parseInt(bonusViewersData, 10) || 0;
    
    const viewerCount = viewers + bonusViewers;
    ViewHandler();
    
    return (
        <ViewCounterContainer>
            <h3>GRABBERS WATCHING</h3>
                <AnimatedNumbers
                includeComma
                transitions={(index) => ({
                    type: "spring",
                    duration: index + 0.3,
                })}
                animateToNumber={viewerCount}
            />
        </ViewCounterContainer>
    );
}

export default ViewCounter;
