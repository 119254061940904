import {
    TextAreaContainer,
    CustomTextArea,
    TextAreaLabel,
    InfoText
} from "./text-area.styles";


const TextAreaInput = ({ label, tabIndex, inputType, ...otherProps }) => {

    return (
        <TextAreaContainer className='group'>
            <CustomTextArea 
                className='form-input'
                tabIndex={tabIndex !== undefined ? tabIndex : (inputType === 'disabled' ? -1 : 0)} 
                {...otherProps} />
            <InfoText>{otherProps.info}</InfoText>
        </TextAreaContainer>
    );
};

export default TextAreaInput;
