import styled from 'styled-components';
import { device } from "../../../utils/styled/breakpoints"
import { grabbitColor } from '../../../utils/styled/colors';

export const ConfirmationContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 380px;
  max-width: 380px;
  margin: 4rem auto;
  padding: 32px;
  text-align: left;
  border: 1px solid ${grabbitColor.warpWhite};

  @media ${device.inBetween} {
      width: 90vw;
    }

  h2 {
    margin: 10px 0;
    color : ${grabbitColor.warpWhite};
  }

  span, p, h1{
    text-align: center;
  }

`;

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
`;