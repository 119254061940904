const size = {
  mobileS: '320px',
  mobileM: '375px',
  mobileL: '425px',
  mobileLMax: '425px',
  mobileXL: '480px',
  mobileMax: '480px',
  inBetween: '600px',
  inBetweenMax: '600px',
  tablet: '768px',
  tabletMax: '955px',
  laptop: '1024px',
  laptopMax: '1366px',
  laptopL: '1440px',
  desktop: '2560px',
}

export const device = {
  mobileS: `(max-width: ${size.mobileS})`,
  mobileM: `(min-width: ${size.mobileM})`,
  mobileLMax: `(max-width: ${size.mobileLMax})`,
  mobileL: `(min-width: ${size.mobileL})`,
  mobileXL: `(min-width: ${size.mobileXL})`,
  mobileMax: `(max-width: ${size.mobileMax})`,
  inBetween: `(min-width: ${size.inBetween})`,
  inBetweenMax: `(max-width: ${size.inBetweenMax})`,
  tablet: `(min-width: ${size.tablet})`,
  tabletMax: `(max-width: ${size.tabletMax})`,
  laptop: `(min-width: ${size.laptop})`,
  laptopMax: `(max-width: ${size.laptopMax})`,
  laptopL: `(min-width: ${size.laptopL})`,
  desktop: `(min-width: ${size.desktop})`,
  desktopL: `(min-width: ${size.desktop})`,
};



//TODO - Refactor above to this simpler set eventually
// const newSize = {
//   mobileS: '320px',
//   mobileM: '375px',
//   mobileL: '425px',
//   tablet: '768px',
//   laptop: '1024px',
//   laptopL: '1440px',
//   desktop: '2560px',
// }

export const newDevice = {
  mobileS: `(max-width: ${size.mobileS})`,
  mobileM: `(min-width: ${size.mobileM}) and (max-width: ${size.mobileL})`,
  tablet: `(min-width: ${size.tablet}) and (max-width: ${size.laptop - 1})`,
  laptop: `(min-width: ${size.laptop}) and (max-width: ${size.laptopL - 1})`,
  laptopL: `(min-width: ${size.laptopL}) and (max-width: ${size.desktop - 1})`,
  desktop: `(min-width: ${size.desktop})`,
};
