import styled from "styled-components";
import { Link } from "react-router-dom";
import { ReactComponent as GrabbitLogo } from '../../assets/logo.svg';
import { device } from "../../utils/styled/breakpoints";
import { grabbitColor } from "../../utils/styled/colors";
import { Facebook, 
         Instagram,
         Tiktok,
         Youtube,
         } from "@styled-icons/boxicons-logos";

export const FooterContainer = styled.div`
  color: ${grabbitColor.warpWhite};
  background-color: ${grabbitColor.concentration};
`;

export const LinkContainer = styled.div`
  padding: 20px 40px;
  margin: 0 auto;
  height: auto;
  display: flex;
  flex-flow: row wrap;
  align-items: start;
  justify-content: space-around;
  justify-items: start;
  text-align: left;
  max-width: 1256px;
  flex-direction: row;
  color: ${grabbitColor.warpWhite};
  font-size: 14px;

  a {
      color: ${grabbitColor.warpWhite};
      padding: 5px 0px;
  } 

  @media ${device.inBetweenMax}{
    flex-direction: column;
    font-size: 16px;
    justify-content: center;
    text-align: center;
  }
`;

export const FooterColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 33%;
  height: 100%;
  margin: 0 auto;
  align-self: start;


  @media ${device.inBetweenMax}{
    justify-content: center;
    justify-items: center;
    width: 100%;
  }
`;

export const FooterDivider = styled.hr`
  width: 100%;
  margin: 0;
  border-color: ${grabbitColor.darkBackground};
`;

export const FooterLink = styled(Link)`
  max-width: 320px;
  width: 300px;
  height: 20px;
  margin: 4px 0;

  @media ${device.inBetweenMax}{
    margin: 4px auto;
  }
`;

export const FullBar = styled.div`
  max-width: 1256px;
  height: 90px;
  padding: 10px 36px;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  justify-items: end;
  color: ${grabbitColor.warpWhite};
  margin-bottom: 0;

  span {
    font-size: 38px;
  font-weight: 900;
  }

  p {
    font-size: 12px;
      color: ${grabbitColor.warpWhite};
  }

  @media ${device.inBetweenMax}{
    flex-direction: column;
  }
`;

export const Logo = styled(GrabbitLogo)`
  width: auto;
  height: 50px;
  margin: 0 10px 0 0;
  fill: ${({ color }) => color || `${grabbitColor.warpWhite}`};
`;

export const LogoLink = styled(Link)`
  color: ${grabbitColor.warpWhite};
  display: flex;
`;

export const SocialIconContainer = styled.div`
  display: flex;
  justify-content: space-between;
  justify-self: flex-end;

  & > * {
    margin: 0 24px;

    & :hover{
      color: ${grabbitColor.flashYellow};
    }
  }
`

export const FacebookIcon = styled(Facebook)`
  color: ${grabbitColor.warpWhite};
  width: 36px;
  height: 36px;
  transform-origin: center;
`;

export const InstagramIcon = styled(Instagram)`
  color: ${grabbitColor.warpWhite};
  width: 36px;
  height: 36px;
  transform-origin: center;
`;

export const TikTokIcon = styled(Tiktok)`
  color: ${grabbitColor.warpWhite};
  width: 36px;
  height: 36px;
  transform-origin: center;
`;

export const YouTubeIcon = styled(Youtube)`
  color: ${grabbitColor.warpWhite};
  width: 36px;
  height: 36px;
  transform-origin: center;
`;