import styled from "styled-components";
import { grabbitFont } from "../../utils/styled/fonts";

export const TimerContainer = styled.div`
  
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: ${grabbitFont.mainGrabbitNumberFont}, monospace;
  width: 100%;
  height: auto;
`;

export const Hours = styled.span`
  margin: 0 5px;
`;

export const Minutes = styled.span`
  margin: 0 5px;
`;

export const Seconds = styled.span`
  margin: 0 5px;
`;