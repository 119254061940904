import styled from "styled-components";

export const CheckoutFormContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    height: 100%;

    .App-Payment {
        border: 1px solid blue;
    }

    & .paypal-container{
        width: 100%;
        max-width: 1256px;
        background-color: white;
        padding: 16px;
        border-radius: 8px;
    }
`

export const CheckoutInfoContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    padding: 16px;

    h3 {
        font-size: x-large;
        margin-right: 24px;
    }
    p{
        font-size: x-large;
    }
`