import { Link } from "react-router-dom";
import {
    FooterContainer,
    FooterLink,
    FooterColumn,
    LinkContainer,
    FooterDivider,
    Logo,
    FullBar,
    LogoLink,
    SocialIconContainer,
    FacebookIcon,
    InstagramIcon,
    TikTokIcon,
    YouTubeIcon,
} from "./footer.styles";

import { useSelector } from "react-redux";
import { selectCurrentUser } from "../../store/user/user.selector";


const Footer = () => {
    const user = useSelector(selectCurrentUser)
    return (
        <FooterContainer className="footer-container">
            <FullBar>
                <LogoLink to="/"><Logo /> <span>GRABBIT</span></LogoLink>
            </FullBar>
            <FooterDivider />
            <LinkContainer><FooterColumn>
                <FooterLink to="/home">Home</FooterLink>
                <FooterLink to="/sign-in">Sign In</FooterLink>
                <FooterLink to="/help">Get Help</FooterLink>
                <FooterLink to="/how-it-works">How It Works</FooterLink>
                <FooterLink to="/faq">FAQ</FooterLink>
                <FooterLink to="/suppliers">Become A Vendor</FooterLink>
                {user ?  <FooterLink to="/account">My Account</FooterLink> : ''}
            </FooterColumn>
                <FooterColumn>
                    <FooterLink to="/press">Press</FooterLink>
                    <FooterLink to="/policies/terms">Terms of Use</FooterLink>
                    <FooterLink to="/policies/privacy">Privacy Policy</FooterLink>
                    <FooterLink to="/policies/warranty-return">Warranty & Return Policy</FooterLink>
                </FooterColumn>
                <FooterColumn>

                </FooterColumn>
            </LinkContainer>
            <FullBar>
                <p>Grabbit ©{new Date().getFullYear()}. All rights reserved. Patent pending.</p>
                <SocialIconContainer>
                    <Link to="https://www.facebook.com/ReadySetGrabbit/" target="_blank"><FacebookIcon /></Link>
                    <Link to="https://www.instagram.com/ReadySetGrabbit/" target="_blank"><InstagramIcon /></Link>
                    <Link to="https://www.tiktok.com/@readysetgrabbit" target="_blank"><TikTokIcon /></Link>
                    <Link to="https://www.youtube.com/@ReadySetGrabbit" target="_blank"><YouTubeIcon /></Link>
                </SocialIconContainer>
            </FullBar>

        </FooterContainer>
    );
};

export default Footer;