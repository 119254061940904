import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { logger } from '../../../utils/logger/logger.utils';

import { CustomResetPassword } from '../../../utils/firebase/authentication.utils';

import Button, { BUTTON_TYPE_CLASSES } from '../../button/button.component';
import FormInput from '../../inputs/form-input/form-input.component';

import {
  FormContainer,
  ResetPasswordContainer,
  SuccessContainer,
  ServerMessage,
} from './reset-password-form.styles';

const defaultFormFields = {
  password: '',
  confirmPassword: '',
};

const ResetPasswordForm = () => {
  const [formFields, setFormFields] = useState(defaultFormFields);
  const { password, confirmPassword } = formFields;
  const [message, setMessage] = useState('');
  const [errorRecieved, setErrorRecieved] = useState(false);
  const [submissionSuccess, setSubmissionSuccess] = useState(false);

  // Extract the action code from the URL
  const urlParams = new URLSearchParams(window.location.search);
  let actionCode = urlParams.get('oobCode');

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormFields({ ...formFields, [name]: value });
  };

  const resetFormFields = () => {
    setFormFields(defaultFormFields);
  };

  const handleResetPassword = async () => {
    if (password !== confirmPassword) {
      setErrorRecieved(true);
      setMessage('Passwords do not match.');
      return;
    }
    try {
      let serverMessage = await CustomResetPassword(actionCode, password);
      
      if (serverMessage === undefined) {
        setErrorRecieved(false);
        setSubmissionSuccess(true);
        setMessage('Woo! You can now sign in with that fresh password.')
      }
      if (serverMessage === 'Error: Firebase: Error (auth/invalid-action-code).') {
        setErrorRecieved(true);
        setMessage('Looks like your reset request expired.');
      }
      else {
        setErrorRecieved(true);
        setMessage(serverMessage);
      }
    } catch (error) {
      logger.error('Error: ', error.message);
      setMessage('Looks like your reset request expired.');
    }
  }

  const renderDisplay = () => {
    if (!submissionSuccess) {
      return (
        <ResetPasswordContainer>
          <h2>Ready … Set … Your Password</h2>
          <FormInput
            label='Password'
            type='password'
            required
            onChange={handleChange}
            name='password'
            value={password}
          />
          {password.length < 8 ? '' : <FormInput
            label='Confirm Password'
            type='password'
            required
            onChange={handleChange}
            name='confirmPassword'
            value={confirmPassword}
          />}
          {password.length < 8 || password !== confirmPassword ?
            <Button buttonType={BUTTON_TYPE_CLASSES.disabled}>Set Your Password</Button>
            : <Button onClick={() => handleResetPassword(password, confirmPassword, actionCode)}>Set Your Password</Button>
          }
          {password.length < 8 ? <ServerMessage>Password must be at least 8 characters</ServerMessage> : ''}
          {password.length >= 8 && password !== confirmPassword ? <ServerMessage>Passwords must match</ServerMessage> : ''}

          <ServerMessage className={errorRecieved ? 'error' : ''}>{message}</ServerMessage>
        </ResetPasswordContainer>
      )
    }
    else {
      return (
        <SuccessContainer>
          <ServerMessage>Woo! You can now sign in with that fresh password.</ServerMessage>
          <Link to='/sign-in'><Button>sign in</Button></Link>
        </SuccessContainer>
      )
    }

  }

  return (
    <FormContainer>
      {renderDisplay()}
    </FormContainer>
  );
};

export default ResetPasswordForm;