
import React from 'react'
import { PriceDataWrapper, PriceDisplayContainer } from './price-display.styles'
import RealTimePrice from '../../../real-time-price-display/real-time-price-display.component'
import { useSelector } from 'react-redux'
import RandomCharacters from '../../../random-characters/random-characters.component'
import { useRealtimeData } from '../../../../utils/firebase/realtime-database.utils'

const PriceDisplay = ({ price }) => {
  const retailPrice = useRealtimeData({collectionName: "currentsale", documentName: "product"})?.retailPrice

  return (
    <PriceDisplayContainer>
      <PriceDataWrapper>
        {
          price? 
          ''
          :
          <>
        <p>
          Retail Price</p>
        <p style={{ textDecoration: 'line-through' }}>{
          retailPrice ?
          '$' + retailPrice
            :
            <>
              <RandomCharacters set='numbers' count={2} speed={1} />.<RandomCharacters set='numbers' count={2} speed={1} /></>}
              </p>
              </>
}
      </PriceDataWrapper>
      {
        price ?
        <PriceDataWrapper>
          <p>
            Price:
          </p>
          <p>${price}</p>
        </PriceDataWrapper>
        : 
      <PriceDataWrapper>
        <p>Current Price</p>
      <RealTimePrice />
      </PriceDataWrapper>
}
    </PriceDisplayContainer>
  )
}

export default PriceDisplay