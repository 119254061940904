import styled from "styled-components";
import { grabbitColor } from "../../../../utils/styled/colors";
import { device } from "../../../../utils/styled/breakpoints";


export const GrabbedNoticeContainer = styled.div`
  color: ${grabbitColor.concentration};
  text-align: center;
  border-radius: 24px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  border-radius: 24px;

  h2{
    margin: 0;
  }
  
`;

export const GrabbedNoticeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  padding: 24px;
  border-radius: 24px;
  z-index: 100;
  background-color: ${grabbitColor.alpha('flashYellow', 0.75)};
  min-width: 100%;
  min-height: 100%;
`;

export const TimerBar = styled.hr`
    width: 100%;
    background-color: ${grabbitColor.ultrapatiencePurple};
    transform-origin: left;
    padding: 4px 0px;

    @keyframes countdown {
    0% {

      width: 100%;
    }

    100% {
      width: 0%;
    }
  }

    animation-name: countdown;
    animation-duration: 2s;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-iteration-count: 1;
`