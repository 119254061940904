import React from 'react'
import { GrabbedNoticeContainer, GrabbedNoticeWrapper, TimerBar } from './grabbed-notice.styles'
import { useRealtimeData } from '../../../../utils/firebase/realtime-database.utils'
import { useSelector } from 'react-redux'
import { selectCartCount } from '../../../../store/cart/cart.selector'

const GrabbedNotice = () => {

    const cartCount = useSelector(selectCartCount);

    const status = useRealtimeData({ collectionName: 'currentsale', documentName: 'status/currentStatus' })

    if (status === 'grabb' && cartCount < 1) return (
        <GrabbedNoticeContainer>
            <GrabbedNoticeWrapper>
                <h2>SOMEBODY GRABBED IT!</h2>
                <TimerBar />
                PRICE RESET
            </GrabbedNoticeWrapper>
        </GrabbedNoticeContainer>
    )
}

export default GrabbedNotice