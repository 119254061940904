import React from 'react'

import { getImageFromStorage } from "../../../utils/firebase/storage.utlis";
import { logger } from "../../../utils/logger/logger.utils";

const FetchTheProductImages = async (image) => {
    
    if(!image){
        return;
    }

    const promises = [];
    promises.push(getImageFromStorage(image));

    try {
      const results = await Promise.all(promises); // This will contain all the resolved URLs  
      const  imageUrl = results;
      
      return imageUrl

    } catch (error) {
      logger.error("Error fetching images:", error);
    }
  };
  
  export default FetchTheProductImages
