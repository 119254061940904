import TopInfoContainer from '../../top-info/top-info-grabb.component';
import GrabbImage from '../../grabb-image/grabb-image.component';
import { CardContainer, InfoContainer } from "../../grabb-card.styles";
import React, { Fragment, useState, useEffect } from 'react'
import { SocialLogo } from './inactive-sale-display.styles';
import { FindBestPrice } from '../../utility-functions/price.utils';
import { useSelector } from 'react-redux';
import FetchTheProductImages from '../../utility-functions/image.utils';
import { logger } from '../../../../utils/logger/logger.utils';

const InactiveDisplay = () => {
  const [bestPrice, setBestPrice] = useState('');
  const [imageUrl, setImageUrl] = useState('');

  // const lastSale = useSelector(selectCurrentSale);
  
  // const {
  //   main_image,
  //   grabb_table,
  //   name,
  //   retailPrice
  // } = lastSale || {}


  // useEffect(() => {
  //   const fetchBestPrice = async () => {
  //     try {
  //       const result = await FindBestPrice(grabb_table);
  //       logger.log(result)
  //       setBestPrice(result);
  //     } catch (error) {
  //       logger.error('Error fetching best price: ', error);
  //     }
  //   };
  //   fetchBestPrice();
  // }, [grabb_table])

  // useEffect(() => {
  //   const fetchImages = async () => {
  //     try {
  //       const result = await FetchTheProductImages(main_image);
  //       setImageUrl(result);
  //     } catch (error) {
  //       logger.error('Error fetching images:', error);
  //     }
  //   };
  //   fetchImages();
  // }, [main_image]);

  return (
    <CardContainer>
      <GrabbImage />
      <InfoContainer>
        <TopInfoContainer
          active={false}
        />
        <h2>{'Don’t miss the next drop. \n Give us a follow.'.split('\n').map((line, index) => (
          <Fragment key={index}>
            {line}
            <br />
          </Fragment>
        ))}</h2>
        <SocialLogo />

      </InfoContainer>
    </CardContainer>
  )
}

export default InactiveDisplay