import styled from 'styled-components';
import { device } from "../../../utils/styled/breakpoints"
import { grabbitColor } from '../../../utils/styled/colors';

export const SignInContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 90vw;
  max-width: 360px;
  padding: 32px;
  text-align: center;
  border: 1px solid ${grabbitColor.warpWhite};

  h2 {
    margin: 10px 0;
    color : ${grabbitColor.warpWhite};
  }

  & .notice{
    font-size: 12px;
  }

`;

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const PasswordLink = styled.span`
  color : ${grabbitColor.flashYellow};
  text-decoration: underline;
  cursor: pointer;
`