import { logger } from "../logger/logger.utils";

export const addToMailchimpAudience = async (email, firstName, lastName) => {
    try {
        const response = await fetch('/.netlify/functions/add-mailchimp-contact', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            "email":email,
            "firstName": firstName,
            "lastName": lastName,
          }),
        });
        const data = await response.json();
        return data;
      } catch (error) {
        logger.error(error);
      }
};