import React, { useEffect, useState } from 'react'
import moment from 'moment-timezone';
import { DetailInfoContainer, PromoSectionContainer, PromoTimerContainer, InfoContainer, PromoImage } from "./promo-section.styles";
import CountdownTimer, { TIMER_FORMATS } from "../countdown-timer/countdown-timer.component";
import Button from '../button/button.component';
import { useNavigate } from 'react-router';
import { getImageFromStorage } from "../../utils/firebase/storage.utlis";
import { logger } from '../../utils/logger/logger.utils';

const PromoSection = ({ promoData }) => {
  const navigate = useNavigate();
  const [imageURL, setImageURL] = useState(null);
  
  const {
    headline,
    subhead,
    promo_image,
    promo_callout,
    promo_launch_time,
    primary_color,
    secondary_color,
    main_text_color,
    secondary_text_color,
  } = promoData || {};


  const fetchURL = async () => {
    if (!promo_image) {
      logger.log("promo_image is undefined, skipping fetch.")
      return;
    }
  
    logger.log("Fetching image for:", promo_image)
    try {
      const imageUrl = await getImageFromStorage(promo_image);
      setImageURL(imageUrl);
    } catch (error) {
      logger.error("Error fetching images:", error);
    }
  };
  
  useEffect(() => {
    fetchURL();
  }, [promo_image]); // Add other dependencies if needed


  // Set the default target date-time in Eastern Time (ET)
  let targetDateET = moment.tz(Date.now(), "America/New_York");

  if (promo_launch_time) {
      // Set the target date-time in Eastern Time (ET)
      targetDateET = moment.tz(promo_launch_time.toDate(), "America/New_York");
  }

  // Convert to milliseconds for the CountdownTimer component
  const endTime = targetDateET.valueOf();
  return (
    <PromoSectionContainer backgroundColor={primary_color} textColor={main_text_color}>
      <InfoContainer>
        <h1 className='title'>{headline}</h1>
        <p>{moment.utc(targetDateET.toDate()).tz(moment.tz.guess()).format('dddd, MMMM Do @ h:mm A z')}</p>
      </InfoContainer>
      <PromoTimerContainer>
        <CountdownTimer endTime={endTime} format={TIMER_FORMATS.DAYS} />
      </PromoTimerContainer>

      <DetailInfoContainer backgroundColor={secondary_color} textColor={secondary_text_color}>
        <h1>{subhead}</h1>
        <PromoImage image={imageURL}  backgroundColor={secondary_color} />
        <h2>{promo_callout}</h2>
      </DetailInfoContainer>
      <Button onClick={() => navigate("/grabb")} >Go To Shop</Button>

    </PromoSectionContainer>
  );
}

export default PromoSection;
