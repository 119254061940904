import React, { useState, useEffect } from "react";
import { setRealtimeData } from "../../../../utils/firebase/realtime-database.utils";
import { 
  BottomInfoWrapper, 
  CountDownDisplayWrapper,
  DisplayContainer,
  CountDownDisplayHeader,
  CountDownNumber,
  CountDownBottom,
} from "./countdown-display.styles";

const CountDownDisplay = () => {
  const [countdownTime, setCountdownTime] = useState(5);

  useEffect(() => {
    if (countdownTime > 0) {
      const timer = setTimeout(() => {
        setCountdownTime((prevTime) => prevTime - 1);
      }, 1_000);
      return () => clearTimeout(timer);;
    } 
  }, [countdownTime]);

  return (
    <DisplayContainer>
      <CountDownDisplayWrapper>
        <CountDownDisplayHeader>OHHH THEY DROPPED IT!</CountDownDisplayHeader>
        <CountDownNumber>{countdownTime}</CountDownNumber>
      </CountDownDisplayWrapper>
      <BottomInfoWrapper>
          <CountDownBottom>
            Get ready, the price is dropping again!
          </CountDownBottom>
      </BottomInfoWrapper>
    </DisplayContainer>
  )
};

export default CountDownDisplay;