import { SpinnerContainer, SpinnerOverlay, SpinnerBackground, Logo } from "./spinner.styles"
import { useEffect, useState } from 'react';

const Spinner = () => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 4000);
    return () => clearTimeout(timer);
  }, []);

  if (isLoading) {
    return (
      <SpinnerBackground>
      <SpinnerContainer>
        <Logo/>
        <SpinnerOverlay />
      </SpinnerContainer>
      </SpinnerBackground>
    );
  }
  return null;
};

export default Spinner;
