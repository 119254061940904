import { createSlice } from '@reduxjs/toolkit';
import { getDocByID } from '../../utils/firebase/firebase.utils';

export const SETTINGS_INITIAL_STATE = {
    settings: {},
    waitlist_active : false,
    error: null,
};

const fetchSettings = async () => {
    const settingsArray = await getDocByID('settings', 'global');
    return settingsArray;
  };
  

export const settingsSlice = createSlice({
    name: 'settings',
    initialState: SETTINGS_INITIAL_STATE,
    reducers: {
        setSettingsStart(state) {
            state.waitlist_active = false;
        },
        setSettingsSuccess(state, action) {
            state.settings = action.payload;
            state.waitlist_active = action.payload.waitlist_active;
        },
        setSettingsFailure(state, action) {
            state.error = action.payload;
        }
    },
});

export const { setSettingsStart, setSettingsSuccess, setSettingsFailure } = settingsSlice.actions;

export const fetchSettingsAsync = () => async (dispatch) => {
    try {
        dispatch(setSettingsStart());
        const settings = await fetchSettings();
        dispatch(setSettingsSuccess(settings));
    } catch (error) {
        dispatch(setSettingsFailure(error));
    }
};

export const settingsReducer = settingsSlice.reducer;
