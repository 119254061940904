//React imports
import { Fragment, useEffect } from "react";
import { useNavigate } from "react-router";

//Redux imports
import {
  useSelector,
  useDispatch,
} from "react-redux";

import {
  selectCartCount,
  selectCartItems,
  selectCartTimeOut
} from "../../store/cart/cart.selector";

import { clearAllCartItems, setCartTimeOut, toggleIsCartOpen } from "../../store/cart/cart.reducer";
import { toggleModalState, setModalClosed } from '../../store/modal/modals.reducer'
import TimeoutModal from '../alert-modals/timeout-modal.component';
import { selectTimeoutModal } from '../../store/modal/modals.selector';

//Styled components
import {
  CartContainer,
  CartIcon,
  Timer,
  GrabbedItemImageWrapper,
} from './nav-cart-display.styles'
import OffCanvasCart from "../off-canvas-cart/off-canvas-cart.component";


const NavCartDisplay = () => {

  const cartCount = useSelector(selectCartCount);
  const cartItems = useSelector(selectCartItems);
  const cartTimeOut = useSelector(selectCartTimeOut);
  const timeoutModalOpen = useSelector(selectTimeoutModal);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  //Cart timeout handler
  //Disabled temporarily
  useEffect(() => {
    let intervalId = null;
    if (cartTimeOut === null) {
      return () => clearInterval(intervalId);
    }

    const dropGrabb = async () =>{
        // Drop the grab
      const response = await fetch(`${process.env.REACT_APP_PROXY}/dropperProxy`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ dropperMethod: "dropped", data: {} }),
      });
    }

    // Subscribe to interval when cart count is > 0
    if (cartCount > 0) {
      intervalId = setInterval(() => {
        if (cartTimeOut < Date.now()) {
          dispatch(clearAllCartItems());
          dispatch(setCartTimeOut(null));
          dispatch(toggleModalState('timeoutModal'));
          dropGrabb();
        }
      }, 5000);
    }

    // Unsubscribe from interval when cart count is 0
    if (cartCount <= 0) {
      clearInterval(intervalId);
    }

    return () => clearInterval(intervalId);
  }, [cartCount, cartTimeOut]);

  const onCloseHandler = () => {
    dispatch(setModalClosed('timeoutModal'));
    dispatch(clearAllCartItems());
    navigate('/grabb');
  }

  const onClickIcon = () => {
    dispatch(toggleIsCartOpen())
  }

  return (
    <Fragment>
      {timeoutModalOpen && <TimeoutModal onClose={() => onCloseHandler} />}
      {cartCount <= 0 ? (
        <CartContainer>
          <CartIcon onClick={onClickIcon} />
        </CartContainer>
        )
        : (
          <CartContainer >
            {cartTimeOut ? <Timer endTime={cartTimeOut} type='short' /> : ''}
            {cartItems[0].imageUrl ?
              (<GrabbedItemImageWrapper onClick={onClickIcon} >
                <img src={cartItems[0].imageUrl} alt="#" />
              </GrabbedItemImageWrapper>) :
              <CartIcon onClick={onClickIcon} />
            }
                  <OffCanvasCart />
          </CartContainer>
          )
          }
    </Fragment>
  )
};

export default NavCartDisplay;