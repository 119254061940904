//Set log level to stop logs in production
const LOG_LEVEL = process.env.REACT_APP_APP_ENV === 'production' ? 'production' : 'development';

/** No-operation function for disabled log levels */
const NO_OP = (message, ...optionalParams) => {};

/** Logger which outputs to the browser console */
export class ConsoleLogger {
  constructor(options) {
    const { level } = options || {};

    this.error = console.error.bind(console);

    if (level === 'error') {
      this.warn = NO_OP;
      this.log = NO_OP;
      return;
    }
    
    this.warn = console.warn.bind(console);

    if (level === 'warn') {
      this.log = NO_OP;
      return;
    }

    this.log = console.log.bind(console);
  }
}

// Create an instance of the logger with the desired log level
export const logger = new ConsoleLogger({ level: LOG_LEVEL });
