import styled from "styled-components";
import { grabbitColor } from "../../../../utils/styled/colors";
import { grabbitFont } from "../../../../utils/styled/fonts";

export const DisplayContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 350px;
  height: 60vh;
  margin: -3rem 0rem -1rem;
  text-align: center;
  align-items: center;
  justify-content: center;
  background: none;
`;

export const PauseDisplayWrapper = styled.div`
  background: ${grabbitColor.warpWhite};
  border-radius: 0px 0px 30px 30px;
  background: ${grabbitColor.flashYellow};
  color: ${grabbitColor.concentration};
  flex: 3;
  padding: 2rem 1.5rem 0rem;
  
  div {
    font-size: 1.25rem;
    text-align: center;
    margin: auto;
    width: 60%;
    font-weight: 700;
  }

  span {
    display: block;
    text-align: center;
    margin: auto;
    font-size: 1.15rem;
    margin-bottom: 2rem;
    width: 90%;
  }
`;

export const PauseDisplayHeader = styled.h2`
  font-size: 2.25rem;
  color: ${grabbitColor.concentration};
  margin-top: 1rem;
  margin-bottom: 0rem;
`;

export const WaitHeader = styled.div`
  font-size: 3rem;
  color: ${grabbitColor.concentration};
  font-weight: 700;
  font-family: ${grabbitFont.mainGrabbitNumberFont};
`;

export const BottomInfoWrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding-top: 1.5rem;
  margin-bottom: 4rem;
  justify-content: center;
  background: ${grabbitColor.flashYellow};
  border-radius: 30px 30px 0px 0px;
  flex: 1;
  width: 100%;
  color: ${grabbitColor.concentration};
  font-size: xx-large;

  svg {
    color: ${grabbitColor.concentration};
  }
`;