import styled, { keyframes, css } from "styled-components";
import { grabbitColor } from "../../utils/styled/colors";
import { grabbitFont } from "../../utils/styled/fonts";
import Button from "../../components/button/button.component";
import { device } from "../../utils/styled/breakpoints";

// Define keyframe animation for the scrolling effect
 const scroll = keyframes`
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-100%);
  }
`;

export const PageContainer = styled.div`
  display: flex;
  overflow: hidden;
  width: 100%;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${grabbitColor.enduranceDarkGray};
`;

export const HomePageContentContainer = styled.div`
  display: flex;
  overflow: hidden;
  width: 100%;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media ${device.laptopL} {
    margin: -4rem 0rem 0rem;
  }
`;

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 5rem;
  justify-content: center;
  padding: 5rem;
  z-index: 2;
`;

export const TopInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  width: 50%;
  text-align: center;
  justify-content: center;
`;


export const Phone = styled.img`
  width: auto;
  height: 40rem;
  margin-right: -5rem;
  margin-top: 5rem;
`


export const TopInfoH2 = styled.h2`
  font-size: 3rem;
  margin-bottom: -1rem;
  color: ${grabbitColor.warpWhite};

`;

export const TopInfoH1 = styled.h1`
  font-size: 4rem;
  margin-top: 0;
  margin-bottom: .75rem;
  color: ${grabbitColor.flashYellow};

`;

export const JoinButton = styled(Button)`
  margin-top: 1.5rem;
  margin-bottom: 1rem;
  height: 2.5rem;
  text-align: center;
  line-height: 40px;
  width: 100%;
  font-weight: 700;
  font-size: .7rem;

  @media ${device.mobileL} {
    font-size: 1rem;
  }
`;

export const JoinButtonInvert = styled(Button)`
  margin-bottom: 1rem;
  height: 3rem;
  text-align: center;
  line-height: 45px;
  width: 60%;
  font-size: 1rem;
  font-weight: 700;
  background-color: ${grabbitColor.ultrapatiencePurple_600};
  border: 2px solid ${grabbitColor.warpWhite};
`;

export const HowItWorksContainer = styled.div`
  background-color: ${grabbitColor.ultrapatiencePurple_600};
  margin-top: 2rem;
  padding-bottom: 5rem;
  z-index: 100;
  h1{
    font-size: 2.5rem;
    margin: 5rem 0 0 0;
    text-align: center;
  }
`;

export const WaitListContainer = styled.div`
  height: 100%;
  width: 100%;
  overflow: visible;
`;

// Create a container component that wraps all the content
export const ScrollContainer = styled.div`
  overflow: hidden;
  position: absolute;
  width: 100vw;
  height: 100vw;
  top: 0;
  z-index: 0;
`

// Create a container component that wraps the content to animate
export const ScrollContent = styled.div`
  opacity: .1;
  display: flex;
  flex-wrap: nowrap;
  text-align: center;
  align-items: center;
  align-content: center;
  display: flex;
  white-space: nowrap;
  overflow: hidden;
  width: 100vw;
  transform: rotate(-8deg);
  will-change: transform;
`

// Create a content component that is a child of the container
// Apply animation and styles to the content
export const ScrollItem = styled.div`
  font-size: 150px;
  font-family: ${grabbitFont.mainGrabbitFont}, Arial, sans-serif;
  font-weight: bold;
  text-transform: uppercase;
  color: ${grabbitColor.enduranceLightGray};
  display: inline-block;
  animation: ${scroll} 5s linear infinite;
  ${({ animateReverse }) =>
    animateReverse &&
    css`
      animation-direction: reverse;
    `}
  will-change: transform;
`