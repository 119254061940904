import styled from "styled-components";
import { grabbitColor } from "../../utils/styled/colors";
import { Help } from "@styled-icons/material";
import { device } from "../../utils/styled/breakpoints";

export const TooltipContainer = styled.div`
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
`

export const TooltipBody = styled.div`
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateY(-75%) translateX(-50%);
    width: 90vw;
    padding: 32px;
    margin: 0 auto;
    border-radius: 24px;
    box-shadow: 0px 0px 12px 8px ${grabbitColor.concentration};
    background-color: ${grabbitColor.enduranceLightGray};
    visibility: ${(props) => props.visible ? 'visible' : 'hidden'};
    opacity: ${(props) => props.visible ? '1' : '0'};;
    z-index: 100;

    @media ${device.laptop} {
        width: 500px;
        top: 100%;
        transform: translateY(0%) translateX(-50%);
    }
    
    transition: visibility 300ms, opacity 300ms linear;
`

export const TipIconHitbox = styled.div`
    width: 54px;
    height: 54px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    z-index: 10;

`
export const TipIcon = styled(Help)`
  color: ${grabbitColor.alpha('warpWhite', 0.5)};
  font-weight: ${(props) => (props.important ? 'bold' : 'normal')};
  width:24px;
`;

export const Qicon = styled(Help)`
  color: ${grabbitColor.alpha('warpWhite', 0.5)};
  font-weight: ${(props) => (props.important ? 'bold' : 'normal')};
  width:24px;
  position: absolute;
  top: 8px;
  left: 8px;
`;