import {
    doc,
    getDoc,
    setDoc,
} from 'firebase/firestore';

import {
    db, getCollectionAndDocuments,
} from './firebase.utils'

import { fetchHashedIp } from '../ip-tools/ip-tools.utils';
import { logger } from '../logger/logger.utils';

const countUserDocs = async () => {
    const query = await getCollectionAndDocuments('users')
    return query.length;;
}

export const createGuestUserDocument = async () => {
    const hasedIP = await fetchHashedIp();
    
    if (!hasedIP) return;

    const userDocRef = doc(db, 'users', hasedIP);
    const userSnapshot = await getDoc(userDocRef);
    const userCount = await countUserDocs();

    if (!userSnapshot.exists()) {
        const displayName = `guest${userCount + 1}`
        const email = 'none'
        const createdAt = new Date();
        try {
            await setDoc(userDocRef, {
                displayName,
                email,
                createdAt,
                role: 'guest',
                grabbsLeft: 3,
                onBoarding: false,
                cart: [],
            })

        } catch (error) {
            logger.log('error creating the user', error.message);
        }
    }

    // Return userSnapshot again to ensure you always return the document
    return await getDoc(userDocRef);
};


