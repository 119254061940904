import styled from "styled-components";
import { device } from "../../utils/styled/breakpoints";

export const PressReleasesContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;

    @media ${device.inBetweenMax}{
        flex-direction: column;
    }

`