import { useState, useEffect } from 'react';
import { getPage } from '../../utils/firebase/firebase.utils';
import { BasicMainContainer } from './basic-page.styles'
import ReactMarkdown from 'react-markdown';
import { logger } from '../../utils/logger/logger.utils';

const MarkdownRenderer = ({ content }) => {
    return <ReactMarkdown>{content}</ReactMarkdown>;
  };

const BasicPage = (pageSlug) => {
    const [page, setPage] = useState({});

    const fetchPage = async () => {
        try {
            const pageData = await getPage('pages', pageSlug.pageSlug);
            setPage(pageData);
        } catch (error) {
            logger.error(error);
        }
    };
    
    useEffect(() => {
        fetchPage()
        window.scrollTo(0, 0);
    }, [pageSlug])

    const { page_title, page_body } = page;

    return (
        <BasicMainContainer>
            <h1>{page_title}</h1>
            <MarkdownRenderer content={page_body}/>
        </BasicMainContainer>
    );
};

export default BasicPage;
