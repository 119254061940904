import styled from "styled-components";
import { grabbitColor } from "../../utils/styled/colors";
import { grabbitFont } from "../../utils/styled/fonts";
import Button from "../../components/button/button.component";
import { device } from "../../utils/styled/breakpoints";

export const PageContainer = styled.div`
  display: flex;
  padding: 36px;
  margin: auto;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`;

export const MainSupplierContainer = styled.div`
  display: flex;
  flex-direction: column;

  @media ${device.tablet} {
    max-width: 50vw;
  }
`;

export const SupplierImage = styled.div`
  img {
    width: 100vw;
    max-height: 40rem;
    object-fit: cover;
  }
`;

export const TopInfoContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  color: ${grabbitColor.warpWhite};
  font-family: ${grabbitFont.mainGrabbitFont};
  max-width: 1256px;


  h1 {
    font-size: 2.25rem;
    margin: 0.5em 0px 1rem;
    text-align: center;
  }

  p {
    font-size: 1rem;
    margin: 0rem;
    font-weight: 100;
  }
`;

export const InfoContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  max-width: 1256px;
  margin: 1rem 0;

  h2 {
    font-size: 1.5rem;
    margin: 0rem 2rem 0rem 0rem;
    text-align: left;
  }

  p {
    font-size: 1rem;
    line-height: 125%;
    letter-spacing: 0.02rem;
  }
`;

export const EmojiContainer = styled.div`
  display: flex;
  margin-top: 1rem;
  font-size: 48px;
`;

export const SupplierContainer = styled.form`
  padding: 0rem 2rem;
  margin: 1.75rem auto;
  width: 100%;
  max-width: 1256px;
  border: 1px solid ${grabbitColor.warpWhite};
  color: ${grabbitColor.warpWhite};

  h3 {
    font-size: 1rem;
  }
`;

export const InputContainer = styled.div`
  margin-top: .5rem;
`;

export const SupplierInput = styled.input`
  height: 2rem;
  width: 100%;
  padding: 0rem .5rem;
  background-color: ${grabbitColor.warpWhite};
  border: none;
  margin: 1rem 0rem 0rem 0rem;
  font-size: 0.75rem;
  font-family: ${grabbitFont.mainGrabbitFont};

  ::-webkit-input-placeholder {
    color: ${grabbitColor.concentration};
  }
`;

export const SignUpButton = styled(Button)`
  margin-top: 1.5rem;
  margin-bottom: 1rem;
  height: 2.5rem;
  text-align: center;
  line-height: 40px;
  width: 75%;
`;

export const ModalContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 110vh;
  margin-top: -4rem;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  transform: translateY(${props => (props.show ? "0" : "50px")});
  opacity: ${props => (props.show ? 1 : 1)};
  transition: transform 0.3s ease, opacity 0.3s ease;
`;

export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  padding: 2rem;
  margin: 2rem;
  border-radius: 5px;
  color: ${grabbitColor.warpWhite};
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  background-color: ${grabbitColor.ultrapatiencePurple};
  max-width: 500px;

  img {
    width: 25%;
    margin-bottom: 1rem;
  }

  h2 {
    text-align: center;
  }
`;

export const CloseButtonWrapper = styled.div`
  font-size: 1.5rem;
  cursor: pointer;
  align-self: end;
  justify-self: start;
`;