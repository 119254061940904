import styled, { css } from "styled-components";
import { device } from '../../../../utils/styled/breakpoints'
import { grabbitColor } from "../../../../utils/styled/colors";
import { grabbitFont } from "../../../../utils/styled/fonts";

import { ReactComponent as GrabbitLogo } from "../../../../assets/logo.svg";
import { Close } from "@styled-icons/material";

import Marquee from "react-fast-marquee";

export const MarqueeContainer = styled.div`
  position: absolute;
  top:200px;
  left: -200px;
  bottom: 0;
  right: 0;
  z-index: -1;
  color: ${grabbitColor.warpWhite};
  font-size: 10rem;
  transform: rotate(270deg);
`;

export const GrabbedMarquee = styled(Marquee)`    
    --direction: reverse;
    --duration: 100s;
`

export const GrabbedText = styled.div `
  font-size: 12rem;
  font-family: ${grabbitFont.mainGrabbitFont}, Arial, sans-serif;
  font-weight: bold;
  text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
  -webkit-text-fill-color: ${grabbitColor.warpWhite};
`;


export const GrabbedItOverlayContainer = styled.div`
  display: flex;
  width: 100%;
`;

export const GrabbedItContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${grabbitColor.warpWhite};
  color: ${grabbitColor.concentration};
  position: absolute;
  top: 70px;
  z-index: 1000;
  width: 100%;
  border-radius: 0 0 25px 25px;
  padding-right: 5%;
  height: auto;
  padding-bottom: 2rem;
  padding-top: 10rem;
  overflow: hidden;

  span {
    align-self: flex-end;
  }

  .closed {
    display: none;
  }
`;

export const GrabbedMessage = styled.div`
  text-align: right;
  font-size: 48px;
  align-self: flex-end;

  @media ${device.mobileS} {
    font-size: 1rem;
  }
  @media ${device.mobileM} {
    font-size: 2rem;
  }
  @media ${device.mobileL} {
    font-size: 2rem;
    font-weight: 700;
    width: 50%;
  }
  @media ${device.inBetween} {
    font-size: 3rem;
  }
  @media ${device.tablet} {
    font-size: 4rem;
  }
  @media ${device.laptop} {
    font-size: 4rem;
  }
  @media ${device.desktop} {
    font-size: 4rem;
  }
`;

export const GrabbedImage = styled.img`
  text-align: right;
  font-size: 48px;
  height: auto;
  width: 60%;
  max-width: 500px;
  max-height: 500px;
  align-self: flex-end;
`;

export const GrabbedPrice = styled.span`
  font-weight: bold;
  font-family: ${grabbitFont.mainGrabbitNumberFont}, Arial, Helvetica, sans-serif;
  align-self: flex-end;

  @media ${device.mobileS} {
    font-size: 4rem;
  }
  @media ${device.mobileM} {
    font-size: 5rem;
  }
  @media ${device.mobileL} {
    font-size: 5rem;
  }
  @media ${device.inBetween} {
    font-size: 6rem;
  }
  @media ${device.tablet} {
    font-size: 7rem;
  }
  @media ${device.laptop} {
    font-size: 7 drem;
  }
  @media ${device.desktop} {
    font-size: 5rem;
  }
`;

export const ProductName = styled.span`
  align-self: flex-end;
`;

export const BrandingContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-self: flex-end;
  -webkit-box-pack: center;
  justify-content: center;
  align-items: end;
  margin-top: 3.5rem;

  @media ${device.mobileS} {
    align-self: flex-end;
  }

  svg {
    color: ${grabbitColor.concentration};
    margin: 0px;
    margin-bottom: 1rem;
  }

  div:first-child {
    width: 80%;
    justify-content: space-between;
  }
`;

export const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: right;

  span {
    font-weight: 800;
    font-size: 1.5rem;
  }

  svg {
    margin-bottom: 0;
  }
`;

export const Logo = styled(GrabbitLogo)`
  width: auto;
  height: 30px;
  margin: 0 10px 0 24px;
  fill: ${({ color }) => color || `${grabbitColor.concentration}`};
`;

export const CloseButtonWrapper = styled.div`
  width: 30px;
  height: 30px;
  position: absolute;
  top: 25px;
  right: 15px;
  transform-origin: center;
  cursor: pointer;
  border: 2px solid ${grabbitColor.concentration};
  border-radius: 50px;

  & :hover {
    color: blue;
    cursor: pointer;
  }
`;

export const CloseButton = styled(Close)`
  color: ${grabbitColor.concentration};
  width: 20px;
  height: 25px;
  transform-origin: center;
`;
