import { Link } from "react-router-dom";
import { useState, useRef } from "react";
import emailjs from '@emailjs/browser';
import { 
  PageContainer,
  HelpContainer,
  Question, 
  TopInfoContainer,
  GetHelpImage,
  TextArea,
  Input,
  GetHelpButton,
  ModalContainer,
  ModalContent,
  MainHelpContainer,
  CloseButtonWrapper,
} from './help.styles';

import { useSelector } from "react-redux";
import { selectCurrentUser } from "../../store/user/user.selector";

import Dropdown from "../../components/dropdown-input/dropdown-input.component";
import gethelp from "../../assets/gethelp.jpg";
import grabbitlogo from "../../assets/grabbit_logo_stacked.svg"

import CloseButton from "../../components/close-button/close-button.component";
import TextAreaInput from "../../components/inputs/text-area/text-area.component";
import { logger } from "../../utils/logger/logger.utils";

const SERVICE_ID = process.env.REACT_APP_EMAILJS_SERVICE_ID;
const TEMPLATE_ID = process.env.REACT_APP_EMAILJS_TEMPLATE_ID;
const PUBLIC_KEY = process.env.REACT_APP_EMAILJS_PUBLIC_KEY;

const helpOptions = [
  {
    id: 0,
    value: "default",
    name: "The Issue",
  },
  {
    id: 1,
    value: "ORDER ISSUE",
    name: "There’s a problem with my order.",
  },
  {
    id: 2,
    value: "ACCOUNT",
    name: "I have a question about my account.",
  },
  {
    id: 3,
    value: "CUSTOMER SERVICE",
    name: "I wanna see the manager!",
  },
  {
    id: 4,
    value: "OTHER",
    name: "You’ll never guess.",
  }
]

const Help = () => {
  const currentUser = useSelector(selectCurrentUser)

  const defaultFormFields = {
    email: currentUser ? currentUser.email : '',
    issue: '',
    message: '',
  };

  const [formFields, setFormFields] = useState(defaultFormFields);
  const [showModal, setShowModal] = useState(false);

  const sendEmail = () => {

    emailjs.send(
      SERVICE_ID,
      TEMPLATE_ID,
      {
      from_name: currentUser ? currentUser.displayName : 'A Friendly Grabbit User',
      to_name: "Grabbit Help Desk",
      issue: formFields.issue,
      message: formFields.message,
      from_email: formFields.email ? formFields.email : 'guest@gograbb.it',
      },
      {publicKey: PUBLIC_KEY,}
      );      
  }
  
  const handleSubmit = async (event) => {
    event.preventDefault();
    
    if(formFields.issue !== '' && formFields.message !== '' && formFields.email !== ''){
      sendEmail();
      setShowModal(true);
    }
  }

  const handleCloseModal = () => {
    setFormFields(defaultFormFields);
    setShowModal(false);
    window.location.reload();
  };

  const handleChange = (field, val) => {
    setFormFields(prevState => ({
      ...prevState,
      [field]: val,
    }));
  };

  const form = useRef();

  return (
    <PageContainer>
      <GetHelpImage>
        <img src={gethelp} alt="#"/>
      </GetHelpImage>
      <MainHelpContainer>
        <TopInfoContainer>
          <h1>WE'RE HERE FOR YOU</h1>
          <h2>Help may be a four-letter word, but there’s nothing wrong with asking for it.</h2>
        </TopInfoContainer>
        <HelpContainer ref={form}>
          <h3>What seems to be the issue?*</h3>
          <Dropdown 
            onChange={(event) => handleChange('issue', event.target.value)} 
            options={helpOptions} 
            value={formFields.issue}
          />
          <Question>
            <h3>Drop some knowledge here.</h3>
            <TextAreaInput 
            onChange={(event) => handleChange('message', event.target.value)} 
            placeholder="#themoreweknow" 
            value={formFields.message}
          />          </Question>
          {currentUser ? (
            <Question>
              <h3>Is this the right email address?</h3>
              <h5>{currentUser.email}</h5>
              <p>Not you? <Link to='/auth'>Log out.</Link> </p>
            </Question>) : (
            <Question>
              <h3>What’s your email address?</h3>
              <Input onChange={(event) => handleChange('email', event.target.value)}  placeholder="This is where we'll contact you!" />
              <p>We can help you better if you <Link to='/auth'>sign in.</Link></p>
            </Question>)}
            <GetHelpButton onClick={handleSubmit}>Submit</GetHelpButton>
            {showModal && (
              <ModalContainer>
                <ModalContent>
                  <CloseButtonWrapper><CloseButton callbackMethod={handleCloseModal} /></CloseButtonWrapper>
                  <img src={grabbitlogo} alt="#"/>
                  <h2>Thank you for submitting!</h2>
                </ModalContent>
              </ModalContainer>
            )}
          </HelpContainer>
          <p>Don't like forms? You can also shoot us an email to <a href="mailto:hello@gograbb.it">hello@gograbb.it</a> and let us know how we can help.</p>
        </MainHelpContainer>
    </PageContainer>
  )
};

export default Help;

