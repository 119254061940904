import { useEffect, useState } from "react";
import {
  DropdownContainer,
  DropdownLabel,
  DropdownSelect,
  Option,
} from "./dropdown-input.styles";

const defaultOptions = [
  {
    id: 0,
    value: "default",
    name: "Default",
  },
];

const Dropdown = ({ options = [], ...otherProps }) => {
  const [dropdownOptions, setDropdownOptions] = useState([]);

  useEffect(() => {
    setDropdownOptions(options.length ? options : defaultOptions);
  }, [options]);

  return (
    <DropdownContainer>
      <DropdownLabel>
        <DropdownSelect {...otherProps}>
          {dropdownOptions.map((option) => (
            <Option key={option.id} value={option.value}>{option.name}</Option>
          ))}
        </DropdownSelect>
      </DropdownLabel>
    </DropdownContainer>
  );
};

export default Dropdown;
