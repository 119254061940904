import styled from "styled-components";
import { grabbitColor } from "../../utils/styled/colors";

export const DropdownContainer = styled.div`
  margin-bottom: 1.5rem;
`;

export const DropdownLabel = styled.label`
  font-size: 1rem;
  color: ${grabbitColor.warpWhite};
`;

export const DropdownSelect = styled.select`
  height: 2rem;
  width: 100%;
  padding: 0rem .5rem;
  color: ${grabbitColor.warpWhite};
  background-color: ${grabbitColor.enduranceDarkGray};
  border: none;

  ::-webkit-input-placeholder {
    color: ${grabbitColor.concentration};
    opacity: .75 !important;
  }
`;

export const Option = styled.option`
  background-color: ${grabbitColor.warpWhite};
  color: ${grabbitColor.warpWhite}
`;