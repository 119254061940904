import styled from "styled-components";
import { grabbitColor } from "../../../utils/styled/colors";
import { grabbitFont } from "../../../utils/styled/fonts";

const primaryColor = `${grabbitColor.enduranceDarkGray}`;
const secondaryColor = `${grabbitColor.concentration}`;
const hightlightColor = `${grabbitColor.ultrapatiencePurple_300}`;
const textColor = `${grabbitColor.warpWhite}`;

const shrinkLabel = () => `
  top: 0px;
  font-size: 12px;
  color: ${primaryColor};
`;

export const TextAreaContainer = styled.div`
  position: relative;
  height: auto;
`;

export const CustomTextArea = styled.textarea`
  font-family: ${grabbitFont.mainGrabbitFont};
  background: none;
  background-color: ${primaryColor};
  color: ${textColor};
  font-size: 16px;
  padding: .75rem .75rem;
  display: block;
  width: ${(otherProps) => otherProps.width ? otherProps.width : '100%'};
  border: 1px solid ${secondaryColor};
  border-radius: 0;
  margin: 30px 0;
  overflow: hidden;
  resize:none;
  appearance: none;
  min-height: 125px;
  transition: all 0.1s linear;

  &:focus, &:hover:focus{
    outline: none;
    box-shadow: 0 0 0 3px ${grabbitColor.ultrapatiencePurple}, 0 0 0 4px ${grabbitColor.alpha('ultrapatiencePurple', 0.7)}, 0 1px 2px 0 rgba(0,0,0,0);
    border: 1px solid ${grabbitColor.alpha('ultrapatiencePurple', 0.5)};    
  }

  &:hover {
    outline: none;
    border: 1px solid ${grabbitColor.ultrapatiencePurple_300};
    box-shadow: 0 0 0 3px ${grabbitColor.ultrapatiencePurple}, 0 0 0 4px ${grabbitColor.alpha('ultrapatiencePurple', 0.1)}, 0 1px 2px 0 rgba(0,0,0,0);
  }
`;

export const InfoText = styled.p`
  color: ${primaryColor};
  font-size: 12px;
  font-weight: normal;
  position: absolute;
  top: 40px;

  &.shrink {
    font-size: 0px;
  }
`;