import React from "react";

import { selectCartCount, selectCartItems } from '../../store/cart/cart.selector'
import { logger } from "../../utils/logger/logger.utils";
import CheckoutForm from "../../components/checkout-components/checkout-form/checkout-form.component";

import { useSelector } from 'react-redux/es/hooks/useSelector'
import { clearAllCartItems, setCartTimeOut } from "../../store/cart/cart.reducer";

import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";

import ToolTip from "../../components/tooltip/tooltip.component";
import { TextButton } from "../../components/button/button.styles";
import DelayedRender from "../../components/delayed-component-render/delayed-component-render.component";

import { PayPalScriptProvider } from "@paypal/react-paypal-js";
import { ActiveGrabbContainer } from "../grabbit/grabbit.styles";
import { CardContainer, InfoContainer } from "../../components/grabb-card/grabb-card.styles";
import { PageContainer } from "../../app.styles";

import GrabbImage from "../../components/grabb-card/grabb-image/grabb-image.component";
import Spinner from "../../components/spinner/spinner.component";
import { Helmet } from "react-helmet-async";
import MNTNConversionPixel, { MNTNPixel } from "../../utils/pixels/mntn-pixel";
import { dropGrabb } from "../../components/grabb-card/utility-functions/cart.utils";

const Checkout = () => {

  const cartItems = useSelector(selectCartItems)
  const cartCount = useSelector(selectCartCount);

  const initialOptions = {
    "client-id": process.env.REACT_APP_PAYPAL_CLIENT_ID,
    "enable-funding": "applepay",
    "disable-funding": "paylater",
    "data-page-type": "checkout",
    currency: "USD",
    components: "buttons",
    "data-sdk-integration-source": "developer-studio",
    intent: "capture",
  };

  const dispatch = useDispatch()
  const navigate = useNavigate();

  const tooltipText = 'If you choose to drop this Grabb and try again for a better price, it will be removed from your cart.'

  const handleClick = async (event) => {
    event.preventDefault()
    try {
      // Send drop request
      dropGrabb(cartItems[0]?.id)

      //Reset the cart
      dispatch(clearAllCartItems());
      dispatch(setCartTimeOut(null));

      //Go back to the grabb page
      navigate('/grabb');
    }
    catch (error) {

    }
  }

  const ButtonPlaceholder = (
    <TextButton onClick={handleClick}></TextButton>
  )

  logger.log('Cart Count: ', cartCount)
  logger.log('Cart Item: ', cartItems)

  return (
    <PageContainer>
      <ActiveGrabbContainer>
        {cartCount < 1 || !cartItems ?
          <Spinner />
          :
          <CardContainer>
            <Helmet>
              <title>You Grabbed: {cartItems[0].name}!</title>
            </Helmet>
            <MNTNConversionPixel orderId={'orderId'} totalAmount={50} />
            <GrabbImage image={cartItems[0].image} />
            <InfoContainer>
              <PayPalScriptProvider options={initialOptions}>
                <ToolTip text={tooltipText}>
                  <DelayedRender placeholder={ButtonPlaceholder}>
                    <TextButton onClick={handleClick} underline={true} width={'100%'}>Drop This Grabb And Try Again</TextButton>
                  </DelayedRender>
                </ToolTip>
                <CheckoutForm />
              </PayPalScriptProvider>
            </InfoContainer>
          </CardContainer>
        }
      </ActiveGrabbContainer>
    </PageContainer>
  )
}

export default Checkout
