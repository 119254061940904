import styled from 'styled-components';
import { grabbitColor } from '../../utils/styled/colors';
import { grabbitFont } from '../../utils/styled/fonts';

export const BaseButton = styled.button`
  display: flex;
  position: relative;
  overflow: hidden;
  min-width: 165px;
  max-width: ${(otherProps) => otherProps.width ? otherProps.width: '300px'};
  width: ${(otherProps) => otherProps.width ? otherProps.width : '100%'};
  height: 50px;
  letter-spacing: 0.5px;
  padding: 0 35px 0 35px;
  font-size: 12px;
  text-transform: uppercase;
  font-family: ${grabbitFont.mainGrabbitFont};
  font-weight: bolder;
  border: 3px solid transparent;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  margin: 1rem auto;

  background-color: ${grabbitColor.ultrapatiencePurple};
  color: ${(otherProps) => otherProps.textColor ?  otherProps.textColor : grabbitColor.warpWhite};

  transition: background-color .2s, color .2s, border-color .2s, box-shadow .2s;


  &:hover {
    background-color: ${grabbitColor.ultrapatiencePurple_300};
    border: 3px solid ${grabbitColor.ultrapatiencePurple_300};
    color: ${grabbitColor.warpWhite};
  }

  &:active{
    background: ${grabbitColor.ultrapatiencePurple};
    border: 3px solid ${grabbitColor.ultrapatiencePurple};
    color: ${grabbitColor.enduranceDarkGray}
  }

  &:focus{
    border: 3px solid ${grabbitColor.enduranceLightGray};
    box-shadow: 0 0 0 3px ${grabbitColor.ultrapatiencePurple}, 0 0 0 4px ${grabbitColor.alpha('ultrapatiencePurple', 0.7)}, 0 1px 2px 0 rgba(0,0,0,0);
  }

`;

export const SecondaryButton = styled.button`
  display: flex;
  position: relative;
  overflow: hidden;
  min-width: 165px;
  max-width: ${(otherProps) => otherProps.width ? otherProps.width: '300px'};
  width: ${(otherProps) => otherProps.width ? otherProps.width : '100%'};
  height: 50px;
  letter-spacing: 0.5px;
  padding: 0 35px 0 35px;
  font-size: 12px;
  text-transform: uppercase;
  font-family: ${grabbitFont.mainGrabbitFont};
  font-weight: bolder;
  border: 3px solid transparent;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  margin: 1rem auto;

  background-color: ${grabbitColor.flashYellow};
  color: ${(otherProps) => otherProps.textColor ?  otherProps.textColor : grabbitColor.enduranceDarkGray};

  transition: background-color .2s, color .2s, border-color .2s, box-shadow .2s;


  &:hover {
    background-color: ${grabbitColor.warpWhite};
    border: 3px solid ${grabbitColor.warpWhite};
    color: ${grabbitColor.enduranceDarkGray};
  }

  &:active{
    background: ${grabbitColor.flashYellow};
    border: 3px solid ${grabbitColor.flashYellow};
    color: ${grabbitColor.enduranceDarkGray}
  }

  &:focus{
    border: 3px solid ${grabbitColor.enduranceLightGray};
    box-shadow: 0 0 0 3px ${grabbitColor.flashYellow}, 0 0 0 4px ${grabbitColor.alpha('flashYellow', 0.7)}, 0 1px 2px 0 rgba(0,0,0,0);
  }

`;

export const GoogleSignInButton = styled(BaseButton)`
  background-color: #4285f4;
  color: ${grabbitColor.warpWhite};

  &:hover {
    background-color: #357ae8;
    border: 3px solid transparent;
  }

  &:focus{
    border: 3px solid ${grabbitColor.enduranceLightGray};
    box-shadow: 0 0 0 3px rgb(53, 122, 232), 0 0 0 4px rgba(53, 122, 232, .7), 0 1px 2px 0 rgba(0,0,0,0);
  }
`;

export const InvertedButton = styled(BaseButton)`
  background-color: ${grabbitColor.warpWhite};
  color: ${(otherProps) => otherProps.textColor ?  otherProps.textColor : grabbitColor.concentration};
  border: 1px solid ${grabbitColor.concentration};

  &:hover {
    background-color: ${grabbitColor.concentration};
    color: ${grabbitColor.warpWhite};
    border: 3px solid transparent;
  }

  &:focus{
    border: 3px solid ${grabbitColor.enduranceLightGray};
    box-shadow: 0 0 0 3px ${grabbitColor.warpWhite}, 0 0 0 4px ${grabbitColor.alpha('flashYellow', 0.7)}, 0 1px 2px 0 rgba(0,0,0,0);
  }
`;

export const DisabledButton = styled(BaseButton)`
  background-color: ${grabbitColor.enduranceDarkGray};
  color: ${grabbitColor.alpha('warpWhite', 0.4)};
  border: 1px solid ${grabbitColor.concentration};
  pointer-events: none;
`;

export const OutlineButton = styled(BaseButton)`
  background-color: rgba(0,0,0,0);
  color: ${grabbitColor.ultrapatiencePurple};
  border: 3px solid ${grabbitColor.ultrapatiencePurple};
`;

export const TextButton = styled(BaseButton)`
  background-color: transparent;
  color: ${(otherProps) => otherProps.textColor ? otherProps.textColor : grabbitColor.flashYellow};
  border: 3px solid transparent;
  font-size: 16px;
  font-weight: 900;
  min-width: 75px;
  width: ${(otherProps) => otherProps.width ? otherProps.width : '100%'};
  border: 3px solid transparent;
  text-decoration-line: ${(otherProps) => otherProps.underline ? 'underline' : ''};;
  text-underline-offset: 8px;

  &:hover{
    background-color: ${grabbitColor.alpha('flashYellow', 0.1)};
    color: ${(otherProps) => otherProps.textColor ? otherProps.textColor : grabbitColor.flashYellow};
    border: 3px solid transparent;
  }

  &:focus{
    border: 3px solid ${grabbitColor.enduranceLightGray};
    box-shadow: 0 0 0 3px ${grabbitColor.flashYellow}, 0 0 0 4px ${grabbitColor.alpha('flashYellow', 0.7)}, 0 1px 2px 0 rgba(0,0,0,0);
  }
`;

