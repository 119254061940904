import styled from "styled-components";
import { Link } from "react-router-dom";
import { grabbitColor } from "../../utils/styled/colors";

export const MenuContainer = styled.div`
  color: ${grabbitColor.concentration};
  background-color: ${grabbitColor.flashYellow};
`;

export const LinkContainer = styled.div`
  height: auto;
  display: grid;
  margin-left: 1rem;
  margin-top: 15rem;
  grid-template-columns: repeat(1, 1fr);
  align-items: center;
  align-content: center;
  text-align: left;
  position: absolute;
  bottom: 100px;

  p {
      font-size: .75rem;
      color: ${grabbitColor.concentration};
      text-transform: uppercase
  }

  a {
      font-size: .75rem;
      color: ${grabbitColor.concentration};
      padding: 0px 0px;
      text-transform: uppercase
  } 
  
  div {
    margin-top: -.75rem;
  }
`;

export const MenuLink = styled(Link)`
  max-width: 430px;
  height: 100%;
  margin: 8px 0;
`;