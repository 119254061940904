import styled from "styled-components"

export const MainContainer = styled.div`
  padding: 20px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  text-align: center;
`

export const SuccessMessage = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  div {
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  h1 {
    font-size: 2rem;
  }

  p {
    margin-bottom: 2.5rem;
  }
`

export const EmptySession = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`