import SocialIcons from '../../../social-icons/social-icons.component';
import {
  GrabbedItContainer,
  Logo,
  CloseButtonWrapper,
  CloseButton,
  GrabbedMessage,
  GrabbedImage,
  BrandingContainer,
  GrabbedPrice,
  ProductName,
  GrabbedItOverlayContainer,
  LogoWrapper,
  MarqueeContainer, 
  GrabbedText,
  GrabbedMarquee
} from './grabbed-it-overlay.styles';

import { Fragment, useState } from 'react';

const GrabbedItOverlay = ( { productName ='', price, imageUrl ='' } ) => {
  const [isVisible, setIsVisible] = useState(true);
  const grabbPrice = (Number(price)).toFixed(2);

  const onClickHandler = () => {
      setIsVisible(false);
  }

  return (
    <Fragment>
        {isVisible ?
        <GrabbedItOverlayContainer>
          <GrabbedItContainer>
          <MarqueeContainer>
            <GrabbedMarquee speed={1} direction={"right"}>
              <GrabbedText>
                GRABBED GRABBED GRABBED GRABBED GRABBED GRABBED GRABBED GRABBED GRABBED GRABBED GRABBED GRABBED GRABBED GRABBED GRABBED GRABBED GRABBED GRABBED GRABBED GRABBED GRABBED GRABBED
              </GrabbedText>
            </GrabbedMarquee>
          </MarqueeContainer>
                <CloseButtonWrapper>
                    <CloseButton onClick={onClickHandler} />
                </CloseButtonWrapper>
                <GrabbedMessage>YOU GRABBED IT!</GrabbedMessage>
                <GrabbedPrice>{`$${grabbPrice}`}</GrabbedPrice>
                <GrabbedImage src={imageUrl} />
                <ProductName>{productName}</ProductName>
                <BrandingContainer>
                    <SocialIcons />
                    <LogoWrapper>
                      <Logo />
                      <span>GRABBIT!</span>
                    </LogoWrapper>
                </BrandingContainer>
            </GrabbedItContainer>
        </GrabbedItOverlayContainer>
            : ''
        }
    </Fragment>
  )
};

export default GrabbedItOverlay;