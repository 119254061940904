import styled from "styled-components";
import SocialIcons from '../social-icons/social-icons.component';
import { grabbitColor } from "../../utils/styled/colors";
import { grabbitFont } from "../../utils/styled/fonts";

export const ModalBackdrop = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.5);
  width: 100vw;
  height: 100vh;
  z-index: 999;
  top: 0;
  left: 0;
  position: absolute;
`;

export const Title = styled.h1`
  font-size: 2.5rem;
  font-weight: 900;
  margin-bottom: 0rem;
`;

export const DisplayContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 350px;
  height: 65vh;
  margin: 0rem 0rem -1rem;
  text-align: center;
  align-items: center;
  justify-content: center;
  background: none;
`;

export const DisplayWrapper = styled.div`
  border-radius: 0px 0px 30px 30px;
  background: ${grabbitColor.flashYellow};
  color: ${grabbitColor.concentration};
  flex: 1;
  padding: 2rem 1.5rem 0rem;

  span {
    font-size: 1.25rem;
    display: block;
    text-align: center;
    width: 75%;
    margin: auto;
  }
`;

export const DisplayHeader = styled.h2`
  font-size: 2.5rem;
  color: ${grabbitColor.concentration};
`;

export const BottomInfoWrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding-top: 1rem;
  justify-content: center;
  background: ${grabbitColor.flashYellow};
  border-radius: 30px 30px 0px 0px;
  flex: 1;
  width: 100%;
  margin-bottom: 6rem;

  svg {
    color: ${grabbitColor.concentration};
  }
`;

export const SocialLogo = styled(SocialIcons)`
  width: 80%;
  max-width: 300px;
  height: auto;
  margin: 1rem auto;
  z-index: 10;
`;

export const DropGrabbInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 15px;
  justify-content: center;
`;

export const GrabbNumberContainer = styled.div`
  border: 1px solid ${grabbitColor.concentration};
  border-radius: 10px;
  font-size: 28px;
  padding: 1px 1rem;
  margin: -1rem;
`;

export const AgainButton = styled.button`
  display: flex;
  min-width: 165px;
  width: 70%;
  height: 50px;
  letter-spacing: 0.5px;
  line-height: 50px;
  padding: 0 35px 0 35px;
  font-size: .85rem;
  background-color: ${grabbitColor.ultrapatiencePurple};
  color: ${grabbitColor.warpWhite};
  text-transform: uppercase;
  font-family: ${grabbitFont.mainGrabbitFont};
  font-weight: bolder;
  border: none;
  cursor: pointer;
  justify-content: center;
  margin: 8px auto;

  &:hover {
    background-color: ${grabbitColor.warpWhite};
    color: ${grabbitColor.concentration};
    border: 1px solid ${grabbitColor.concentration};
  }
`;