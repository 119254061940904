import {
  PageContainer,
  PageTitle,
  InfoContainer,
  VideoiFrame,
  VideoContainer,
  VideoWrapper,
} from './how-it-works.styles';


import { useEffect, useState } from 'react';
import HowItWorksSteps from '../../components/how-it-works-steps/how-it-works-steps.component';
import { getDocByID, getDocumentByPath, getPage } from '../../utils/firebase/firebase.utils';
import { logger } from '../../utils/logger/logger.utils';

const HowItWorks = () => {
  const [page, setPage] = useState({});

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);


  useEffect(() => {

    const fetchPage = async () => {
      try {
        const pageData = await getPage('pages', 'how-it-works');
        setPage(pageData);

      } catch (error) {
        logger.error(error);
      }
    };
    fetchPage()
  }, [])

  return (
    <PageContainer>
      <PageTitle>How it works </PageTitle>
      <InfoContainer>
        <p>Grabbit is a brand new way to get quick deals and savings on products you need and want, whether you know it or not. Featuring limited-time deals on everything from electronics to home goods to new gadgets and gear, there's something for everyone.</p>
      </InfoContainer>
      <HowItWorksSteps steps={page.content_blocks} />
      <VideoContainer>
        <VideoWrapper>
          <script src="https://player.vimeo.com/api/player.js" />
          <VideoiFrame src="https://player.vimeo.com/video/851074997?title=0&portrait=0&byline=0" frameborder="0" />
        </VideoWrapper>
      </VideoContainer>
    </PageContainer>
  )
}

export default HowItWorks