import React, { useState, useEffect } from 'react';
import Spinner from '../../spinner/spinner.component.jsx';
import GrabbedModal from '../../alert-modals/grabbed-modal.component.jsx';
import { logger } from '../../../utils/logger/logger.utils.js';

import BadgeOverlay from '../badge-overlay/badge-overlay.component.jsx';

import {
  NextIcon,
  PreviousIcon,
  ImageSwitcherButton,
  ImageControlsContainer,
  PriceContainer,
  RetailPriceContainer,
  Details,
  ImageContainer,
  MainImage,
  ActivePrice,
  ImageScaffold,
} from './grabb-image.styles.jsx';

import FetchTheProductImages from '../utility-functions/image.utils.jsx';
import RealTimePrice from '../../real-time-price-display/real-time-price-display.component.jsx';
import InventoryWarning from './inventory-warnings/inventory-warnings.component.jsx';
import GrabbedNotice from './grabbed-notice/grabbed-notice.component.jsx';
import { useRealtimeData } from '../../../utils/firebase/realtime-database.utils.js';

// imageUrls={[imageUrl]}
// name={name}
// retailPrice={retailPrice}
// bestPrice={bestPrice}

const GrabbImage = ({
  image,
  price,
  bestPrice,
}) => {

  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [imageLoaded, setImageLoaded] = useState(false);
  const [imageUrls, setImageUrls] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const currentSaleProduct = useRealtimeData({ collectionName: "currentsale", documentName: "product" })


  const {
    name,
    retailPrice,
    main_image,
    cart_image,
    more_images,
  } = currentSaleProduct || {};

  const displayPrice = price?.toLocaleString("en-US", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  //Short load timer to prevent layout shift
  useEffect(() => {
    setIsLoading(true);
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 1250);
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    const fetchImages = async () => {
      const urls = [main_image];
      const newImageUrls = []; // Local variable to accumulate new URLs

      try {
        for (let index = 0; index < urls.length; index++) {
          const url = urls[index];
          if (url) {
            const result = await FetchTheProductImages(url);
            // Check against the accumulated array instead of the state
            if (!newImageUrls.includes(result)) {
              newImageUrls.push(result);
            }
          }
        }

        //If there are more images to display add them to the array or images
        if (more_images) {
          try {
            for (let index = 0; index < more_images.length; index++) {
              const url = more_images[index];
              if (url) {
                const result = await FetchTheProductImages(url);

                // Check against the accumulated array instead of the state
                if (!newImageUrls.includes(result)) {
                  newImageUrls.push(result);
                }
              }
            }

          } catch (error) {

          }
        }
        // Update the state once after all URLs are processed
        setImageUrls(imageUrls => [...newImageUrls]);

      } catch (error) {
        logger.error('Error fetching images:', error);
      }
    };
    fetchImages();
  }, [main_image]);


  useEffect(() => {
    if (imageUrls && imageUrls.length > 0) {
      const img = new Image();
      img.onload = () => setImageLoaded(true);
      img.src = imageUrls[currentImageIndex];
    }
  }, [imageUrls, currentImageIndex]);

  const nextImage = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex + 1) % imageUrls.length);
    logger.log('Image count: ', imageUrls.length)
    logger.log('Current Image: ', imageUrls[currentImageIndex])

  };

  const prevImage = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex - 1 + imageUrls.length) % imageUrls.length);
  };

  const PriceDetails = () => (
    <>
      {retailPrice && (
        <Details style={{ background: 'none' }}>
          <RetailPriceContainer>
            <h3>RETAIL PRICE: {`$${retailPrice}`}</h3>
          </RetailPriceContainer>
        </Details>
      )}
      {<PriceContainer>
        <PriceContent />
      </PriceContainer>}
    </>
  );

  const PriceContent = () => (
    <>
      {bestPrice ? (
        <>
          <ActivePrice className='price'>{bestPrice ? `$${Number(bestPrice).toFixed(2).toLocaleString()}` : ''}</ActivePrice>
          <BadgeOverlay text={'BEST GRABBED PRICE!'} />
        </>
      ) : (
        (
          <>
            <h3 className="title">CURRENT PRICE</h3>
            {price ? <ActivePrice className='price'>{`$${displayPrice}`}</ActivePrice> : <ActivePrice className='price'><RealTimePrice /></ActivePrice>}
          </>
        )
      )}
    </>
  );

  const scaffoldStyles = {
    border: '1px solid white',
  }

  return (
    <ImageContainer>
      {isLoading ?
        <ImageScaffold /> :
        <>
          <GrabbedNotice />
          <InventoryWarning />
          <ImageControlsContainer>
            <ImageSwitcherButton onClick={prevImage}><PreviousIcon /></ImageSwitcherButton>
            <ImageSwitcherButton onClick={nextImage}><NextIcon /></ImageSwitcherButton>
          </ImageControlsContainer>
          <MainImage
            src={imageUrls[currentImageIndex]}
            style={{ display: imageLoaded ? 'block' : 'none' }}
            active={bestPrice !== null}
          />
        </>
      }
    </ImageContainer>
  );
}

export default GrabbImage;