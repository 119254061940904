import styled from "styled-components";
import { grabbitColor } from "../../../../utils/styled/colors";

export const DisplayContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 350px;
  height: 65vh;
  min-height: 100vh;
  margin: -3rem 0rem -4rem;
  text-align: center;
  align-items: center;
  justify-content: center;
  background: none;
`;

export const NoGrabbsDisplayWrapper = styled.div`
  background: ${grabbitColor.concentration};
  border-radius: 0px 0px 30px 30px;
  background: ${grabbitColor.flashYellow};
  color: ${grabbitColor.concentration};
  flex: 1;
  padding: 5rem 3.5rem 1rem;
`;

export const NoGrabbsDisplayHeader = styled.h2`
  font-size: 2.5rem;
  color: ${grabbitColor.concentration};
`;

export const BottomInfoWrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding-top: 1rem;
  justify-content: center;
  background: ${grabbitColor.flashYellow};
  border-radius: 30px 30px 0px 0px;
  flex: 1;
  width: 100%;
  color: ${grabbitColor.concentration};
  font-size: 3rem;
  margin-bottom: 22rem;

  svg {
    color: ${grabbitColor.concentration};
  }
`;