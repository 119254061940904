import styled from "styled-components";
import { grabbitColor } from "../../utils/styled/colors";
import Button from "../../components/button/button.component";

export const PreferenceContainer = styled.div`
  display: flex;
  margin: 0rem auto;
  width: 100vw;
  height: 100vh;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background: ${({ theme }) => theme.darkBackground};
  color: ${({ theme }) => theme.errorRed};
`;


export const ThemeSwitcherButtonContainer = styled.div`
  display: flex;

`

export const DarkThemeSwitcherButton = styled(Button)`

`

export const LightThemeSwitcherButton = styled(Button)`
  background-color: ${grabbitColor.warpWhite};
  color: ${grabbitColor.concentration};
  &:hover {
    background-color: ${grabbitColor.ultrapatiencePurple};
    color: ${grabbitColor.warpWhite};
    border: 1px solid ${grabbitColor.concentration};
  }

`