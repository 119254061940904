import styled from "styled-components";
import { grabbitColor } from "../../utils/styled/colors";
import Button from "../../components/button/button.component";

export const OnboardContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 100vw;
  min-height: 100vh;
  flex-wrap: wrap;
  margin: 4rem auto;
  align-items: center;
  justify-content: center;
  background: ${grabbitColor.warpWhite};
`

export const NextButton = styled(Button)`
  margin-top: 1.5rem;
  margin-bottom: 1rem;
  height: 2.5rem;
  text-align: center;
  line-height: 40px;
  width: 75%;
`;