import { Link, useLocation } from "react-router-dom";
import { useState, useEffect } from "react";
// import { updateGrabbs } from "../../utils/firebase/realtime-database.utils";
import {
  MainContainer,
  SuccessMessage,
  EmptySession,
} from "./success.styles.jsx";

import { useDispatch, useSelector } from "react-redux";
import { clearAllCartItems } from "../../store/cart/cart.reducer";
import { setGrabbsLeft } from "../../store/user/user.reducer";

import SocialIcons from "../../components/social-icons/social-icons.component";
import GrabbedItOverlay from "../../components/grabb-card/displays/grabbed-it-overlay/grabbed-it-overlay.component.jsx";
import Button from "../../components/button/button.component";
import { updateGrabbs } from "../../utils/firebase/realtime-database.utils.js";
import { logger } from "../../utils/logger/logger.utils.js";
import { selectCurrentUser } from "../../store/user/user.selector.js";
import { getDocByID } from "../../utils/firebase/firebase.utils.js";
import { getImageFromStorage } from "../../utils/firebase/storage.utlis.js";

const Success = () => {
  const dispatch = useDispatch();

  const location = useLocation();
  const [session, setSession] = useState(null);
  const [handledSessions, setHandledSessions] = useState([]);
  const [paidSessionHandled, setPaidSessionHandled] = useState(false);
  const sessionId = new URLSearchParams(location.search).get("session_id");

  const currentUser = useSelector(selectCurrentUser)

  useEffect(() => {
    dispatch(clearAllCartItems()); // clear cart items on successful payment
  }, [dispatch]);

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(location.search);
    const status = urlSearchParams.get("status");
    const amount_total = urlSearchParams.get("val"); // Renamed to follow camelCase convention
    const productId = urlSearchParams.get("reference_id"); // Renamed to follow camelCase convention

    setSession({
      status,
      amount_total,
      product_name: '' // Initialize product, will be updated later
    });

    const fetchProductData = async () => {
      if (!productId) {
        console.log('No product id?');
        return; // Exit early if no product ID is found
      }

      try {
        const fetchedProduct = await getDocByID('products', productId, true);
        const product_image = await getImageFromStorage(fetchedProduct.cart_image);
        setSession(prevSession => ({
          ...prevSession,
          product_name: fetchedProduct.name,
          product_image
        }));
      } catch (error) {
        console.error('Error fetching product:', error);
      }
    };

    fetchProductData();
    logger.log('Session data', session);

  }, [location.search, setSession]);


  return (
    <MainContainer>
      {session ? <GrabbedItOverlay
        productName={session.product_name}
        price={session.amount_total}
        imageUrl={session.product_image}
      />
        : ''
      }
      {session ?
        <SuccessMessage>
          <div>
            <h1>CONGRATS! YOUR GRABB IS ON ITS WAY.</h1>
            <p>
              You will receive your purchase confirmation and tracking information
              soon.
            </p>
            <Link to="/grabb">
              <Button>Grabbit Again!</Button>
            </Link>
          </div>
          <br />
          <div>
            <h2>Don’t miss the next drop.</h2>
            <SocialIcons />
          </div>
        </SuccessMessage>
        :
        <EmptySession>
          Success! <br />You have arrived on the page and it is loaded!
        </EmptySession>
      }
    </MainContainer>
  );
};

export default Success;