import styled from "styled-components";
import { grabbitColor } from "../../utils/styled/colors";
import { grabbitFont } from "../../utils/styled/fonts";
import Button from "../../components/button/button.component";

export const OnboardPageContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 26.875rem;
  height: 50.9375rem;
  margin: auto;
  margin-bottom: 2rem;
  align-items: center;
  justify-content: start;
  background: ${grabbitColor.warpWhite};
  padding: 0rem 1.4rem 1.4rem;

`

export const OnboardImage = styled.div`
  display: flex;
  width: 17.0625rem;
  height: 22.25rem;
`

export const OnboardText = styled.div`
  display: flex;
  align-items: center;

  h1 {
    text-align: center;
    color: ${grabbitColor.concentration};
    font-size: 2.25rem;
    font-family: ${grabbitFont.mainGrabbitFont};
    font-weight: 700;
    line-height: 136.1%;
    text-transform: uppercase;
    leading-trim: both;
    text-edge: cap;
  }
`

export const OnboardButtonContainer = styled.div` 
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 70%;
`;

export const OnboardButton = styled(Button)`
  margin-top: 1.5rem;
  margin-bottom: 1rem;
  height: 2.5rem;
  text-align: center;
  line-height: 40px;
  width: 75%;
  font-size: 1rem;
  font-family: ${grabbitFont.mainGrabbitFont};
  font-weight: 700;
  text-transform: uppercase;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
`;

export const SkipOnboard = styled.a`
  text-decoration: underline;
  cursor: pointer;
  font-size: 1rem;
  font-family: ${grabbitFont.mainGrabbitFont};
  color: ${grabbitColor.concentration}
`;