import { useState, useEffect } from 'react';
import {
  getDatabase,
  runTransaction,
  get,
  ref,
  child,
  onValue,
  off
} from "firebase/database";

import 'firebase/compat/auth';
import 'firebase/compat/database';
import 'firebase/compat/functions';

import { firebaseApp } from './firebase.utils'
import { SHA256 } from 'crypto-js';
import { logger } from '../logger/logger.utils';

const db = getDatabase(firebaseApp);

// Global cache
const cache = {
  subscriptions: {},
  data: {},
};

export const useRealtimeData = ({ collectionName, documentName, onChange }) => {
  const [data, setData] = useState(null);
  const path = documentName ? `${collectionName}/${documentName}` : collectionName;

  useEffect(() => {
    let dbRef = ref(db, path);

    // Ensure the subscription object for the path exists
    if (!cache.subscriptions[path]) {
      cache.subscriptions[path] = {
        dbRef,
        count: 0 // Initialize reference count
      };
    }

    cache.subscriptions[path].count++;

    const handleValue = (snapshot) => {
      const val = snapshot.val();
      cache.data[path] = val; // Update global cache
      // logger.log('Data changed in: ', collectionName, documentName ? ` at document ${documentName}`  : '');
      setData(val);
      if(onChange){
        onChange()
      }
    };

    const handleError = (error) => {
      logger.error("Error reading from Firebase Realtime Database:", error);
    };

    onValue(dbRef, handleValue, handleError);

    // Cleanup on unmount
    return () => {
      if (cache.subscriptions[path]) {
        cache.subscriptions[path].count--;
        if (cache.subscriptions[path].count === 0) {
          off(dbRef, "value", handleValue);
          delete cache.subscriptions[path];
        }
      }
    };
  }, [path, db, cache]);

  return data;
};





// Add UpdateGrabbs Function - will take the type of grab + how much it should increment
export const updateGrabbs = async (type, increment) => {
  const currentSaleRef = ref(db, 'currentsale');
  await runTransaction(currentSaleRef, (currentData) => {
    const updatedData = currentData || {};
    updatedData[type] = Math.max((updatedData[type] || 0) + increment, 0)
    return updatedData;
  });
};

export const setRealtimeData = async ({ collectionName = null, documentName = null, value = null }) => {
  if (!collectionName || collectionName.trim() === "") {
    logger.error("Invalid collectionName");
    return;
  }

  let dbRef = ref(db, collectionName);

  if (documentName && documentName.trim() !== "") {
    dbRef = ref(db, `${collectionName}/${documentName}`);
  }

  try {
    await runTransaction(dbRef, (currentData) => {
      return value;
    });
  } catch (error) {
    logger.error("Error updating data:", error);
    // Handle error if necessary
  }
};

export const getRealtimeValue = async ({ collectionName = null, documentName = null }) => {
  if (!collectionName || collectionName.trim() === "") {
    logger.error("Invalid collectionName");
    return;
  }

  let dbRef = ref(db, collectionName);

  if (documentName && documentName.trim() !== "") {
    dbRef = ref(db, `${collectionName}/${documentName}`);
  }

  try {
    return await dbRef.once('value');

  } catch (error) {
    logger.error("Error updating data:", error);
  }
}

// Hash IP address
const hashIP = (ipAddress) => {
  return SHA256(ipAddress).toString();
};

// Add viewer to the database
export const addViewer = async (ipAddress) => {
  const viewersRef = child(ref(db, 'currentsale'), 'viewers');

  await runTransaction(viewersRef, (currentValue) => {
    let viewers = currentValue || {};
    viewers[hashIP(ipAddress)] = Date.now(); // Add the hashed IP address
    return viewers;
  });
};

// Remove viewer from the database
export const removeViewer = async (ipAddress) => {
  const viewersRef = child(ref(db, 'currentsale'), 'viewers');
  await runTransaction(viewersRef, (currentValue) => {
    let viewers = currentValue || {};
    delete viewers[hashIP(ipAddress)]; // Remove the hashed IP address
    return viewers;
  });
};

// Get the count of unique viewers
export const getUniqueViewerCount = async () => {
  const viewersRef = child(ref(db, 'currentsale'), 'viewers');
  const snapshot = await get(viewersRef);
  const viewers = snapshot.val() || {};
  return Object.keys(viewers).length;
};

export const DisplayViewers = () => {
  const [totalViewers, setTotalViewers] = useState(0);

  useEffect(() => {
    const viewersRef = child(ref(db, 'currentsale'), 'viewers');
    onValue(viewersRef, (snapshot) => {
      setTotalViewers(snapshot.numChildren());
    });

    return () => {
      off(viewersRef, 'value');
    };
  }, []);

  return totalViewers;
};

export const getCurrentSale = async () => {
  const currentSaleRef = ref(db, 'currentsale');
  const snapshot = await get(currentSaleRef);
  const currentSaleData = snapshot.val();

  const { id, name, description, imageUrl, currentPrice, retailPrice, watchers, descriptionHeading } = currentSaleData;

  const currentSale = {
    id,
    name,
    description,
    descriptionHeading,
    imageUrl,
    currentPrice,
    watchers,
    retailPrice,
  };

  return currentSale;
};

