import styled, { keyframes, css } from "styled-components";
import { grabbitColor } from "../../utils/styled/colors";
import { grabbitFont } from "../../utils/styled/fonts";

// Define keyframe animation for the scrolling effect
const scroll = keyframes`
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-100%);
  }
`;

// Create a container component that wraps the content to animate
export const ScrollContent = styled.div`
  opacity: .1;
  display: flex;
  flex-wrap: nowrap;
  text-align: center;
  align-items: center;
  align-content: center;
  display: flex;
  white-space: nowrap;
  overflow: hidden;
  width: 100%;
  transform: rotate(-8deg);
  will-change: transform;
`;

// Create a content component that is a child of the container
// Apply animation and styles to the content
export const ScrollItem = styled.div`
  font-size: 150px;
  font-family: ${grabbitFont.mainGrabbitFont}, Arial, sans-serif;
  font-weight: bold;
  text-transform: uppercase;
  color: ${grabbitColor.enduranceDarkGray};
  display: inline-block;
  animation: ${scroll} 5s linear infinite;
  ${({ animateReverse }) =>
    animateReverse &&
    css`
      animation-direction: reverse;
    `}
  will-change: transform;
`;
