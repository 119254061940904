import {
    FormInputContainer,
    FormInputLabel,
    BaseFormInput,
    InfoText,
    DisabledFormInput
} from "./form-input.styles";

export const INPUT_TYPE_CLASSES = {
    base: 'base',
    disabled: 'disabled',
};

const getInputType = (inputType = INPUT_TYPE_CLASSES.base) => ({
    [INPUT_TYPE_CLASSES.base]: BaseFormInput,
    [INPUT_TYPE_CLASSES.disabled]: DisabledFormInput,
}[inputType]);

const FormInput = ({ label, inputType, tabIndex, ...otherProps }) => {
    const CustomInput = getInputType(inputType);
    return (
        <FormInputContainer className='group'>
            <CustomInput 
            className='form-input'
            tabIndex={tabIndex !== undefined ? tabIndex : (inputType === 'disabled' ? -1 : 0)} 
            {...otherProps}
            />
            <FormInputLabel
                className={`${otherProps.value?.length ? 'shrink' : ''
                    } form-input-label`}
            >
                {label}
            </FormInputLabel>
            <InfoText>{otherProps.info}</InfoText>
        </FormInputContainer>
    );
};

export default FormInput;
