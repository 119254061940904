import styled from "styled-components";
import { grabbitColor } from "../../utils/styled/colors";
import { device } from '../../utils/styled/breakpoints';

export const PromoSectionContainer = styled.div`
    z-index: 0;
    margin:  0 auto 10rem auto;
    padding: 1rem;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    background-color: ${(props) => props.backgroundColor || grabbitColor.flashYellow};
    color: ${(props) => props.textColor || grabbitColor.concentration};

    & h1{
        font-weight: 900;
        text-transform: uppercase;
        font-size: 50px;
    }

    & .title{
        margin: 3rem auto 0 auto;
    }


    @media ${device.inBetweenMax} {
        margin: auto;
    }
`

export const InfoContainer = styled.div`
    margin: 0 auto;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    & p{
        font-size: 36px;
    }
`

export const PromoTimerContainer = styled.div`
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
width: 100%;
height: 100%;
max-width: 1256px;
max-height: 1256px;
border-radius: 24px;
font-weight: bold;
text-align: center;
font-size: 64px;

@media ${device.inBetweenMax} {
    font-size: 32px;
}`

export const DetailInfoContainer = styled.div`
    width: 40%;
    margin: 2rem auto;
    background-color: ${(props) => props.backgroundColor || grabbitColor.flashYellow};
    color: ${(props) => props.textColor || grabbitColor.concentration};
    border-radius: 24px;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @media ${device.inBetweenMax} {
    width: 100%;
}
`

export const PromoImage = styled.div`
  width: auto;
  max-width: 500px;
  max-height: 500px;
  min-width: 300px;
  min-height: 300px;
  background-image: url(${(props) => props.image});
  background-size: cover;
  background-position: center;
  -webkit-box-shadow: inset 0px 0px 20px 20px ${(props) => props.backgroundColor || grabbitColor.concentration};
  -moz-box-shadow: inset 0px 0px 20px 20px ${(props) => props.backgroundColor || grabbitColor.concentration};
  box-shadow: inset 0px 0px 20px     20px ${(props) => props.backgroundColor || grabbitColor.concentration};
`;