import React from 'react'
import { LastWarningWrapper, LastWarningContainer, QuantityWarningContainer } from './inventory-warnings.styles';
import { logger } from '../../../../utils/logger/logger.utils';
import { useRealtimeData } from '../../../../utils/firebase/realtime-database.utils';


const QuantityWarning = () => (
      <QuantityWarningContainer>
        YIKES! THEY'RE ALMOST ALL GONE.
      </QuantityWarningContainer>
  );

  const LastOneWarning = () => (
      <LastWarningContainer>
        <LastWarningWrapper>
          OH BOY! LAST ONE LEFT.
        </LastWarningWrapper>
      </LastWarningContainer>
  );

const InventoryWarning = () => {

    const status = useRealtimeData({ collectionName: 'currentsale', documentName: 'status/currentStatus' });
  
}

export default InventoryWarning