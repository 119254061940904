import React, { useEffect, useMemo, useRef } from 'react';

import {
  BaseButton,
  SecondaryButton,
  GoogleSignInButton,
  InvertedButton,
  DisabledButton,
  OutlineButton,
  TextButton,
} from './button.styles';
import { debounce } from 'lodash';

export const BUTTON_TYPE_CLASSES = {
  base: 'base',
  secondary: 'secondary',
  google: 'google-sign-in',
  inverted: 'inverted',
  disabled: 'disabled',
  outline: 'outline',
  text: 'text',
};


const createRipple = (e, buttonElement) => {
  if (buttonElement.getElementsByClassName('ripple').length > 0) {
    buttonElement.removeChild(buttonElement.childNodes[1]);
  }

  const circle = document.createElement('div');
  buttonElement.appendChild(circle);

  const d = Math.max(buttonElement.clientWidth, buttonElement.clientHeight);
  circle.style.width = circle.style.height = `${d}px`;

  const rect = buttonElement.getBoundingClientRect();
  const x = e.clientX - rect.left;
  const y = e.clientY - rect.top;
  circle.style.left = `${x - d / 2}px`;
  circle.style.top = `${y - d / 2}px`;
  circle.classList.add('ripple');
};

const getButton = (buttonType = BUTTON_TYPE_CLASSES.base) => ({
  [BUTTON_TYPE_CLASSES.base]: BaseButton,
  [BUTTON_TYPE_CLASSES.secondary]: SecondaryButton,
  [BUTTON_TYPE_CLASSES.google]: GoogleSignInButton,
  [BUTTON_TYPE_CLASSES.inverted]: InvertedButton,
  [BUTTON_TYPE_CLASSES.disabled]: DisabledButton,
  [BUTTON_TYPE_CLASSES.outline]: OutlineButton,
  [BUTTON_TYPE_CLASSES.text]: TextButton,

}[buttonType]);

const Button = ({ children, buttonType, onClick, tabIndex, ...otherProps }) => {
  const CustomButton = getButton(buttonType);
  const buttonElement = useRef(null);

  const onClickHandler = (e, ripple = true) => {
    if (ripple) {
      createRipple(e, buttonElement.current);
      if (onClick) {
        onClick(e);
      }
    }
    else {
      onClick(e);
    }

  };


  // Create the debounced onClickHandler function when the component mounts
  useEffect(() => {
    const onClickHandlerThrottled = debounce(onClickHandler, 10000);
    return () => {
      // Cleanup the debounced function when the component unmounts
      onClickHandlerThrottled.cancel();
    };
  }, []); // Empty dependency array ensures this effect runs only once after the initial render


  return (
    <CustomButton
      ref={buttonElement}
      onClick={onClickHandler}
      tabIndex={tabIndex !== undefined ? tabIndex : (buttonType === 'disabled' ? -1 : 0)}
      {...otherProps}
    >
      {children}
    </CustomButton>
  );
};

export default Button;