import styled from "styled-components";
import { grabbitColor } from "../../utils/styled/colors";
import { grabbitFont } from "../../utils/styled/fonts";

export const PageContainer = styled.div`
  width: 100vw;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: ${grabbitColor.ultrapatiencePurple_600};
  padding: 5rem 0;
`

export const StepContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  max-width: 75rem;
  align-items: center;
  justify-content: center;
`

export const Step = styled.div`
  background-color: ${grabbitColor.ultrapatiencePurple};
  max-width: 350px;
  height: 300px;
  border-radius: 16px;
  padding: 32px;
  padding-bottom: 45px;
  margin: 1rem 2rem;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  justify-content: center;

  h3 {
    font-size: 10rem;
    margin: 0;
  }

  h4 {
    font-weight: 700;
    margin: 0rem;
  }

  p {
    margin-top: 0;
    font-size: 1rem;
  }
`

export const TopInfoContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  padding: 5%;
  color: ${grabbitColor.warpWhite};
  font-family: ${grabbitFont.mainGrabbitFont};
  
`
