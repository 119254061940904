import styled from "styled-components";
import { grabbitColor } from "../../../utils/styled/colors";

const primaryColor = `${grabbitColor.enduranceDarkGray}`;
const secondaryColor = `${grabbitColor.warpWhite}`;
const disabledColor = `${grabbitColor.enduranceLightGray}`;

const shrinkLabel = () => `
  top: -20px;
  font-size: 12px;
  color: ${secondaryColor};
`;

export const FormInputContainer = styled.div`
  position: relative;
`;

export const BaseFormInput = styled.input`
  background: none;
  background-color: ${primaryColor};
  color: ${secondaryColor};
  font-size: 14px;
  padding: .75rem .75rem;
  display: block;
  width: ${(otherProps) => otherProps.width ? otherProps.width : '100%'};
  border: ${(otherProps) => otherProps.invalid ? '2px solid red' : '2px solid transparent'};

  border-radius: 0;
  margin: 36px 0;

  transition: all 0.1s linear;


  &:focus, &:hover:focus{
    outline: none;
    box-shadow: 0 0 0 0.1rem ${grabbitColor.ultrapatiencePurple_300};
    border: 2px solid ${grabbitColor.alpha('ultrapatiencePurple', 0.5)};
  }

  &:hover {
    outline: none;
    border: 2px solid ${grabbitColor.ultrapatiencePurple};
  }

  &:focus ~ .form-input-label {
    ${shrinkLabel()};
  }

  input[type='password'] {
    letter-spacing: 0.3em;
  }
`;

export const FormInputLabel = styled.label`
  color: ${secondaryColor};
  font-size: 16px;
  font-weight: normal;
  position: absolute;
  pointer-events: none;
  left: 8px;
  top: 10px;
  transition: 300ms ease all;

  &.shrink {
    ${shrinkLabel()};
  }
`;

export const InfoText = styled.p`
  color: ${primaryColor};
  font-size: 12px;
  font-weight: normal;
  position: absolute;
  top: 40px;

  &.shrink {
    font-size: 0px;
  }
`;


export const DisabledFormInput = styled(BaseFormInput)`
    background-color: ${disabledColor};
    pointer-events: none;
    border: 1px solid ${grabbitColor.concentration};

    & ~ .form-input-label {
    color: ${grabbitColor.concentration};
  }
`;
