import styled from "styled-components";
import { grabbitColor } from "../../utils/styled/colors";

export const BasicMainContainer = styled.div`
  padding: 5%;
  margin: 5% auto;
  min-height: 100%;
  min-width: 320px;
  max-width: 1256px;
  width: 90%;
  text-align: justify;
  border: 1px solid ${grabbitColor.warpWhite};
  border-radius: 8px;
`;