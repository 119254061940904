import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  UserPreferenceContainer,
  EditPreferenceContainer,
  EditButton,
  FieldContainer,
  InputContainer,
  PreferenceBodyContainer,
} from "./user-preference.styles";
import {
  updateDisplayName,
  updateEmail,
  updateFirstName,
  updateLastName
} from "../../store/user/user.reducer";
import { selectCurrentUser } from "../../store/user/user.selector";
import FormInput from "../inputs/form-input/form-input.component";
import Button from "../button/button.component";

const UserPreferences = () => {
  const currentUser = useSelector(selectCurrentUser);
  const dispatch = useDispatch();

  const [displayName, setDisplayName] = useState("");
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [editingField, setEditingField] = useState(null);

  useEffect(() => {
    if (currentUser) {
      setDisplayName(currentUser.displayName);
      setEmail(currentUser.email);
      setFirstName(currentUser.firstName);
      setLastName(currentUser.lastName);
    }
  }, [currentUser]);

  const handleEdit = (field) => {
    setEditingField(field);
  };

  const handleSaveChanges = () => {
    switch (editingField) {
      case "displayName":
        dispatch(updateDisplayName(displayName));
        break;
      case "email":
        dispatch(updateEmail(email));
        break;
      case "firstName":
        dispatch(updateFirstName(firstName));
        break;
      case "lastName":
        dispatch(updateLastName(lastName));
        break;
      default:
        break;
    }

    setEditingField(null);
  };

  return (
    <UserPreferenceContainer>
      <PreferenceBodyContainer>
      <EditPreferenceContainer>
        <h3>Profile</h3>
        <FieldContainer>
          <span>Full Name</span>
          <InputContainer>
          {editingField === "firstName" || editingField === "lastName" ? (
              <>
              <FormInput
                type="text"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
              />
              <FormInput
                type="text"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
              />
              </>
          ) : (
            <div>
              {firstName} {lastName}
            </div>
          )}
          {editingField === "firstName" || editingField === "lastName" ? (
            <Button width={'50px'} onClick={handleSaveChanges}>SAVE</Button>
          ) : (
            <EditButton onClick={() => handleEdit("firstName")}>EDIT</EditButton>
          )}
          </InputContainer>
        </FieldContainer>
        
        <FieldContainer>
          <span>Display Name</span>
          <InputContainer>
          {editingField === "displayName" ? (
            <FormInput
              type="text"
              value={displayName}
              onChange={(e) => setDisplayName(e.target.value)}
            />
          ) : (
            <div>{displayName}</div>
          )}
          {editingField === "displayName" ? (
            <Button width={'50px'} onClick={handleSaveChanges}>SAVE</Button>
          ) : (
            <EditButton onClick={() => handleEdit("displayName")}>EDIT</EditButton>
          )}
        </InputContainer>
        </FieldContainer>

        <FieldContainer>
          <span>Email Address</span>
          <InputContainer>
          {editingField === "email" ? (
            <FormInput
              type="text"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          ) : (
            <div>{email}</div>
          )}
          {editingField === "email" ? (
            <Button width={'50px'} onClick={handleSaveChanges}>SAVE</Button>
            ) : (
            <EditButton onClick={() => handleEdit("email")}>EDIT</EditButton>
          )}
          </InputContainer>
        </FieldContainer>
      </EditPreferenceContainer>
      <hr />
      </PreferenceBodyContainer>
    
    </UserPreferenceContainer>
  );
};

export default UserPreferences;
