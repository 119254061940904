import React from 'react'
import BasicPage from "../../components/basic-page/basic-page.component";

const FAQ = () => {
    
    return (
        <BasicPage pageSlug={'faq'}/>
    );
}

export default FAQ