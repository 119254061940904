import styled from "styled-components";
import SocialIcons from '../../../social-icons/social-icons.component';
import { grabbitColor } from "../../../../utils/styled/colors";


export const BottomInfoWrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding-top: 1rem;
  justify-content: center;
  background: ${grabbitColor.flashYellow};
  border-radius: 30px 30px 0px 0px;
  flex: 1;
  width: 100%;
  svg {
    color: ${grabbitColor.concentration};
  }
`;

export const SocialLogo = styled(SocialIcons)`
  width: 80%;
  max-width: 300px;
  height: auto;
  margin: 2rem auto;
  z-index: 10;
`;