import styled from "styled-components";
import { grabbitColor } from '../../utils/styled/colors';

const ANIMATION_TIME = '8s';
const ANIMATION_TIMING_FUNCTION = 'linear';
const SHINE_COLOR = grabbitColor.alpha('warpWhite', .25);

export const ScaffoldingObject = styled.div`
  display: flex;
  flex-direction: column;
  align-self: ${(props) => props.align ? props.align : 'center'};
  justify-self: ${(props) => props.align ? props.align : 'center'};
  width: ${(props) => props.width ? props.width : '250px'};
  height: ${(props) => props.height ? props.height : '250px'};
  margin: ${(props) => props.margin ? props.margin : '1rem 0'};
  border-radius: ${(props) => props.radius ? props.radius : '24px'};
  background-color: ${(props) => props.backgroundColor ? props.backgroundColor : grabbitColor.alpha('enduranceLightGray', .15)};
  overflow: hidden;
  position: relative;

  @keyframes shine {
    0% {transform:translateX(-100%);}
    100% {transform:translateX(200%);}
  }
  @-webkit-keyframes shine {
    0% {transform:translateX(-100%);}
    100% {transform:translateX(200%);}
  }	 

    /* Shine, only rendered when type is not 'blank' */
    ${(props) => props.type !== 'blank' && `
    ::after {
      content: '';
      top: 0;
      left: 0;
      transform-origin: center left;
      transform: translateX(0%);
      width: 100%;
      height: 100%;
      position: absolute;
      z-index: 1;
      background-color: ${SHINE_COLOR};
      box-shadow: 0 0 50px 50px ${SHINE_COLOR};

      animation-name: shine;
      animation-duration: ${ANIMATION_TIME};
      animation-iteration-count: infinite;
      animation-timing-function: ${ANIMATION_TIMING_FUNCTION};
      -webkit-animation-name: shine;
      -webkit-animation-duration: ${ANIMATION_TIME};
      -webkit-animation-iteration-count: infinite;
      -webkit-animation-timing-function: ${ANIMATION_TIMING_FUNCTION};
    }
  `}
  `;