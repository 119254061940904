import React from "react";

import { 
  BottomInfoWrapper, 
  DisplayWrapper,
  DisplayContainer,
  SocialLogo,
  DisplayHeader,
  SpanWrapper,
} from "./sold-out-display.styles";

const SoldOutDisplay = () => {
  return (
    <DisplayContainer>
      <DisplayWrapper>
        <DisplayHeader>ALL THE GRABBS HAVE BEEN GRABBED FOR NOW.</DisplayHeader>
        <SpanWrapper>
          <div>Don’t miss the next drop.</div>
          <div>Give us a follow.</div>
        </SpanWrapper>
      </DisplayWrapper>
      <BottomInfoWrapper>
        <SocialLogo/>
      </BottomInfoWrapper>
    </DisplayContainer>
  )
};

export default SoldOutDisplay;