import styled from "styled-components";
import { device } from "../../utils/styled/breakpoints";
import { ReactComponent as GrabbitLogo } from '../../assets/grabbit_logo_stacked.svg';
import { grabbitColor } from "../../utils/styled/colors";

export const  PageHeader = styled.h1`
  color: ${grabbitColor.warpWhite};

  @media ${device.inBetweenMax}{
    display: none;
  }
`

export const CartContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;

  @media ${device.tabletMax}{
    flex-wrap: wrap;
    flex-direction: column;
  }
`

export const CheckoutContainer = styled.div`
  width: 100%;

  @media ${device.tabletMax}{
    width: 100%;
  }
`

export const ProductCardContainer = styled.div`
  display: block;
  margin: 0rem auto;
  width: 100%;
  height: 100%;
  max-width: 1256px;
  min-width: 200px;
`

export const ActiveGrabbContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;

  @media ${device.laptop} {
  max-width: 90vw;
  }

`

export const NoActiveGrabbContainer = styled.div`
  text-align: center;
  height: 75vh;
  width: 100%;
  max-width: 1256px;
  background-color: ${grabbitColor.concentration};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 4rem auto;
  padding: 32px;
  border-radius: 20px;
  background-color: ${grabbitColor.ultrapatiencePurple};
`

export const DadsDayContainer = styled.div`
  text-align: center;
  height: 100%;
  width: 100%;
  max-width: 1256px;
  background-color: ${grabbitColor.concentration};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 4rem auto;
  padding: 32px;
  border-radius: 20px;
  background-color: ${grabbitColor.ultrapatiencePurple};
  overflow: hidden;
  z-index: 100;

  h1 {
    font-size: 62px;
    margin: 0;

    @media ${device.tabletMax}{
      font-size: 40px;
  }
  }
  h2 {
    font-size: 24px;
    margin: 10px 0;
  }

  h3 {
    font-size: 32px;
    margin: 20px 0 0 0;
    @media ${device.tabletMax}{
      font-size: 22px;
  }
  }

  h4 {
    font-size: 18px;
    margin: 0;

  }

  p {
    max-width: 900px;
    margin: 0;
  }

  #dad-timer {
    font-size: 48px;

    @media ${device.tabletMax}{
      font-size: 28px;
  }
  }
`

export const Logo = styled(GrabbitLogo)`
  width: 80%;
  max-width: 300px;
  height: auto;
  margin: 20px auto;
  fill: ${({ color }) => color || 'white'};
  z-index: 10;
`

export const BackgroundWrapper = styled.div`
  position: absolute;
  max-width: 1256px;
  max-height: 900px;
  overflow: hidden;
  border-radius: 20px;
  z-index: 0;
`