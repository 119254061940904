export const darkColors = {
  flashYellow: '#E6F341',
  ultrapatiencePurple: '#6C00F3',
  enduranceDarkGray: '#212127',
  enduranceLightGray: '#41414D',
  concentration: '#17171B',
  warpWhite: '#EFFBFF',
  errorRed: '#D00E17',
  darkBackground: '#1E1E1E',
}

export const lightColors = {
  flashYellow: '#FFFF00',
  ultrapatiencePurple: '#800080',
  enduranceDarkGray: '#A9A9A9',
  enduranceLightGray: '#D3D3D3',
  concentration: '#000000',
  warpWhite: '#FFFFFF',
  errorRed: '#FF0000',
  darkBackground: '#F5F5F5',
};

export const grabbitColors = {
  ...darkColors,
  ...lightColors,
}