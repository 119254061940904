import { MenuContainer, MenuLink, LinkContainer, } from "./menu-modal.styles";

const MenuModal = () => {
    return (
        <MenuContainer className="footer-container">
            <LinkContainer>
                <MenuLink to="/homepage">Home</MenuLink>
                <MenuLink to="/help">Get Help</MenuLink>
                <MenuLink to="/how-it-works">How It Works</MenuLink>
                <MenuLink to="/suppliers">Become A Vendor</MenuLink>
                <MenuLink to="/policies/terms">Terms of Use</MenuLink>
                <MenuLink to="/policies/privacy">Privacy Policy</MenuLink>
                <MenuLink to="/policies/warranty-return">Warranty & Return Policy</MenuLink>
                <div><p>Grabbit ©{new Date().getFullYear()}. All rights reserved.</p></div>
            </LinkContainer>

        </MenuContainer>
    );
};

export default MenuModal;