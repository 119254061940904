import styled from "styled-components";
import { grabbitColor } from "../../utils/styled/colors";
import { grabbitFont } from "../../utils/styled/fonts";
import Button from "../../components/button/button.component";

export const PageContainer = styled.div`
  display: flex;
  padding: 36px;
  margin: auto;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`;

export const GetHelpImage = styled.div`
  img {
    width: 100vw;
    max-height: 40rem;
    object-fit: cover;
  }
`;

export const TopInfoContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  color: ${grabbitColor.warpWhite};
  font-family: ${grabbitFont.mainGrabbitFont};

  h1 {
    font-size: 2.25rem;
    margin: .75rem 0 .5rem 0;
  }

  h2 {
    font-size: 1rem;
    margin: 0rem;
    font-weight: 100;
  }
`;

export const HelpContainer = styled.form`
  padding: 0rem 2rem;
  margin: 1.75rem auto;
  width: 100%;
  max-width: 1256px;
  border: 1px solid ${grabbitColor.warpWhite};
  color: ${grabbitColor.warpWhite};

  h3 {
    font-size: 1rem;
  }
`;

export const MainHelpContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 50vw;
`;

export const Question = styled.div`
  color: ${grabbitColor.warpWhite};
  a {
    color: yellow;
  };

  h3 {
    margin: 1rem 0;
    font-weight: 700;
    font-size: 1rem;
  };

  h5 {
    margin-top: 0rem;
  };
`;

export const TextArea = styled.textarea`
  width: 100%;
  height: 6.5rem;
  padding: .5rem .5rem;
  background-color: ${grabbitColor.warpWhite};
  border: none;

  ::-webkit-input-placeholder {
    color: ${grabbitColor.concentration};
    opacity: 1 !important;
  }
`;

export const Input = styled.input`
  height: 2rem;
  width: 100%;
  padding: 0rem .5rem;
  background-color: ${grabbitColor.warpWhite};
  border: none;
  margin-bottom: 1rem;

  ::-webkit-input-placeholder {
    color: ${grabbitColor.concentration};
    opacity: 1 !important;
  }
`;

export const GetHelpButton = styled(Button)`
  margin-top: 2rem;
  margin-bottom: 1.5rem;
`;

export const ModalContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 110vh;
  margin-top: -4rem;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  transform: translateY(${props => (props.show ? "0" : "50px")});
  opacity: ${props => (props.show ? 1 : 1)};
  transition: transform 0.3s ease, opacity 0.3s ease;
`;

export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  padding: 3.5rem 4rem 2.5rem 4rem;
  border-radius: 5px;
  color: ${grabbitColor.warpWhite};
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  background-color: ${grabbitColor.ultrapatiencePurple};
  margin: 2rem;

  img {
    width: 70%;
    margin-bottom: 1rem;
  }

  h2 {
    text-align: center;
  }
`;

export const CloseButtonWrapper = styled.div`
  position: relative;
  bottom: 2rem;
  left: 10rem;
  font-size: 1.5rem;
  cursor: pointer;
  background-color: transparent;
  border: none;
`;