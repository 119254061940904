import { StyledCloseButton, CloseWrapper } from "./close-button.styles";

import React from 'react'

const CloseButton = ({callbackMethod = null, color = ""}) => {
  return (
    <CloseWrapper>
    <StyledCloseButton onClick={callbackMethod} />
    </CloseWrapper>
  )
}

export default CloseButton