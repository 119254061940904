import { createSelector } from 'reselect';

const selectQueueReducer = (state) => state.queue;

export const selectQueueIsLoading = createSelector(
    [selectQueueReducer],
    (queueSlice) => queueSlice.queueIsLoading
  );

export const selectQueueProducts = createSelector(
  [selectQueueReducer],
  (queueSlice) => queueSlice.queue
);