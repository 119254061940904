import SignInForm from '../../components/authentication/sign-in-form/sign-in-form.component';
import { AuthenticationContainer } from './authentication.styles';
import { useLocation } from 'react-router';
import EmailLinkConfirmation from '../../components/authentication/email-link-confirmation/email-link-confirmation.component'
import { selectUserError } from '../../store/user/user.selector';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { selectCurrentUser } from '../../store/user/user.selector';
import { useNavigate } from 'react-router';

const SignIn = () => {
  const location = useLocation().search;
  const error = useSelector(selectUserError);
  const user = useSelector(selectCurrentUser);
  const navigate = useNavigate();

  useEffect(() => {
    if (user) {
      navigate('/');
    }

  }, [user, navigate])
  return (
    <AuthenticationContainer>
      {location !== '?email-sent' ? <SignInForm /> : ''}
      {location === '?email-sent' && !error && window.localStorage.getItem('emailForSignIn') ? <EmailLinkConfirmation /> : ''}
      {(error || !window.localStorage.getItem('emailForSignIn')) && location === '?email-sent' ? <p>Uh oh… there was an error. Please try again.</p> : ''}
    </AuthenticationContainer>
  );
};

export default SignIn;