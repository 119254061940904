import styled from "styled-components";
import { grabbitColor } from "../../../../utils/styled/colors";
import { device } from "../../../../utils/styled/breakpoints";

export const QuantityWarningContainer = styled.div`
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  margin-top: -60px;
  min-height: 15%;
  top: 60px;
  z-index: 100;
  min-width: 100%;
  border: 1px solid ${grabbitColor.warpWhite};
  border-radius: 25px 25px 0px 0px;
  border-bottom: none;
  padding: 30px;
  text-align: center;
  color: ${grabbitColor.concentration};
  background-color: ${grabbitColor.flashYellow};

  font-size: .75rem;

  @media ${device.tablet} {
    font-size: 1rem;
  }
`;

export const LastWarningContainer = styled.div`
  color: ${grabbitColor.concentration};
  border: 10px ${grabbitColor.flashYellow} solid;
  border-radius: 24px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  
`;


export const LastWarningWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  z-index: 100;
  border: 3px solid ${grabbitColor.flashYellow};
  background-color: ${grabbitColor.flashYellow};
  min-width: 100%;
  min-height: 15%;
`;