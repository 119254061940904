import styled from "styled-components";
import { device } from "../../../utils/styled/breakpoints";
import { grabbitColor } from "../../../utils/styled/colors";

import { grabbitFont } from "../../../utils/styled/fonts";
import { ChevronRight, ChevronLeft } from '@styled-icons/material';
import Scaffold from "../../scaffold/scaffold.component";

const maxWidth = '600px'
const maxHeight = '600px'


export const ImageContainer = styled.div`
  display: block;
  width: 100%;
  height: 100%;
  max-height: ${maxHeight};
  max-width: ${maxWidth};
  aspect-ratio: 1 / 1;
  margin: 0 auto;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  position: relative;

  @media ${device.laptop, device.laptopMax} {
  width: 100%;
  height: 100%;
  }

  @media ${device.inBetweenMax} {
  width: 40%;
  height: 40%;
}

@media ${device.tabletMax} {
  width: 100%;
  height: 100%;
}

`;

export const MainImage = styled.img`
  display: block;
  position: relative;
  width: 100%;
  height: 100%;
  aspect-ratio: 1 / 1; /* This maintains a 1:1 aspect ratio */
  margin: 0 auto;
  border-radius: 24px;
  opacity: ${(props) => (props.active ? 1 : 0.75) };
`;

export const ImageScaffold = styled(Scaffold)`
  display: block;
  position: relative;
  width: 100%;
  height: 100%;
  aspect-ratio: 1 / 1; /* This maintains a 1:1 aspect ratio */
  min-width: 100px;
  min-height: 100px;
  max-height: ${maxHeight};
  max-width: ${maxWidth};
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
`

export const PriceContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  font-family: ${grabbitFont.mainGrabbitNumberFont};
  text-align: right;

  h3 {
    margin: 0 16px 0 0;
    font-size: 16px;
  }

  .title {
    transform: translateY(6px);
    font-weight: 100;
  }

  .price {
    text-align: right;
    font-size: 70px;
    text-shadow: 0px 0px 10px rgba(0,0,0,0.5);
  }

  @media ${device.mobileMax} {
    .price {
      font-size: 55px;
    }
    h3 {
      font-size: 12px;
      margin-top: 0rem;
    }
    .title {
      transform: translateY(7px);
    }
  }
`;

export const ActivePrice = styled.h3`
    text-align: right;
    font-size: 70px;
    text-shadow: 0px 0px 10px rgba(0,0,0,0.5);
`

export const ImageControlsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
`

export const ImageSwitcherButton = styled.div`
  width: 60px;
  height: 60px;
  cursor: pointer;
  padding: 10px;
  margin: 10px;
  transition: opacity ease-in-out .5s, fill ease-in-out .5s;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${grabbitColor.warpWhite};
  opacity: 0.5;
  border-radius: 100%;
  
  &:hover {
    opacity: 1;
  }
`;


export const PreviousIcon = styled(ChevronLeft)`
  width: 60px;
  height: 60px;
  fill:${grabbitColor.concentration};
`

export const NextIcon = styled(ChevronRight)`
  width: 60px;
  height: 60px;
  fill:${grabbitColor.concentration};
`

export const RetailPriceContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: ${grabbitFont.mainGrabbitNumberFont};

  p {
    margin: 0 1% 1% 1%;
    font-weight: bold;
  }
  
  h3 {
    margin: 0rem 1rem;
    font-size: 16px;
    font-weight: 100;
  }
  
  @media ${device.mobileM} {
    justify-content: right;
    text-align: right;
    flex-direction: column;
  }

  @media ${device.tablet} {
    flex-direction: row;
  }
  
  @media ${device.mobileMax} {
    h3 {
      font-size: 12px;
    }
  }
`;

export const Details = styled.div`
  display: flex;
  justify-content: space-around;
  background-color: ${grabbitColor.enduranceDarkGray};
  border-radius: 18px;

  @media ${device.mobileM} {
    justify-content: center;
    text-align: center;
    flex-direction: column;
  }

  @media ${device.tablet} {
    flex-direction: row;
  }
`;

export const DetailWrapper = styled.div`
  background: rgb(34, 33, 39);
  padding: 8px 30px 20px;
  border-radius: 0px 0px 25px 25px;
  border-top: 1px solid ${grabbitColor.warpWhite};

`;