import styled from "styled-components"
import { grabbitColor } from "../../../utils/styled/colors"
import { grabbitFont } from "../../../utils/styled/fonts"
import {device} from "../../../utils/styled/breakpoints"

export const BadgeContainer = styled.div`
  background-color: ${grabbitColor.flashYellow};
  height: 10%;
  width: 100%;
  border-radius: 0 0 24px 24px;
  color: ${grabbitColor.concentration};
  font-size: 24px;
  font-weight: 900;
  font-family: ${grabbitFont.mainGrabbitNumberFont};
  line-height: 1;
  text-transform: uppercase;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 20px;

  span{
    width: 100%;
    text-align: center;
  }
  
  @media ${device.mobileMax} {
    font-size: 16px;
    font-weight: 900;
  }
`