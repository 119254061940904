import React, { useState, useEffect } from 'react'
import { Qicon, TipIcon, TipIconHitbox, TooltipBody, TooltipContainer } from './tooltip.styles'
import { logger } from '../../utils/logger/logger.utils';

const ToolTip = ({ text, showIcon = true, children }) => {
    const [tooltipVisible, setTooltipVisible] = useState(false);

    
    const toggleVisibility = (event) => {
        event.stopPropagation();
        setTooltipVisible(!tooltipVisible);

        if (!tooltipVisible) {
            // Register the event listener with a delay to avoid immediate trigger
            setTimeout(() => {
                document.addEventListener("click", closeTooltip);
            }, 500);
        }
    };

    const closeTooltip = () => {
        setTooltipVisible(false);
        document.removeEventListener('click', closeTooltip);
    };

    // Cleanup event listener when the component is unmounted or tooltip is closed
    useEffect(() => {
        return () => {
            document.removeEventListener('click', closeTooltip);
        };
    }, []);

    
    return (
        <TooltipContainer>
            {showIcon ?
                <TipIconHitbox
                    onClick={toggleVisibility}
                >
                    <TipIcon />
                </TipIconHitbox>
                : ''
            }
            {children}
            <TooltipBody visible={tooltipVisible} className='tooltipBody'>
                <Qicon />
                {text ? text : 'tooltip'}
            </TooltipBody>
        </TooltipContainer>
    )
}

export default ToolTip