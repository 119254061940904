
import { SHA256 } from 'crypto-js';
import axios from "axios";

//Fetch IP from ipify
export const fetchIPAddress = async () => {
    try {
      const response = await axios.get('https://api.ipify.org?format=json');
      return response.data.ip;
    } catch (error) {
      console.error("Error fetching IP address:", error);
      return null;
    }
  }

// Hash IP address
export const hashIP = (ipAddress) => {
    return SHA256(ipAddress).toString();
};

export const fetchHashedIp = async () => {
    const userIP = fetchIPAddress();
    const hashedIP = hashIP(userIP);
    return hashedIP;
}