import React from "react";
import { 
  OnboardPageContainer,
  OnboardButton,
  OnboardImage,
  OnboardText,
  SkipOnboard,
  OnboardButtonContainer,
} from './onboard-pages.styles.jsx';

import onboard1 from "../../assets/onboard1.png";

const PageOne = ({ onButtonClick, onBoardComplete }) => {

  return (
    <OnboardPageContainer>
      {/* <OnboardImage>
        <img src={onboard1} alt="#"/>
      </OnboardImage> */}
      <OnboardText>
        <h1>THE DEALS START BIG AND GET BIGGER AS THE CLOCK TICKS DOWN.</h1>
      </OnboardText>
      <OnboardButtonContainer>
        <OnboardButton onClick={() => onButtonClick("pagetwo")}>NEXT</OnboardButton>
        <SkipOnboard onClick={() => onBoardComplete()}>SKIP</SkipOnboard>
      </OnboardButtonContainer>
    </OnboardPageContainer>
  );
};

export default PageOne;