import { setCartTimeOut, addItemToCart } from "../../../store/cart/cart.reducer";
import { addToDocumentField, getCollectionAndDocuments, getDocByID } from "../../../utils/firebase/firebase.utils";
import { createGuestUserDocument } from "../../../utils/firebase/guest.utils";
import { logger } from "../../../utils/logger/logger.utils";

const validateItemInQ = async (itemId) => {
    const queue = await getCollectionAndDocuments('grabb_q')
    if (queue && queue[0].product.id === itemId) {
        return true;
    }
    else {
        logger.log(`The item did not match.\n ${queue[0].product.id} is first in Queue and item we were looking for was ${itemId}. \n Refreshing page…`)
        window.location.reload();
        return false;
    }
}

export const buyNow = async (
    user,
    dispatch,
    name,
    buy_now_price,
    cartImageUrl,
    taxCode,
    id,
    checkout_fields,
    shippingCost,
) => {
    try {
        // Validate the item in the queue and await the result
        const isValidItem = await validateItemInQ(id);
        if (!isValidItem) {
            logger.error(`The item was not found in sale database.`);
            return; // Exit the function if not valid
        }

        // Stop the current grabb countdown
        const response = await triggerDropperProxy('buynow', id, buy_now_price);

        if (!response.ok) {
            throw new Error(`Failed to complete Buy Now. Status: ${response.status}`);
        }

        // Add the product to the cart
        if (!user) {
            await createGuestUserDocument();
        }
        logger.log('Adding ', { name, price: buy_now_price, imageUrl: cartImageUrl }, ' to the cart via Buy Now.');
        dispatch(addItemToCart({
            name,
            price: (buy_now_price * 100).toFixed(0),
            imageUrl: cartImageUrl,
            taxCode,
            id: id,
            checkoutFields: checkout_fields,
            shippingCost: shippingCost,
        }));
        dispatch(setCartTimeOut(null));

    } catch (error) {
        logger.error('Error in buyNow:', error);
    }
};


export const addProductToCart = async (
    navigate,
    dispatch,
    name,
    currentPrice,
    cartImageUrl,
    taxCode,
    id,
    checkout_fields,
    shippingCost
) => {
    const isValidItem = await validateItemInQ(id); // Await the result
    if (isValidItem) {
        try {
            const price = (currentPrice * 100).toFixed(0);
            const response = await triggerDropperProxy('grabbed', id, price);

            if (!response.ok) {
                throw new Error(`Failed to stop the grabb countdown. Status: ${response.status}`);
            }

            // Create a new product
            const newProduct = {
                name,
                price,
                imageUrl: cartImageUrl,
                taxCode,
                id,
                checkoutFields: checkout_fields,
                shippingCost: shippingCost,
            };

            // Add the updated product to the cart
            dispatch(addItemToCart(newProduct));
            dispatch(setCartTimeOut(Date.now() + 300_000));

        } catch (error) {
            logger.error('Error in addProductToCart():', error);
        }
    } else {
        logger.error(`The item was not found in sale database.`);
    }
};


export const dropGrabb = async (id = null) => {
    logger.log('Dropping grabb with id: ', id, '\n')
    const response = await triggerDropperProxy('dropped', id);

    if (!response.ok) {
        throw new Error(`Failed to drop grabb. Status: ${response.status}`);
    }
}

export const triggerDropperProxy = async (dropperMethod, id = null, price = null) => {
    const data = {
        data: {
            dropperMethod,
            id,
            price,
            time: new Date().toISOString(),
        }
    };

    try {
        const response = await fetch(`${process.env.REACT_APP_PROXY}/dropperProxy`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });

        if (!response.ok) {
            throw new Error(`Network response was not ok. Status: ${response.status}`);
        }

        const responseData = await response.json();
        console.log('Response data:', responseData);
        return response; // Ensure we return the response
    } catch (error) {
        console.error('There was an Error:', error);
        throw error; // Re-throw the error after logging
    }
};
