import { Link } from "react-router-dom";
import Button from "../../components/button/button.component";

import { MainContainer } from "./timeout.styles";

const TimeOut = () => {

  return (
    <MainContainer>
        <h1>AW DAG YO!</h1>
        <Link to="/grabb"><Button>Try Again</Button></Link>   
    </MainContainer>
  );
}

export default TimeOut;