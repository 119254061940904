import { logger } from "../logger/logger.utils";

const color = {
  flashYellow: '#E6F341',
  ultrapatiencePurple_300: '#8336e2',
  ultrapatiencePurple: '#6C00F3',
  ultrapatiencePurple_600: '#2D084E',
  enduranceDarkGray: '#212127',
  enduranceLightGray: '#41414D',
  concentration: '#17171B',
  warpWhite: '#EFFBFF',
  errorRed: '#D00E17',
  darkBackground: '#1E1E1E',
}

export const grabbitColor = {
  flashYellow: `${color.flashYellow}`,
  ultrapatiencePurple_300: `${color.ultrapatiencePurple_300}`,
  ultrapatiencePurple: `${color.ultrapatiencePurple}`,
  ultrapatiencePurple_600: `${color.ultrapatiencePurple_600}`,
  enduranceDarkGray: `${color.enduranceDarkGray}`,
  enduranceLightGray: `${color.enduranceLightGray}`,
  concentration: `${color.concentration}`,
  warpWhite: `${color.warpWhite}`,
  errorRed: `${color.errorRed}`,
  darkBackground: `${color.darkBackground}`,

  alpha: (colorName, alpha = 1) => {
    if (!color[colorName]) {
      logger.warn(`Color ${colorName} not found`);
      return null;
    }
    return hexToRgbA(color[colorName], alpha);
  },
}

export const hexToRgbA = (hex, alpha) => {
  let r, g, b;
  [r, g, b] = hex.match(/\w\w/g).map((x) => parseInt(x, 16));
  return `rgba(${r},${g},${b},${alpha})`;
};
