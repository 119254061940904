import ResetPasswordForm from '../../components/authentication/reset-password-form/reset-password-form.component';
import { AuthenticationContainer } from './authentication.styles';
import { useEffect, useState } from 'react';
import { useDispatch,useSelector } from 'react-redux';
import { signInWithEmailLink } from '../../store/user/user.reducer';
import { useNavigate } from 'react-router';
// import { linkEmailCredential } from '../../utils/firebase/authentication.utils';
import { selectCurrentUser } from '../../store/user/user.selector';

const Authenitication = () => {
  const dispatch = useDispatch();
  const [ authMode, setAuthMode ] = useState('');
  const user = useSelector(selectCurrentUser);
  const navigate = useNavigate();

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    setAuthMode(urlParams.get('mode'));
    
    const signIn = async () => {
      const email = window.localStorage.getItem('emailForSignIn');
      await dispatch(signInWithEmailLink(email));
      
      if (user) {
        navigate('/');
      }
    };
    
    signIn();
  }, [user, dispatch, navigate]);

  useEffect(() => {
    if (user) {
      if (user.onBoarding) {
        navigate('/');
      } else {
        navigate('/onboard');
      }
    }
  }, [user, navigate]);

  return (
    <AuthenticationContainer>
      {authMode === 'resetPassword' ? <ResetPasswordForm /> : ''}
    </AuthenticationContainer>
  );
};

export default Authenitication;