import React, { useState, useEffect } from 'react'
import { DelayedContainer } from './delayed-component-render.styles';

const DelayedRender = ({children, delay, placeholder}) => {
    const [isLoading, setIsLoading] = useState(true);
    
    //Short load timer to prevent layout shift
    useEffect(() => {
        if(delay)
        {
            setIsLoading(true);
            const timer = setTimeout(() => {
              setIsLoading(false);
            }, delay);
            return () => clearTimeout(timer);
        }
        else{
            setIsLoading(true);
            const timer = setTimeout(() => {
              setIsLoading(false);
            }, 1250);
            return () => clearTimeout(timer);
        }

      }, []);
  return (
    <DelayedContainer loading={isLoading}>
    {isLoading ? placeholder : children}
    </DelayedContainer>
  )
}

export default DelayedRender