import React from "react";

import { 
  BottomInfoWrapper, 
  PauseDisplayWrapper,
  DisplayContainer,
  PauseDisplayHeader,
} from  "./pause-display.styles"
import CountdownTimer from "../../../countdown-timer/countdown-timer.component";

const PauseDisplay = () => {
  const timer = Date.now() + 300_000;

  return (

    <DisplayContainer>
      <PauseDisplayWrapper>
        <div>Some Grabbers just Grabbed the last of the Grabbits.</div>
        <PauseDisplayHeader>But wait!</PauseDisplayHeader>
        <span>They could let their grip slip. Hold tight and let's see!</span>
      </PauseDisplayWrapper>
      <BottomInfoWrapper>
      <CountdownTimer endTime={Date.now() + 300000} type={'short'} />
      </BottomInfoWrapper>
    </DisplayContainer>
  )
};

export default PauseDisplay;