import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { PageContainer } from '../../app.styles';
import { getCollectionAndDocuments } from '../../utils/firebase/firebase.utils';
import PressReleaseCard from '../../components/press-release-card/press-release-card.component';
import { PressReleasesContainer } from './press.styles';
import PressRelease from '../../components/press-release/press-release.component';
import { logger } from '../../utils/logger/logger.utils';

const PressRoom = () => {
    const [pressReleases, setPressReleases] = useState([]);
    const [selectedRelease, setSelectedRelease] = useState(null);

    const navigate = useNavigate();
    const location = useLocation();
    const slug = location.pathname.split('/')[2] //Select the slug from the pathname ex: /(0)press/(1)slug-name(2)
    logger.log("SLUG: ", slug)

    useEffect(() => {
        const fetchPressReleases = async () => {
            const releases = await getCollectionAndDocuments('press');
            logger.log("Press releases: ", releases)

            setPressReleases(releases);

            //If a specific release was loaded set that as the selected release for viewing
            if (slug) {
                const release = releases.find(r => r.page_slug === slug);
                setSelectedRelease(release);
            }
        };
        fetchPressReleases();
    }, [slug]);

    // const handleReadMore = (slug) => {
    //     navigate(slug);
    // };

    const handleBack = () => {
        navigate('/press')
        setSelectedRelease(null);
    }

    if (selectedRelease) {
        return (
            <PageContainer>
                <PressRelease release={selectedRelease} onClick={handleBack} />
            </PageContainer>
        );
    } else {
        return (
            <PageContainer>
                <h1>Grabbit Press Center</h1>
                <PressReleasesContainer>
                    {pressReleases.map((release) => (
                        <div key={release.page_slug}>
                            <PressReleaseCard release={release} />
                        </div>
                    ))}
                </PressReleasesContainer>
            </PageContainer>
        );
    }
};

export default PressRoom;
