import styled from "styled-components";
import { grabbitColor } from "../../../../utils/styled/colors";
import Scaffold from "../../../scaffold/scaffold.component";
import { grabbitFont } from "../../../../utils/styled/fonts";

export const PriceDisplayContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    font-family: ${grabbitFont.mainGrabbitNumberFont};

    p{
        font-size: large;
    }

    h2{
        font-size: 40px;
    }
`

export const PriceDataWrapper = styled.div`
    width: 100%;
    min-height: 10%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid ${grabbitColor.warpWhite};
    margin: 1rem auto;
    padding-bottom: 1rem;
    text-decoration: ${(props) => props.textDecoration ? props.textDecoration : ''};

    & * {
        margin: 0;
    }

`

export const PriceScaffold = styled(Scaffold)`
    
`