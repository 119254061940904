import styled from "styled-components";
import Scaffold from "../../scaffold/scaffold.component";
import { ScrollPanel } from "primereact/scrollpanel";

export const DescriptionContainer = styled.div`
  text-transform: none;
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  text-align: left;
`;

export const Heading = styled.h3`
    font-size: 24px;
    margin-bottom: 0;

`

export const DescriptionScrollPanel = styled(ScrollPanel)`
  width: 100%;
`

export const DescriptionScaffold = styled(Scaffold)`
  position: relative;
  width: 100%;
  height: 350px;

`
