import styled from "styled-components";
import { grabbitColor } from "../../utils/styled/colors";
import { Close } from "@styled-icons/material";

export const StyledCloseButton = styled(Close)`
  color: ${grabbitColor.concentration};
  width: 32px;
  height: 32px;
  transform-origin: center;


`;

export const CloseWrapper = styled.div`
  width: 50px;
  height: 50px;
  cursor: pointer;

  & :hover{
    color: ${grabbitColor.flashYellow};
  }
`