import React from 'react'
import { CartCloser, CartItemContainer, CartItemsContainer, CartMessage, CloseCartButton, CloseCursor, DecreaseQuantity, IncreaseQuantity, OffCanvasCartContainer, OffCanvasCartWrapper, QuantityWrapper, RemoveProductIcon, SubtotalContainer } from './off-canvas-cart.styles'
import { useDispatch, useSelector } from 'react-redux'
import { selectCartCount, selectCartItems, selectCartTotal, selectIsCartOpen } from '../../store/cart/cart.selector'
import { clearAllCartItems, setIsCartOpen } from '../../store/cart/cart.reducer'
import Button from '../button/button.component'
import { grabbitColor } from '../../utils/styled/colors'

const OffCanvasCart = () => {

    const cartItems = useSelector(selectCartItems);
    const cartCount = useSelector(selectCartCount);
    const cartTotal = useSelector(selectCartTotal);
    const showCart = useSelector(selectIsCartOpen);

    const dispatch = useDispatch();

    const clearCartHandler = () => {
        dispatch(clearAllCartItems())
    }

    const closeCartHandler = () => {
        dispatch(setIsCartOpen(false))
        const cursor = document.querySelector(".cursor")
        cursor.style.visibility = 'hidden';
    }

    const showCursor = () => {
        const cursor = document.querySelector(".cursor")
        cursor.style.visibility = 'visible';
    }

    const hideCursor = () => {
        const cursor = document.querySelector(".cursor")
        cursor.style.visibility = 'hidden';
    }

    const mouseMoveEvent = (e) => {
        const cursor = document.querySelector(".cursor")
        cursor.style.left = `${e.pageX}px`
        cursor.style.top = `${e.pageY}px`
    }


    const CartProduct = ({ product }) => {
        return (
            <CartItemContainer>
                <p>{product.name}</p>
                <p>{product.quantity}</p>
            </CartItemContainer>
        )

    }

    return (
        <OffCanvasCartWrapper active={showCart}>
            <CloseCursor className='cursor' onClick={closeCartHandler} />
            <OffCanvasCartContainer active={showCart} onMouseEnter={hideCursor}>
                <h3>Cart</h3>
                <CloseCartButton onClick={closeCartHandler} />
                {
                    cartItems ? (
                        <>
                            <CartItemsContainer>
                            {cartItems?.map((product, index) => (
                                product ?
                                    <CartProduct key={`${product.id}${index}`} product={product} />
                                    : ''
                            ))}
                            </CartItemsContainer>
                            <SubtotalContainer>
                            <h3>{`Subtotal (${cartCount} items)`}</h3>
                            <h2>${(cartTotal / 100).toFixed(2)}</h2>
                            </SubtotalContainer>
                            <Button style={{maxWidth:'600px'}}buttonType={'text'} textColor={grabbitColor.ultrapatiencePurple} onClick={clearCartHandler} width={'100%'}>Drop This Grabb And Try Again</Button>
                        </>
                    )
                        :
                        <CartMessage>Your cart is empty.</CartMessage>
                }
            </OffCanvasCartContainer>
            <CartCloser onMouseMove={mouseMoveEvent} onMouseEnter={showCursor} onClick={closeCartHandler} onTouchEnd={closeCartHandler} />
        </OffCanvasCartWrapper>
    )
}

export default OffCanvasCart