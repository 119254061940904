import styled from "styled-components";
import { grabbitColor } from "../../utils/styled/colors";

export const ViewCounterContainer = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 24px;
    background-color: ${grabbitColor.enduranceDarkGray};
    width: 100%;

    font-size: 20px;
    padding: 0 24px 24px 24px;
    margin: 2rem;

    h3{
        font-size: 20px;
    }
`