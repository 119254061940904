import SignUpForm from '../../components/authentication/sign-up-form/sign-up-form.component';
import { AuthenticationContainer } from './authentication.styles';

const CreateAccount = () => {
  return (
    <AuthenticationContainer>
      <SignUpForm />
    </AuthenticationContainer>
  );
};

export default CreateAccount;