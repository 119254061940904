import styled from "styled-components";
import { device } from "../../utils/styled/breakpoints";
import { grabbitColor } from "../../utils/styled/colors";

import Button from "../button/button.component";

export const RenderDisplayWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const CardContainer = styled.div`
  display: flex;
  max-width: 1256px;
  width: 100%;
  height: 100%;
  min-width: 100%;
  min-height: 100%;
  margin: 0 auto;
  border-radius: 24px;
  color: ${grabbitColor.warpWhite};
  background-color: ${grabbitColor.concentration};
  justify-content: center;


  @media ${device.inBetweenMax}, ${device.inBetween}, ${device.tablet} {
    padding: 16px;
    flex-wrap: wrap;
  }

  @media ${device.laptop} {
    padding: 24px 16px;
  }
`;

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  text-transform: uppercase;
  margin: 0;
  border-radius: 20px;
  border-width: 1px;
  align-items: center;

  h2 {
      text-align: center;

    @media ${device.mobileLMax} {
      font-size: 20px;
      
    }

    @media ${device.inBetweenMax} {
      font-size: 15px;
    }

  }

  .details {
    display: flex;
    flex-direction: row;
    margin: 0 auto;

    div {
      display: flex;
      width: 50%;
      flex-direction: row;
      align-items: center;
      margin: 0 auto;
      padding: 8px;
    }


    p {
      font-weight: bold;
      font-size: 20px;
      margin-left: 8px;
    }
  }

  @media ${device.mobileS}, ${device.inBetween}, ${device.tablet}, ${device.laptop} {
    width: 100%;
  }

  @media ${device.laptop} {
    max-width: 500px;
  }

  @media ${device.mobileLMax} {
    padding: 0rem 0rem;
  }
`;

export const TopInfoContainer = styled.div`
  flex: 1;
`;

export const Title = styled.h2`
  font-size: 2rem;
  font-weight: 900;
  height: 8rem;
  color: ${grabbitColor.concentration};
  width: 80%;
  margin: 27px auto 0px auto;

  hr {
    border: none;
    background: ${grabbitColor.ultrapatiencePurple};
    height: 0.5rem;
  }
`;

export const ModalBody = styled.div`
  position: relative;
  margin-top: -155px;
  font-size: 24px;
  color: ${grabbitColor.concentration};
  bottom: 100px;
  z-index: 100000000;

  h2 {
    color: ${grabbitColor.concentration};
  }
`;

export const ModalContainer = styled.div`
  background-color: ${grabbitColor.flashYellow};
  border-radius: 24px;
  text-align: center;
`;

export const ActiveButton = styled.button`
  padding: 8px 10px;
  background: ${(props) => (props.active ? grabbitColor.concentration : grabbitColor.concentration)};
  border: ${(props) => (props.active ? "1px solid #EFFBFF" : "none")};
  color: ${(props) => (props.active ? grabbitColor.warpWhite : grabbitColor.warpWhite)};
  border-bottom: 1px solid ${grabbitColor.warpWhite};
`;

export const GrabbButton = styled(Button)`
  position: relative;
  width: 100%;
  max-width: 1000px;
  overflow: hidden;
  text-align: center;
  font-size: 16px;

  @keyframes shine {
    0% {transform:translateX(-100%)}
    1% {transform:translateX(1000%)}
    100% {transform:translateX(1000%)}
  }
  @-webkit-keyframes shine {
    0% {transform:translateX(-100%)}
    1% {transform:translateX(1000%)}
    100% {transform:translateX(1000%)}
  }	 

  /* Shine */
  ::after {
    content: '';
    top: 0;
    left: 0;
    transform: translateX(100%);
    width: 100%;
    height: 220px;
    position: absolute;
    z-index: 1;

    animation: ${(props) => (props.buttonType !== 'disabled' ? 'shine 8s cubic-bezier(.14, .75, .2, 1.01) infinite' : '')};
    -webkit-animation: ${(props) => (props.buttonType !== 'disabled' ? 'shine 8s cubic-bezier(.14, .75, .2, 1.01) infinite' : '')};
  
  /* CSS Gradient - complete browser support from http://www.colorzilla.com/gradient-editor/ */
  background: -moz-linear-gradient(left, rgba(135, 109, 222,0) 0%, rgba(135, 109, 222,0.8) 50%, rgba(128,186,232,0) 99%, rgba(125,185,232,0) 100%); /* FF3.6+ */
	background: -webkit-gradient(linear, left top, right top, color-stop(0%,rgba(135, 109, 222,0)), color-stop(50%,rgba(135, 109, 222,0.8)), color-stop(99%,rgba(128,186,232,0)), color-stop(100%,rgba(125,185,232,0))); /* Chrome,Safari4+ */
	background: -webkit-linear-gradient(left, rgba(135, 109, 222,0) 0%,rgba(135, 109, 222,0.8) 50%,rgba(128,186,232,0) 99%,rgba(125,185,232,0) 100%); /* Chrome10+,Safari5.1+ */
	background: -o-linear-gradient(left, rgba(135, 109, 222,0) 0%,rgba(135, 109, 222,0.8) 50%,rgba(128,186,232,0) 99%,rgba(125,185,232,0) 100%); /* Opera 11.10+ */
	background: -ms-linear-gradient(left, rgba(135, 109, 222,0) 0%,rgba(135, 109, 222,0.8) 50%,rgba(128,186,232,0) 99%,rgba(125,185,232,0) 100%); /* IE10+ */
	background: linear-gradient(to right, rgba(135, 109, 222,0) 0%,rgba(135, 109, 222,0.8) 50%,rgba(128,186,232,0) 99%,rgba(125,185,232,0) 100%); /* W3C */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#007db9e8',GradientType=1 ); /* IE6-9 */
}
`;


export const BuyNowButton = styled(Button)`
  position: relative;
  width: 100%;
  max-width: 1000px;
  overflow: hidden;
  text-align: center;
  font-size: 16px;

  &:hover{
    color: ${grabbitColor.concentration};
    background-color: ${grabbitColor.warpWhite};
  }

  @keyframes shine {
    0% {transform:translateX(-100%);}
    1% {transform:translateX{1000%};}
    100% {transform:translateX(1000%);}
  }
  @-webkit-keyframes shine {
    0% {transform:translateX(-100%);}
    1% {transform:translateX{1000%};}
    100% {transform:translateX(1000%);}
  }	 

  /* Shine */
  ::after {
    content: '';
    top: 0;
    left: 0;
    transform: translateX(100%);
    width: 100%;
    height: 220px;
    position: absolute;
    z-index: 1;

    animation: ${(props) => (props.buttonType !== 'disabled' ? 'shine 8s cubic-bezier(.14, .75, .2, 1.01) infinite' : '')};
    -webkit-animation: ${(props) => (props.buttonType !== 'disabled' ? 'shine 8s cubic-bezier(.14, .75, .2, 1.01) infinite' : '')};
  


  /* CSS Gradient - complete browser support from http://www.colorzilla.com/gradient-editor/ */
  
  /* FF3.6+ */
  background: -moz-linear-gradient(
    left,
    rgba(135, 109, 222,0) 0%,
    rgba(135, 109, 222,0.8) 50%,
    rgba(128,186,232,0) 99%,
    rgba(125,185,232,0) 100%); 
  /* Chrome,Safari4+ */
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    color-stop(0%,rgba(135, 109, 222,0)),
    color-stop(50%,rgba(135, 109, 222,0.8)),
    color-stop(99%,rgba(128,186,232,0)),
    color-stop(100%,rgba(125,185,232,0))); 
	
  /* Chrome10+,Safari5.1+ */
  background: -webkit-linear-gradient(
    left, 
      ${grabbitColor.alpha('flashYellow', 0)} 0%,
      ${grabbitColor.alpha('flashYellow', 0.8)} 50%, 
      ${grabbitColor.alpha('flashYellow', 0)} 99%, 
      ${grabbitColor.alpha('flashYellow', 0)} 100%);
	
  /* Opera 11.10+ */
  background: -o-linear-gradient(
    left, 
    ${grabbitColor.alpha('flashYellow', 0)} 0%,
      ${grabbitColor.alpha('flashYellow', 0.8)} 50%, 
      ${grabbitColor.alpha('flashYellow', 0)} 99%, 
      ${grabbitColor.alpha('flashYellow', 0)} 100%);
	
    /* IE10+ */
  background: -ms-linear-gradient(
    left, 
    ${grabbitColor.alpha('flashYellow', 0)} 0%,
      ${grabbitColor.alpha('flashYellow', 0.8)} 50%, 
      ${grabbitColor.alpha('flashYellow', 0)} 99%, 
      ${grabbitColor.alpha('flashYellow', 0)} 100%);
  background: linear-gradient(
      to right, 
      ${grabbitColor.alpha('flashYellow', 0)} 0%,
      ${grabbitColor.alpha('flashYellow', 0.8)} 50%, 
      ${grabbitColor.alpha('flashYellow', 0)} 99%, 
      ${grabbitColor.alpha('flashYellow', 0)} 100%
    );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#007db9e8',GradientType=1 ); /* IE6-9 */
}
`;