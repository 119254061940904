import styled from "styled-components";
import { grabbitColor } from "../../utils/styled/colors";
import Button from "../../components/button/button.component";

export const ThemeSwitcherButtonContainer = styled.div`
  display: none;
`

export const DarkThemeSwitcherButton = styled(Button)`

`

export const LightThemeSwitcherButton = styled(Button)`
  background-color: ${grabbitColor.warpWhite};
  color: ${grabbitColor.concentration};
  &:hover {
    background-color: ${grabbitColor.ultrapatiencePurple};
    color: ${grabbitColor.warpWhite};
    border: 1px solid ${grabbitColor.concentration};
  }

`