import { 
  PreferenceContainer, 
 } from "./account.styles";

import ThemeSwitcher from "../../components/theme-switcher/theme-switcher.component";
import UserPreferences from "../../components/user-preference/user-preference.component";

const Account = () => {

  return (
      <PreferenceContainer>
        <UserPreferences/>
        
        <ThemeSwitcher/>
      </PreferenceContainer>
  )
};

export default Account;