import styled from "styled-components";
import { grabbitColor } from "../../utils/styled/colors";
import Button from "../../components/button/button.component";
import { device } from "../../utils/styled/breakpoints";

export const BottomBlockContainer = styled.div`
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100vw;
  max-width: 430px;
  margin: 3rem 2rem 3rem;
  padding: 2rem 0;
  text-align: center;
`;

export const ScrollableContainer = styled.div`
  display: flex;
  flex-direction: row;
  overflow-x: hidden;
  padding: 20% 0;
`

export const BottomBlockInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  flex: 0 0 100%; 
  margin: 0rem; 

  h1 {
    font-size: 1.1rem;
    margin: 0rem 0rem 3rem;
  }
  
  span {
    font-size: 1.5rem;
    color: ${grabbitColor.flashYellow};
    font-weight: 700;
  }

  @media ${device.mobileMax} {
    h1 {
      font-size: .8rem;
    }
    span {
      font-size: 1.3rem;
    }
  }
`;

export const CatDiv = styled.div`
  height: 5rem;
  margin-top: 1rem;
  font-size: xxx-large;
`;

export const StarContainer = styled.div`
  position: relative;
  gap: 1rem;
  z-index: 100;
`;

export const Star1 = styled.img`
  position: absolute;
  height: 1.75rem;
  width: 1.5rem;
  top: 4rem;
  left: 25rem;

  @media ${device.mobileMax} {
    top: 4rem;
    left: 20rem;
  }
`;

export const Star2 = styled.img`
  position: absolute;
  height: 1.75rem;
  width: 1.5rem;
  top: 13rem;
  left: 6rem;
  z-index: 1000;

  @media ${device.mobileMax} {
    left: 6rem;
  }
`;

export const Star3 = styled.img`
  position: absolute;
  height: 1.75rem;
  width: 1.5rem;
  top: 14rem;
  left: 23rem;
  z-index: 1000;

  @media ${device.mobileMax} {
    left: 19rem;
  }
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 50vw;
  margin: auto;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

export const BottomJoinButton = styled(Button)`
  z-index: 10;
  position: relative;
  bottom: -2rem;
  width: 70%;
  font-size: .9rem;
  margin: 0rem 0rem 2rem;

`;
