import { Outlet } from "react-router-dom";
import { Fragment, useState } from "react";
import { useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";

import {
  NavigationContainer,
  NavLink,
  NavLinkButton,
  LogoContainer,
  NavLinksContainer,
  MenuContainer,
  HamburgerMenu,
  BigMenuLinks,
  OffCanvasLink,
  StyledHamburgerIcon,
  WordMark,
  Logo
} from './navigation.styles';

import { selectCurrentUser } from '../../store/user/user.selector';
import { clearAllCartItems } from "../../store/cart/cart.reducer";
import NavCartDisplay from "../../components/nav-cart-display/nav-cart-display.component";
import { useDispatch } from "react-redux";
import { startSignOutUser } from '../../store/user/user.reducer';
import MenuModal from "../../components/menu-modal/menu-modal.component";
import OffCanvasCart from "../../components/off-canvas-cart/off-canvas-cart.component";

const Navigation = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const currentUserAuth = useSelector(selectCurrentUser);
  // const waitListActive = useSelector(selectWaitlistActive);

  const signOutClickHandler = () => {
    dispatch(startSignOutUser());
    dispatch(clearAllCartItems());
    navigate("/");
  }

  const signInClickHandler = () => {
    setIsMenuOpen(false);
    setTimeout(() => {
      navigate("/sign-in");
    }, 100); // Wait 100ms before navigating
  }

  const handleCloseModal = () => {
    setIsMenuOpen(false);
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <Fragment>
      <NavigationContainer>
        <LogoContainer className="logo-container" onClick={handleCloseModal} to="/">
          <Logo />
          <WordMark>GRABBIT</WordMark>
        </LogoContainer>
        <NavLinksContainer>
          <NavLink to="/grabb">SHOP</NavLink>
          <NavLink onClick={handleCloseModal} to="/how-it-works">HOW IT WORKS</NavLink>
          <HamburgerMenu onClick={toggleMenu} ><StyledHamburgerIcon className={isMenuOpen ? 'open' : ''} /></HamburgerMenu>
          <MenuContainer className={isMenuOpen ? 'opened' : 'closed'}>
            <BigMenuLinks>
              <OffCanvasLink onClick={handleCloseModal} to='/grabb'>SHOP</OffCanvasLink>
              <OffCanvasLink onClick={handleCloseModal} to="/how-it-works" >HOW IT WORKS</OffCanvasLink>
              {currentUserAuth ? (<OffCanvasLink onClick={signOutClickHandler}>SIGN OUT</OffCanvasLink>) : (<OffCanvasLink onClick={signInClickHandler}>SIGN IN</OffCanvasLink>)}
              {currentUserAuth ? '' : <OffCanvasLink onClick={signInClickHandler}>SIGN UP</OffCanvasLink>}
            </BigMenuLinks>
            <MenuModal />
          </MenuContainer>
          <NavCartDisplay />
          <OffCanvasCart />
          {currentUserAuth ? <NavLink as="span" onClick={signOutClickHandler}>SIGN OUT</NavLink> : <NavLink to="/sign-in">SIGN IN</NavLink>}
          {currentUserAuth ? '' : <NavLinkButton to="/sign-up">SIGN UP</NavLinkButton>}
        </NavLinksContainer>
      </NavigationContainer>
      <Outlet />
    </Fragment>

  );
}

export default Navigation;
