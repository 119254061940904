import styled from "styled-components";
import { grabbitColor } from "../../utils/styled/colors";
import { grabbitFont } from "../../utils/styled/fonts";
import {device} from "../../utils/styled/breakpoints";
export const PageContainer = styled.div`
  width: 100vw;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: ${grabbitColor.ultrapatiencePurple_600};
  padding: 5rem 0 0 0;
`

export const PageTitle = styled.h1`
    font-size: 2.5rem;
    margin: 5rem 0 0 0;
    text-align: center;
    text-transform: uppercase;
`

export const InfoContainer = styled.div`
  width: 100%;
  max-width: 680px;
  line-height: 1.75;
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  width: 100%;
  color: ${grabbitColor.warpWhite};
  font-family: ${grabbitFont.mainGrabbitFont};  
`

export const VideoContainer = styled.div`
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  height: 100%;
  width: 100vw;
  padding: 5rem 0;
  background-color: ${grabbitColor.enduranceDarkGray};
`

export const VideoWrapper = styled.div`
  display: block;
  border: 1.5px solid ${grabbitColor.flashYellow};
  border-radius: 1rem;
  z-index: 100;
  margin: 5rem;
  position: relative;
  width: 40%;
  padding-bottom: 40%;
  overflow: hidden;


  @media ${device.inBetweenMax}{
    width: 100%;
    padding-bottom: 75%;
    padding-right: 75%;
  }

`

export const VideoiFrame = styled.iframe`
  position: absolute;  
  width: 100%;
  height: 100%;
  border: none;
`;
