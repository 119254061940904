import React from 'react'
import Button from '../button/button.component'
import { PressReleaseContainer } from './press-release.styles'
import ReactMarkdown from 'react-markdown';
import { logger } from '../../utils/logger/logger.utils';

const MarkdownRenderer = ({ content }) => {
    return <ReactMarkdown>{content}</ReactMarkdown>;
  };

const PressRelease = ({ release, onClick }) => {
    return (
        <PressReleaseContainer>
            <h6>{release.publication_date?.seconds}</h6>
            <h1>{release.page_title}</h1>
            <h2>{release.headline}</h2>
            <h3>{release.subhead}</h3>
            <MarkdownRenderer content={release.body_copy}/>
            <Button onClick={onClick}>Back to Press Releases</Button>
        </PressReleaseContainer>
    )
}

export default PressRelease