import styled from "styled-components";
import { device } from "../../../../utils/styled/breakpoints";

export const NameDisplayContainer = styled.div`
    width: 100%;
    min-height: 5%;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 2rem auto 1rem auto;
    font-size: x-large;
    
    *{
        margin: 0;
    }

    @media ${device.laptopL}{
        font-size: x-large;
    }

    @media ${device.laptop}{
        font-size: large;
    }

    @media ${device.tabletMax}{
        font-size: x-large;
    }
    @media ${device.inBetweenMax}{
        font-size: medium;
    }
    @media ${device.mobileLMax}{
        font-size: medium;
    }
    @media ${device.mobileMax}{
        font-size: medium;
    }
    @media ${device.mobileS}{
        font-size: small;
    }
`