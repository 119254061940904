import styled from "styled-components";
import { grabbitColor } from "../../utils/styled/colors";

import { 
  Facebook,
  Instagram,
} from '@styled-icons/entypo-social';

export const IconContainer = styled.div`
  display: flex;
  flex-direction: row;
  z-index: 100;
`;

export const FBicon = styled(Facebook)`
  color: ${(props) => (props.color ? props.color : grabbitColor.ultrapatiencePurple)};
  font-weight: ${(props) => (props.important ? 'bold' : 'normal')};
  width:42px;
  margin: 10px 10px;
  transition: 0.5s;

  :hover { 
    width:38px;
    color: ${grabbitColor.ultrapatiencePurple};
    filter: drop-shadow(0 1mm 2px ${grabbitColor.concentration});
    transition: 0.5s;
  }
`;

export const IGicon = styled(Instagram)`
  color: ${(props) => (props.color ? props.color : grabbitColor.ultrapatiencePurple)};
  font-weight: ${(props) => (props.important ? 'bold' : 'normal')};
  width:42px;
  margin: 10px 10px;
  transition: 0.5s;

  :hover { 
    width: 38px;
    color: ${grabbitColor.ultrapatiencePurple};
    filter: drop-shadow(0 1mm 2px ${grabbitColor.concentration});
    transition: 0.5s;
  }
`;