import moment from 'moment-timezone';
import React from 'react'
import { PressCardContainer, PressInfoContainer } from './press-release-card.styles';
import { Link } from 'react-router-dom';
import { logger } from '../../utils/logger/logger.utils';

const truncateLength = 140;

const truncate = ((longString) => {
    return longString.length > truncateLength ? longString.substring(0,truncateLength) + '…' : longString;
})

const PressReleaseCard = ({release, onClick}) => {

    let formattedDate = '';

    if (release.publication_date && release.publication_date.seconds && !release.updated) {
        // Convert the seconds to a Unix timestamp and format it
        formattedDate = moment.unix(release.publication_date.seconds).format("MM/DD/YYYY");
    }
    if (release.updated && release.updated.seconds) {
        // Convert the seconds to a Unix timestamp and format it
        formattedDate = moment.unix(release.updated.seconds).format("MM/DD/YYYY");
    }
    

    return (
        <PressCardContainer>
            <PressInfoContainer>
            <h6>{formattedDate}</h6>
            <h2>{release.page_title}</h2>
            <h3>{release.headline}</h3>
            <h4>{release.subhead}</h4>
            <p>{release.body_copy ? truncate(release.body_copy) : ''}</p>
            <Link to={release.page_slug} >Read More</Link>
            </PressInfoContainer>
        </PressCardContainer>
    )
}

export default PressReleaseCard