import { useSelector } from 'react-redux';
import GrabbCard from '../../components/grabb-card/grabb-card.component';
import CountdownPage from '../countdown/countdown.route';
import { Helmet } from 'react-helmet-async';

import {
  ProductCardContainer,
  ActiveGrabbContainer,
  NoActiveGrabbContainer,
  BackgroundWrapper,
  PageHeader,
  DadsDayContainer,
} from './grabbit.styles';

import SocialIcons from '../../components/social-icons/social-icons.component'
import ScrollingText from '../../components/scrolling-text/scrolling-text.component';
import { selectCartCount } from '../../store/cart/cart.selector';
import { useRealtimeData } from '../../utils/firebase/realtime-database.utils';
import { logger } from '../../utils/logger/logger.utils';
import { useEffect, useState } from 'react';
import FetchTheProductImages from '../../components/grabb-card/utility-functions/image.utils';
import { PageContainer } from '../../app.styles';
import CountdownTimer, { TIMER_FORMATS } from '../../components/countdown-timer/countdown-timer.component';
import { selectWaitlistActive } from '../../store/settings/settings.selector';
import { fetchSettingsAsync } from '../../store/settings/settings.reducer';
import Spinner from '../../components/spinner/spinner.component';
import { selectCurrentUser } from '../../store/user/user.selector';


const Grabbit = () => {
  const [metaImageUrl, setMetaImageUrl] = useState('');
  const currentProduct = useRealtimeData({ collectionName: "currentsale", documentName: "product" })
  const activeGrabb = useRealtimeData({ collectionName: "currentsale", documentName: "active" })
  const cartCount = useSelector(selectCartCount);
  const user = useSelector(selectCurrentUser);


  useEffect(() => {
    fetchSettingsAsync()
  }, [])

  useEffect(() => {
    if (currentProduct && currentProduct.main_image) {
      const fetchImages = async () => {
        try {
          const imageUrl = await FetchTheProductImages(currentProduct.main_image);
          setMetaImageUrl(imageUrl[0]);
        } catch (error) {
          logger.error('Error fetching images', error);
        }
      };
      fetchImages();
      logger.log('This is the meta image\n', metaImageUrl);
    }
  }, [currentProduct]);



  // logger.log('Active Grabb is: ', currentProduct)

  const RenderLoader = (
    <NoActiveGrabbContainer >
      <Spinner />
    </NoActiveGrabbContainer >
  )
  const RenderNotYetLaunched = (
    <NoActiveGrabbContainer >
      <CountdownPage />
    </NoActiveGrabbContainer >
  )

  const RenderActiveGrabb = (
    <>
      {cartCount > 0 ?
        <PageHeader>You Grabbed It!</PageHeader>
        :
        <PageHeader>Up For Grabbs Now</PageHeader>
      }
      <ActiveGrabbContainer>
        <ProductCardContainer>
          <GrabbCard product={currentProduct} />
        </ProductCardContainer>
      </ActiveGrabbContainer>
    </>
  )

  const RenderNoActiveGrabb = (
    <NoActiveGrabbContainer >
      <h1>This is where it’s going down!</h1>
      <h3>But later.</h3>
      <SocialIcons />
      <BackgroundWrapper>
        <ScrollingText />
      </BackgroundWrapper>
    </NoActiveGrabbContainer >
  )

  const RenderSaleOver = (
    <NoActiveGrabbContainer >
      <h1>All The Grabbs Have Been Grabbed For Now.</h1>
      <SocialIcons color="white" />
      <BackgroundWrapper>
        <ScrollingText />
      </BackgroundWrapper>
    </NoActiveGrabbContainer >
  )


  // const RenderDadsDay = (
  //   <DadsDayContainer >
  //     <h1>{dadsDay.toString()}</h1>
  //     <h1>Dad's Day Deal</h1>
  //     <h2>Love your dad so much you’d drop $600 on a gift for him? Yeah, us neither.</h2>
  //     <p>Luckily, now’s your chance to seem like you do. We have one (and only one) Solo Stove Pi Dual Fuel backyard pizza oven (with Essential Bundle accessories included!) coming up for Grabbs. Wait as long as you can and watch the price drop lower and lower. But don’t wait too long. You have to Grabbit before someone else does.</p>
  //     <h4>Remember … there’s only 1.</h4>
  //     <h3>This Grabbit starts in:</h3>
  //     <CountdownTimer id='dad-timer' format={TIMER_FORMATS.DAYS} endTime={new Date(2024, 5, 8, 12)} />
  //     <SocialIcons color="white" />
  //     <BackgroundWrapper>
  //       <ScrollingText />
  //     </BackgroundWrapper>
  //   </DadsDayContainer >
  // )

  // const RenderDadsDayOver = (
  //   <DadsDayContainer >
  //     <h1>The Dad’s Day Deal is over!</h1>
  //     <h2>Congrats to the lucky Grabber’s dad or to the kid who wants to keep it!</h2>
  //     <p>Give us a follow on social so you don’t miss the next Grabbit.</p>
  //     <SocialIcons color="white" />
  //     <BackgroundWrapper>
  //       <ScrollingText />
  //     </BackgroundWrapper>
  //   </DadsDayContainer >
  // )


  const renderDisplay = (user = null) => {

    //If the sale is not active
    if (!currentProduct) return RenderLoader;
    if (user && user.role === 'superadmin') return RenderActiveGrabb;

    if (!activeGrabb) return RenderSaleOver;

    //If there is a product in the Queue and the launch time has passed show the grabb
    else if (currentProduct) return RenderActiveGrabb;

    return RenderNoActiveGrabb;
  };
  return (
    <PageContainer>
      {currentProduct && (
        <Helmet>
          <title>Ready… Set… Grabbit!</title>
          <meta name="description" content={currentProduct?.description} />
          <meta name="keywords" content={currentProduct?.keywords} />
          <meta name="author" content="Grabbit" />
          <meta property="og:title" content={`Grabbit Now: ${currentProduct?.name}`} />
          <meta property="og:description" content={currentProduct?.description} />
          <meta property="og:image" content={metaImageUrl} />
          <meta property="og:url" content="https://gograbb.it/grabb" />
          <meta name="twitter:title" content={`Grabbit Now: ${currentProduct?.name}`} />
          <meta name="twitter:description" content={currentProduct?.description} />
          <meta name="twitter:image" content={metaImageUrl} />
          <meta name="twitter:card" content="summary_large_image" />
        </Helmet>
      )}

      {renderDisplay(user)}
    </PageContainer>
  );
};

export default Grabbit;