import { useState, useEffect, Fragment } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useLocation } from 'react-router';

import { Link } from 'react-router-dom';

import FormInput from '../../inputs/form-input/form-input.component';
import Button, { BUTTON_TYPE_CLASSES } from '../../button/button.component';

import { SignInContainer, ButtonsContainer } from './sign-in-form.styles';

import { sendResetPassword } from '../../../utils/firebase/authentication.utils';
import { selectCurrentUser } from '../../../store/user/user.selector';

import { signInWithGoogle, sendEmailSignInLink } from '../../../store/user/user.reducer';
import { grabbitColor } from '../../../utils/styled/colors';
import { WhiteLogo } from '../authentication.styles';
import { logger } from '../../../utils/logger/logger.utils';

const defaultFormFields = {
  email: '',
  password: '',
};

const SignInForm = () => {
  const [formFields, setFormFields] = useState(defaultFormFields);
  const [error, setError] = useState('');
  const [authMode, setAuthMode] = useState('');
  const [provider, setProvider] = useState('');
  const { email, password } = formFields;
  const navigate = useNavigate();

  const user = useSelector(selectCurrentUser);
  const dispatch = useDispatch();
  const location = useLocation();
  const urlParams = useLocation().search;

  useEffect(() => {
    if (user) {
      if (user.onBoarding) {
        navigate('/');
      } else {
        navigate('/onboard');
      }
    }
  }, [user, navigate]);

  useEffect(() => {
    switch (location.pathname) {
      case '/sign-up':
        setAuthMode('up')
        break;
      case '/sign-in':
        setAuthMode('in')
          break;
    
      default:
        setAuthMode('')
        break;
    }

  }, [location])
  


  const resetFormFields = () => {
    setFormFields(defaultFormFields);
  };

  const handleSignInWithGoogle = async () => {
    dispatch(signInWithGoogle());
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!email) {
      setError('Email field can not be empty.')
      return;
    }
      try {
        await dispatch(sendEmailSignInLink({ email }));

      } catch (error) {
        logger.error("Error sending sing in link: ", error);
      }
      clearFormFields();
      navigate('?email-sent')
  };


  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormFields({ ...formFields, [name]: value });
  };

  const handlePasswordResetClick = (event) => {
    event.preventDefault();
    if (email === '') {
      alert("Please enter your email");
    } else {
      try {
        sendResetPassword(email);
      } catch (error) {
        logger.error("Error sending password reset email: ", error);
        return;
      }
    }
    clearFormFields();
    // window.location.reload(true);
  }

  const clearFormFields = () => {
    setFormFields(defaultFormFields);
  }

  const handleResetPasswordClick = (event) => {
    event.preventDefault();
    navigate("?resetPassword");
  }

  return (
    <SignInContainer>
      <WhiteLogo />
      {urlParams === "?resetPassword" ? (
        <form onSubmit={handlePasswordResetClick}>
          <h2>Need a new password?</h2>
          <span>Enter the email address you used to register, and we'll send you a link to reset your password.<p>For your security, we don't store or send your password via email.</p></span>
          <FormInput
            label="Email"
            type="email"
            required
            onChange={handleChange}
            name="email"
            value={email}
          />
          <Button type="submit">Reset Password</Button>
        </form>
      ) : (
        <Fragment>
          <h2>Ready … Set … Sign {authMode}</h2>
          <p>{urlParams === '?password' ? 'Sign in with your email and password' : 'Choose Your Preferred Method'}</p>
          <form onSubmit={handleSubmit}>
            {provider === 'link' ?
              <FormInput
                label="Email"
                type="email"
                required
                onChange={handleChange}
                name="email"
                value={email}
              />
              :
              ''
            }
            {urlParams === '?password' ?
              <FormInput
                label="Password"
                type="password"
                required
                onChange={handleChange}
                name="password"
                value={password}
              />
              :
              ''
            }
            {error && <div style={{ color: `${grabbitColor.errorRed}` }}>{error}</div>}
            <ButtonsContainer>
              {urlParams === '?password' ? (
                <>
                  <Button type="submit">Sign In</Button>
                  <Link onClick={handleResetPasswordClick}>Forgot your password?</Link>
                </>
              ) : (
                <>
                  {provider === 'link' ? <Button type="submit" onClick={handleSubmit} >Sign {authMode} With Email Link</Button> : <Button type="button" onClick={() => setProvider('link')} >Sign {authMode} With Email Link</Button>}
                  <Button type="button" buttonType={BUTTON_TYPE_CLASSES.google} onClick={handleSignInWithGoogle}>Sign {authMode} With Google</Button>
                  <p className='notice'>Be sure to accept cookies if prompted before proceeding.</p>
                  {/* <PasswordLink onClick={() => navigate('?password')}>Continue with email and password.</PasswordLink> */}
                </>
              )}

            </ButtonsContainer>
          </form>
        </Fragment>
      )}
    </SignInContainer>
  );
};

export default SignInForm;