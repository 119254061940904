import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  signInWithGooglePopup,
  signInAuthUserWithEmailAndPassword,
  createUserDocumentFromAuth,
  getCurrentUser,
  signOutUser,
  updateUserDocument,
  sendSignInLinkByEmail,
  handleSignInWithEmailLink,
} from '../../utils/firebase/authentication.utils';

import { getIdTokenResult } from "firebase/auth";
import { logger } from '../../utils/logger/logger.utils';


const initialState = {
  currentUser: null,
  role: null,
  error: null,
  isLoading: false,
};

const signInWithGoogle = createAsyncThunk(
  'user/signInWithGoogle',
  async () => {
    const { user } = await signInWithGooglePopup();
    const userSnapshot = await createUserDocumentFromAuth(user);
    return { id: userSnapshot.id, ...userSnapshot.data() };
  }
);

const signInWithEmail = createAsyncThunk(
  'user/signInWithEmail',
  async ({ email, password }, thunkAPI) => {
    try {
      const userCredential = await signInAuthUserWithEmailAndPassword(email, password);
      const user = userCredential.user;
      const userSnapshot = await createUserDocumentFromAuth(user);
      return { id: userSnapshot.id, ...userSnapshot.data() };
    } catch (error) {
      logger.log('Incorrect username or password. Please try again!', error);
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

const sendEmailSignInLink = createAsyncThunk(
  'user/sendEmailSingInLink',
  async ({ email }, thunkAPI) => {
    try {
      await sendSignInLinkByEmail(email);
      return true;
    } catch (error) {
      logger.log('Email Link Sign in error:', error);
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

const signInWithEmailLink = createAsyncThunk(
  'user/signInWithEmailLink',
  async (_, thunkAPI) => {
    try {
      const userCredential = await handleSignInWithEmailLink();
      logger.log("User Credential:", userCredential);
      const user = userCredential;
      if (!user) {
        throw new Error("User is undefined");
      }
      const userSnapshot = await createUserDocumentFromAuth(user);
      if (!userSnapshot || !userSnapshot.id) {
        throw new Error("User Snapshot is undefined");
      }
      return { id: userSnapshot.id, ...userSnapshot.data() };
    } catch (error) {
      logger.log('Email Link error:', error);
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);



export const checkUserSession = createAsyncThunk(
  'user/checkUserSession',
  async () => {
    try {
      const userAuth = await getCurrentUser();
      if (!userAuth) return null;

      // Fetch the role from the user's token
      const idTokenResult = await getIdTokenResult(userAuth);
      const role = idTokenResult.claims.role;

      const userSnapshot = await createUserDocumentFromAuth(userAuth);

      return { id: userSnapshot.id, role, ...userSnapshot.data() };
    } catch (error) {
      return error;
    }
  }
);

export const fetchUserRole = createAsyncThunk(
  'user/fetchUserRole',
  async (_, thunkAPI) => {
    try {
      const user = thunkAPI.getState().user.currentUser;
      if (!user) {
        return thunkAPI.rejectWithValue({ error: 'User not authenticated' });
      }
      const idTokenResult = await getIdTokenResult(user);
      return idTokenResult.claims.role;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

const startSignOutUser = createAsyncThunk(
  'user/startSignOutUser',
  async () => {
    try {
      await signOutUser();
      return null;
    } catch (error) {
      return error;
    }
  }
);

const setGrabbsLeft = createAsyncThunk(
  'user/startSetGrabbsLeft',
  async (grabbs) => {
    try {
      const userAuth = await getCurrentUser();
      if (!userAuth) return null;
      await updateUserDocument(userAuth, 'grabbsLeft', grabbs);
      return grabbs;
    } catch (error) {
      logger.log('Error updating grabbsLeft:', error);
      return { error: error.message };
    }
  }
);

const decrementGrabbsLeft = createAsyncThunk(
  'user/decrementGrabbsLeft',
  async (_, thunkAPI) => {
    try {
      const state = thunkAPI.getState(); // Get the current state

      // Check if user is defined and has grabbsLeft property
      if (state.user.currentUser && state.user.currentUser.grabbsLeft !== undefined) {
        const currentGrabbsLeft = state.user.currentUser.grabbsLeft;

        // Proceed with decrementing grabbsLeft
        const userAuth = await getCurrentUser();
        if (!userAuth) return null;
        
        // Perform the decrement operation
        await updateUserDocument(userAuth, 'grabbsLeft', currentGrabbsLeft - 1);
        
        return currentGrabbsLeft - 1;

      } else {
        // Handle the case where currentUser or grabbsLeft is not defined
        logger.log('User or grabbsLeft is not defined in the state.');
        return null;
      }
    } catch (error) {
      logger.log('Error updating grabbsLeft:', error);
      return { error: error.message };
    }
  }
);


const incrementGrabbsLeft = createAsyncThunk(
  'user/incrementGrabbsLeft',
  async (_, thunkAPI) => {
    try {
      const state = thunkAPI.getState(); // Get the current state
      const currentGrabbsLeft = state.user.currentUser.grabbsLeft; // Access the grabbsLeft value from the state
      const userAuth = await getCurrentUser();
      if (!userAuth) return null;
      await updateUserDocument(userAuth, 'grabbsLeft', currentGrabbsLeft + 1);
      return currentGrabbsLeft + 1;
    } catch (error) {
      logger.log('Error updating grabbsLeft:', error);
      return { error: error.message };
    }
  }
);



const setOnBoardingStatus = createAsyncThunk(
  'user/startSetOnBoardingStatus', 
  async (onBoardingStatus) => {
    try {
      const userAuth = await getCurrentUser();
      if (!userAuth) return null;
      await updateUserDocument(userAuth, 'onBoarding', onBoardingStatus);
      return onBoardingStatus;
    } catch (error) {
      logger.log('Error updating onBoarding status:', error);
      return { error: error.message };
    }
  }
);

const updateDisplayName = createAsyncThunk(
  'user/updateDisplayName',
  async (displayName) => {
    try {
      const userAuth = await getCurrentUser();
      if (!userAuth) return null;
      await updateUserDocument(userAuth, 'displayName', displayName);
      return displayName;
    } catch (error) {
      logger.log('Error updating display name:', error);
      return { error: error.message };
    }
  }
);

const updateEmail = createAsyncThunk(
  'user/updateEmail',
  async (email) => {
    try {
      const userAuth = await getCurrentUser();
      if (!userAuth) return null;
      await updateUserDocument(userAuth, 'email', email);
      return email;
    } catch (error) {
      logger.log('Error updating email:', error);
      return { error: error.message };
    }
  }
);

const updateFirstName = createAsyncThunk(
  'user/updateFirstName',
  async (firstName) => {
    try {
      const userAuth = await getCurrentUser();
      if (!userAuth) return null;
      await updateUserDocument(userAuth, 'firstName', firstName);
      return firstName;
    } catch (error) {
      logger.log('Error updating first name:', error);
      return { error: error.message };
    }
  }
);

const updateLastName = createAsyncThunk(
  'user/updateLastName',
  async (lastName) => {
    try {
      const userAuth = await getCurrentUser();
      if (!userAuth) return null;
      await updateUserDocument(userAuth, 'lastName', lastName);
      return lastName;
    } catch (error) {
      logger.log('Error updating last name:', error);
      return { error: error.message };
    }
  }
);


const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(signInWithGoogle.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(signInWithGoogle.fulfilled, (state, action) => {
        state.currentUser = action.payload;
        state.isLoading = false;
        state.error = null;
      })
      .addCase(signInWithGoogle.rejected, (state, action) => {
        state.error = action.error.message;
        state.isLoading = false;
      })
      .addCase(sendEmailSignInLink.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(sendEmailSignInLink.fulfilled, (state) => {
        state.isLoading = false;
        state.error = null;
      })
      .addCase(sendEmailSignInLink.rejected, (state, action) => {
        state.error = action.error.message;
        state.isLoading = false;
      })
      .addCase(signInWithEmailLink.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(signInWithEmailLink.fulfilled, (state, action) => {
        state.currentUser = action.payload;
        state.isLoading = false;
        state.error = null;
        window.localStorage.removeItem('emailForSignIn');
      })
      .addCase(signInWithEmailLink.rejected, (state, action) => {
        state.error = action.error.message;
        state.isLoading = false;
      })
      .addCase(signInWithEmail.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(signInWithEmail.fulfilled, (state, action) => {
        state.currentUser = action.payload;
        state.isLoading = false;
        state.error = null;
      })
      .addCase(signInWithEmail.rejected, (state, action) => {
        state.error = action.error.message;
        state.isLoading = false;
      })
      .addCase(checkUserSession.fulfilled, (state, action) => {
        state.currentUser = action.payload;
        state.error = null;
      })
      .addCase(checkUserSession.rejected, (state, action) => {
        state.error = action.error.message;
      })
      .addCase(startSignOutUser.pending, (state) => {
        state.isLoading = false;
      })
      .addCase(startSignOutUser.fulfilled, (state, action) => {
        state.currentUser = action.payload;
        state.error = null;
      })
      .addCase(startSignOutUser.rejected, (state, action) => {
        state.error = action.error.message;
      }).addCase(decrementGrabbsLeft.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(decrementGrabbsLeft.fulfilled, (state, action) => {
        state.currentUser.grabbsLeft = action.payload;
        state.error = null;
        state.isLoading = false;
      })
      .addCase(decrementGrabbsLeft.rejected, (state, action) => {
        state.error = action.error.message;
        state.isLoading = false;
      })
      .addCase(setGrabbsLeft.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(setGrabbsLeft.fulfilled, (state, action) => {
        state.currentUser.grabbsLeft = action.payload;
        state.error = null;
        state.isLoading = false;
      })
      .addCase(setGrabbsLeft.rejected, (state, action) => {
        state.error = action.error.message;
        state.isLoading = false;
      })
      .addCase(setOnBoardingStatus.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(setOnBoardingStatus.fulfilled, (state, action) => {
        state.currentUser.onBoarding = action.payload;
        state.error = null;
        state.isLoading = false;
      })
      .addCase(setOnBoardingStatus.rejected, (state, action) => {
        state.error = action.error.message;
        state.isLoading = false;
      })
      .addCase(updateDisplayName.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateDisplayName.fulfilled, (state, action) => {
        state.currentUser.displayName = action.payload;
        state.isLoading = false;
        state.error = null;
      })
      .addCase(updateDisplayName.rejected, (state, action) => {
        state.error = action.error.message;
        state.isLoading = false;
      })
      .addCase(updateEmail.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateEmail.fulfilled, (state, action) => {
        state.currentUser.email = action.payload;
        state.isLoading = false;
        state.error = null;
      })
      .addCase(updateEmail.rejected, (state, action) => {
        state.error = action.error.message;
        state.isLoading = false;
      })
      .addCase(updateFirstName.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateFirstName.fulfilled, (state, action) => {
        state.currentUser.firstName = action.payload;
        state.isLoading = false;
        state.error = null;
      })
      .addCase(updateFirstName.rejected, (state, action) => {
        state.error = action.error.message;
        state.isLoading = false;
      })
      .addCase(updateLastName.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateLastName.fulfilled, (state, action) => {
        state.currentUser.lastName = action.payload;
        state.isLoading = false;
        state.error = null;
      })
      .addCase(updateLastName.rejected, (state, action) => {
        state.error = action.error.message;
        state.isLoading = false;
      })
      .addCase(fetchUserRole.fulfilled, (state, action) => {
        state.role = action.payload;
      })
      .addCase(fetchUserRole.rejected, (state, action) => {
        state.error = action.error.message;
      });
      ;
  },
});

// export const { } = userSlice.actions;

export { startSignOutUser, signInWithEmail, signInWithGoogle, sendEmailSignInLink, signInWithEmailLink, setGrabbsLeft, decrementGrabbsLeft, incrementGrabbsLeft, setOnBoardingStatus, updateDisplayName, updateEmail, updateFirstName, updateLastName };

export const userReducer = userSlice.reducer;