import { useEffect, useRef } from "react";
import axios from "axios";
import { addViewer, removeViewer } from '../../utils/firebase/realtime-database.utils';
import { logger } from "../../utils/logger/logger.utils";

const ViewHandler = () => {
  const removeViewerHandlerRef = useRef(null);
  const heartbeatIntervalRef = useRef(null);

  const HEARTBEAT_INTERVAL = 30_000; // 30 seconds

  const startHeartbeat = () => {
    heartbeatIntervalRef.current = setInterval(() => {
      sendHeartbeatSignal();
    }, HEARTBEAT_INTERVAL);
  };

  const stopHeartbeat = () => {
    clearInterval(heartbeatIntervalRef.current);
  };

  const fetchIPAddress = async () => {
    try {
      const response = await axios.get('https://api.ipify.org?format=json');
      return response.data.ip;
    } catch (error) {
      logger.error("Error fetching IP address:", error);
      return null;
    }
  }

  const sendHeartbeatSignal = async () => {
    const ipAddress = await fetchIPAddress();
    if (!ipAddress) return;

    addViewer(ipAddress);
  }

  useEffect(() => {
    if (!removeViewerHandlerRef.current) {
      removeViewerHandlerRef.current = async () => {
        const ipAddress = await fetchIPAddress();
        if (ipAddress) removeViewer(ipAddress);
      };
    }
    window.addEventListener('beforeunload', removeViewerHandlerRef.current);

    startHeartbeat();
    
    return () => {
      stopHeartbeat();
      window.removeEventListener('beforeunload', removeViewerHandlerRef.current);
    };
  }, []);

  return null;
}

export default ViewHandler;
