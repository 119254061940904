import styled from "styled-components";
import Button from "../button/button.component";
import { grabbitColor } from "../../utils/styled/colors";
import { device } from "../../utils/styled/breakpoints";

export const UserPreferenceContainer = styled.div`
  display: flex;
  margin: auto 2rem;
  width: 90vw;
  max-width: 400px;
  -webkit-box-pack: center;
  justify-content: center;
  flex-direction: column;
  -webkit-box-align: center;
  align-items: center;
  background-color: rgb(23, 23, 27);
  border-radius: 1rem;
  height: 50vh;
`;

export const PreferenceBodyContainer = styled.div`
  margin-top: -4rem;
  padding: 2rem;
  width: 100%;
`;

export const EditPreferenceContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: -6rem;
`;

export const FieldContainer = styled.div`
  display: flex;
  flex-direction: column;

  span {
    font-size: .8rem;
    margin-bottom: .25rem;
  }
`;

export const InputContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 1rem;
  font-weight: 700;
  margin-bottom: 1rem;

  @media ${device.mobileMax} {
    font-size: .8rem;
  }

`

export const PasswordChangeContainer = styled.div`
  display: flex;
  flex-direction: column;

  h1 {
    font-size: 1.2rem;
  }
`

export const PasswordResetContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 2rem;
`

export const EditButton = styled(Button)`
  width: 3.5rem;
  align-items: center;
  height: 2rem;
  background-color: ${grabbitColor.enduranceLightGray};
  margin: 0rem;
  margin-left: 3rem;
  font-size: 1rem;

  @media ${device.mobileMax} {
    margin: 0rem;
    width: 2.5rem;
  }
`;

export const ChangePasswordButton = styled(Button)`
  font-size: 1rem;
`
