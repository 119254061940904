import styled from "styled-components";
import { grabbitColor } from "../../../utils/styled/colors";

export const ToggleContainer = styled.div`
    min-width: 100px;
`

export const ToggleLabel = styled.p`
  color: white;
  font-size: 16px;
  font-weight: normal;
  pointer-events: none;
  transition: 300ms ease all;
`;
export const ToggleSwitch = styled.div`
    width: 24px;
    height: 24px;
    border-radius: 100%;
    background-color: ${grabbitColor.warpWhite};
    transition: 0.3s ease all, all 0.3s cubic-bezier(0.18, 0.89, 0.35, 1.15);
    position: relative;
    box-shadow: 0 0 20px ${grabbitColor.concentration} ;


    &.off{
        align-self: flex-start;
        right: auto;
        background-color: ${grabbitColor.warpWhite};
    }

    &.on{
        right: auto;
        align-self: flex-end;
        background-color: ${grabbitColor.warpWhite};
    }
`

export const ToggleWrapper = styled.div`
    display: flex;
    flex-direction: column;
    border: 3px solid ${grabbitColor.warpWhite};
    border-radius: 24px;
    padding: 5px;
    box-shadow: inset 5px 5px 10px rgba(0, 0, 0, 0.25) ;
    width: 100%;
    max-width: 100px;
    max-height: 40px;

    transition: 0.3s ease all, all 0.3s cubic-bezier(0.18, 0.89, 0.35, 1.15);


    &:hover{
        box-shadow: inset 0px 0px 1px 4px ${grabbitColor.alpha('flashYellow', 0.75)} ;
         }

    &:active{
        ${ToggleSwitch}{
            width: 48px;
            border-radius: 24px;

            &.on{
                background-color: ${grabbitColor.enduranceDarkGray};
            }

            &.off{
                background-color: ${grabbitColor.flashYellow};
            }


            &:active{
            width: 48px;
            border-radius: 24px;
            }
        }
    }

    &.on{
        background-color: ${grabbitColor.flashYellow};
    }

`