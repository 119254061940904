import React from "react";
import { 
  OnboardPageContainer,
  OnboardButton,
  OnboardImage,
  OnboardText,
  SkipOnboard,
  OnboardButtonContainer,
} from './onboard-pages.styles.jsx';

import onboard2 from "../../assets/onboard2.png";

const PageTwo = ({ onButtonClick, onBoardComplete }) => {

  return (
    <OnboardPageContainer>
      {/* <OnboardImage>
        <img src={onboard2} alt="#"/>
      </OnboardImage> */}
      <OnboardText>
        <h1>WHEN IT HITS A PRICE YOU LIKE, CLICK GRABBIT.</h1>
      </OnboardText>
      <OnboardButtonContainer>
      <OnboardButton onClick={() => onButtonClick("pagethree")}>NEXT</OnboardButton>
        <SkipOnboard onClick={() => onBoardComplete()}>SKIP</SkipOnboard>
      </OnboardButtonContainer>
    </OnboardPageContainer>
  );
};

export default PageTwo;