import styled from "styled-components";
import { grabbitColor } from "../../utils/styled/colors";
import { Close, Remove, Add} from "@styled-icons/material";
import { device } from "../../utils/styled/breakpoints";

export const OffCanvasCartWrapper = styled.div`
    position: absolute;
    width: 100vw;
    height: 100vh;
    top: 0;
    right: 0;
    visibility: ${(props) => props.active ? 'visible' : 'hidden'};
`

const onCanvas = () => `
    width: 500px;
    height: 75vh;
    top: 100px;
    right: 45px;

    @media ${device.mobileM}{
        width: 90vw;
        height: 80vh;
        top: 2%;
        right: 5%;
        z-index: 90000;
    }
    
`;

export const OffCanvasCartContainer = styled.div`
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: ${grabbitColor.warpWhite};
    width: 500px;
    max-width: 500px;
    height: 75vh;
    top: 100px;
    right: -500px;
    border-radius: 24px;
    padding: 32px;
    box-shadow: 5px 5px 5px black;
    z-index: 1001;
    transition: all 1s;
    opacity: ${(props) => props.active ? '1' : '0'};

    @media ${device.mobileM}{
        width: 90vw;
        height: 80vh;
        top: 2%;
        right: -500px;
    }
    
    ${(props) => props.active ? onCanvas() : ''};

`

export const CartMessage = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    align-self: center;
    justify-self: center;
    width: 100%;
    height: 100%;
`

export const CartItemContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid ${grabbitColor.enduranceLightGray};
`

export const CartItemsContainer = styled.div`
    flex: 2 1;
`

export const SubtotalContainer = styled.div`
    border-top: 1px solid ${grabbitColor.enduranceLightGray};
    border-bottom: 1px solid ${grabbitColor.enduranceLightGray};

`

export const CloseCartButton = styled(Close)`
    position: absolute;
    top: 24px;
    right: 24px;
    width: 20px;
    cursor: pointer;
`

export const CartCloser = styled.div`
    position: absolute;
    width: 100vw;
    height: 100vh;
    top: 0;
    right: 0;
    background-color: ${grabbitColor.alpha('enduranceDarkGray', 0.5)};
    z-index: 1000;
    cursor: none;
`

export const CloseCursor = styled(Close)`
    position: absolute;
    color: ${grabbitColor.warpWhite};
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 50px;
    height: 50px;
    z-index: 5000;
    cursor: none;
`

export const QuantityWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 75px;
`

export const RemoveProductIcon = styled(Close)`
    width: 20px;
    height: 20px;
    transition: all .3s cubic-bezier(0.075, 0.82, 0.165, 1);
    color: ${grabbitColor.enduranceLightGray};

    :hover{
        color: ${grabbitColor.enduranceDarkGray};

        width: 22px;
        height: 22px;  
    }
`
export const IncreaseQuantity = styled(Add)`
    width: 10px;
    height: 10px;
`

export const DecreaseQuantity = styled(Remove)`
    width: 10px;
    height: 10px;

    :hover{

    }
`