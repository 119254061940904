import { createSlice } from '@reduxjs/toolkit';
import { getCollectionAndDocuments } from '../../utils/firebase/firebase.utils';

export const PRODUCTS_INITIAL_STATE = {
    products: [],
    isLoading: false,
};

const fetchProducts = async () => {
    const productsArray = await getCollectionAndDocuments('products');
    return productsArray;
}

export const productsSlice = createSlice({
    name: 'products',
    initialState: PRODUCTS_INITIAL_STATE,
    reducers: {
        setProductsStart(state) {
            state.products = [];
            state.isLoading = true;
        },
        setProductsSuccess(state, action) {
            state.products = action.payload;
            state.isLoading = false;
        },
        setProductsFailure(state, action) {
            state.error = action.payload;
            state.isLoading = false;
        }
    },
});

export const { setProductsStart, setProductsSuccess, setProductsFailure } = productsSlice.actions;

export const fetchProductsAsync = () => async (dispatch) => {
    try {
        dispatch(setProductsStart());
        const products = await fetchProducts();
        dispatch(setProductsSuccess(products));
    } catch (error) {
        dispatch(setProductsFailure(error));
    }
};

export const productsReducer = productsSlice.reducer;
