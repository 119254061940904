import React from "react";

import {
  Title,
  DisplayContainer,
  DisplayWrapper,
  BottomInfoWrapper,
  ModalBackdrop,
  AgainButton
} from './timeout-modal.styles';

const TimeoutModal = ({ onClose }) => {
  

  return (
    <ModalBackdrop>
      <DisplayContainer>
        <DisplayWrapper>
          <Title>TIMES UP.</Title>
            <span>You’ve lost your grip on this Grabb.</span>
        </DisplayWrapper>
        <BottomInfoWrapper>
          <AgainButton onClick={onClose()}>Let Me Grabbit Again</AgainButton>
        </BottomInfoWrapper>
      </DisplayContainer>
    </ModalBackdrop>
  );
};

export default TimeoutModal;
