import styled from "styled-components";
import { grabbitColor } from "./utils/styled/colors";

export const Main = styled.section`
    width: 100vw;
    overflow-x: hidden;
    color: ${grabbitColor.warpWhite};

    h1{
        text-transform: uppercase;
    }

    h2{

    }

    h3{
        
    }

    h4{

    }

    h5{

    }

    h6{

    }

    p{

    }
`

export const InnerContainer = styled.div`
    width: 100%;
    height: 100%;
`

export const SpinnerWrapper = styled.div`
    width: 100vw;
    height: 100vh;
    display: flex;
    align-content: center;
    justify-content: center;
`

export const PageContainer = styled.div`
  margin-top: 70px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 100vh;
  text-align: center;
  padding: 16px;
`