import React from "react";
import { 
  OnboardPageContainer,
  OnboardButton,
  OnboardImage,
  OnboardText,
  OnboardButtonContainer,
} from './onboard-pages.styles.jsx';

import onboard4 from "../../assets/onboard4.png";

const PageFour = ({ onBoardComplete }) => {

  return (
    <OnboardPageContainer>
      {/* <OnboardImage>
        <img src={onboard4} alt="#"/>
      </OnboardImage> */}
      <OnboardText>
        <h1> BUT YOU STILL HAVE A CHANCE. When an item gets Grabbed, the PRICE resets AND STARTS DROPPING AGAIN.</h1>
      </OnboardText>
      <OnboardButtonContainer>
        <OnboardButton onClick={onBoardComplete}>GET GRABBIN!</OnboardButton>
      </OnboardButtonContainer>
    </OnboardPageContainer>
  );
};

export default PageFour;