import styled from "styled-components";

export const DelayedContainer = styled.div`
    width: 100%;
    height: 100%;

    * {
        transition: opacity 300ms ease;
        opacity: ${(props) => props.loading ? '0' : '1'};
    }
`