import styled from "styled-components";
import CountdownTimer from '../countdown-timer/countdown-timer.component';

import { Link } from "react-router-dom";
import { ReactComponent as DefaultCartIcon } from '../../assets/cart.svg';
import { grabbitColor } from "../../utils/styled/colors";

export const CartContainer = styled.div`
  display: flex;
  cursor: pointer;
  pointer-events: auto;
  margin-right: 0;
  max-width: 250px;
  max-height: 100%;
  justify-content: flex-end;
  align-items: center;
  flex-direction: row;
  font-size: 18px;
  color: ${grabbitColor.concentration};
  height: 50px;
`;

export const CartIcon = styled(DefaultCartIcon)`
  width: 20px;
  height: 20px;
  margin-left: 8px;
  cursor: pointer;
`;

export const GrabbedItemImageWrapper = styled.div`
  width: 50px;
  height: 50px;
  border-radius: 100%;
  border: 3px solid ${grabbitColor.ultrapatiencePurple};
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: ${grabbitColor.warpWhite};
  margin: 0 10px;
  padding: 2px;

  img{
      width: 100%;
      height: auto;
  }
`;

export const Timer = styled(CountdownTimer)`
  margin-right: 16px;
  width: 100px;
`;

export const Divider = styled.p`
  margin: 0 10px;
`;