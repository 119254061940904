import styled from "styled-components";
import { grabbitColor } from "../../utils/styled/colors";
import { device } from "../../utils/styled/breakpoints";

export const PressCardContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    padding: 8px;
    margin: 8px;
    width: 100%;
    max-width: 420px;
    min-width: 250px;
    height: 360px;
    flex: 1 1;
    border: 1px solid ${grabbitColor.warpWhite};
    border-radius: 24px;

    @media ${device.inBetweenMax} {
        height: 100%;
    }
`

export const PressInfoContainer = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    text-align: left;
    height: 100%;
    padding: 16px;
    flex-grow: 2;
    flex-shrink: 1;
    flex-basis: 50%;

    h6 {
        margin: 0;
    }

    h2 {
        margin: 8px 0;
    }

    h3 {
        margin: 0;

    }

    h4 {
        margin: 16px 0;
    }

    p {
        margin: 0 0 16px 0;
    }

    a{
        position: absolute;
        bottom: 16px;
        text-decoration: underline;
        text-underline-offset: 6px;

        @media ${device.inBetweenMax} {
            position: relative;
            margin-top: 16px;

        }
    }
    
`