import { getApp } from "firebase/app";
import { getStorage, ref, getDownloadURL } from "firebase/storage";
import { logger } from "../logger/logger.utils";

const app = getApp();
const storage = getStorage(app);

export const getImageFromStorage  = async (path) => {
  if(!path){
    logger.warn('No Path Provided')
    return;
  }
  const storageRef = ref(storage, path);
  const url = await getDownloadURL(storageRef);
  return url;
}
