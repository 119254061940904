import styled from "styled-components";
import { grabbitColor } from "../../../utils/styled/colors";
import {device} from '../../../utils/styled/breakpoints'

export const FormContainer = styled.div`
  width: 380px;
  max-width: 380px;
  margin: 4rem auto;
  padding: 32px;
  text-align: center;
  border: 1px solid ${grabbitColor.warpWhite};

  @media ${device.inBetween} {
      width: 90vw;
    }

  h2 {
    margin: 10px 0;
    color : ${grabbitColor.warpWhite};
  }

  span {
    color : ${grabbitColor.warpWhite};
  }
`;

export const SuccessContainer = styled.div`
`

export const ResetPasswordContainer = styled.div`
`

export const ServerMessage = styled.p`
    text-align: center;
    color : ${grabbitColor.warpWhite};

    &.error {
      color : ${grabbitColor.errorRed};
  }
`