import { useEffect, useState } from 'react';

import {
  PageContainer,
  HeaderContainer,
  TopInfoContainer,
  JoinButton,
  JoinButtonInvert,
  TopInfoH1,
  TopInfoH2,
  HomePageContentContainer,
  HowItWorksContainer,
  ScrollContainer,
  ScrollContent,
  ScrollItem,
  Phone
} from './home.styles';

import HowItWorksSteps from '../../components/how-it-works-steps/how-it-works-steps.component';
import SneakPeek from '../../components/sneak-peak/sneak-peek.component';
import BottomHomeBlock from '../../components/bottom-home-block/bottom-home-block.component';
import PromoSection from '../../components/promo-section/promo-section.component';
import { useNavigate } from 'react-router';

import { getPage } from '../../utils/firebase/firebase.utils';
import { getImageFromStorage } from "../../utils/firebase/storage.utlis";
import { logger } from '../../utils/logger/logger.utils';

const scrollingText = (horizontalCount = 8, verticalCount = 20) => {
  const items = Array.from({ length: horizontalCount }, (_, i) => i);
  const containers = Array.from({ length: verticalCount }, (_, i) => i);

  return (
    containers.map((container) => (
      <ScrollContent key={container}>
        {items.map((item) => (
          <ScrollItem key={item} animateReverse={container % 2 !== 0}>wait for it…</ScrollItem>
        ))}
      </ScrollContent>

    ))

  )
}

const HomePage = () => {
  const [itemCount, setItemCount] = useState(0);
  const [pageData, setPageData] = useState(null);
  const [headerImage, setHeaderImage] = useState(null);
  const navigate = useNavigate();

  const fetchPage = async () => {
    try {
      const pageData = await getPage('pages', '/');
      setPageData(pageData);
    } catch (error) {
      logger.error(error);
    }
  };

  const fetchHeaderImage = async (image_url) => {
    try {
      const image = await getImageFromStorage(image_url);
      setHeaderImage(image); // Set the fetched image in the state
    } catch (error) {
      logger.error("Error fetching images:", error);
    }
  };
  
  useEffect(() => {
    if(headerImage){
      return
    }
    fetchPage();
    fetchHeaderImage(pageData?.header_section.header_image); // Fetch the header image
  }, [pageData,headerImage]); // Add pageData as a dependency to trigger image fetching when it's available

  useEffect(() => {
    const itemHeight = 200; // Set the height of the ScrollItem
    const screenHeight = window.innerHeight;
    const newItemCount = Math.ceil(screenHeight / itemHeight);
    setItemCount(newItemCount);
  }, []);

  const handleClick = () => {
    navigate("/grabb")
  };

  return (
    <PageContainer>
      <HomePageContentContainer>
        <ScrollContainer>
          {scrollingText(8, itemCount)}
        </ScrollContainer>
        <HeaderContainer>
          <TopInfoContainer>
            <TopInfoH2>{pageData?.header_section.subhead}</TopInfoH2>
            <TopInfoH1>{pageData?.header_section.headline}</TopInfoH1>
            <span>{pageData?.header_section.body_copy}</span>
            <JoinButton onClick={handleClick}>{pageData?.header_section.button_text}</JoinButton>
          </TopInfoContainer>
          <Phone src={headerImage} />
        </HeaderContainer>
        {pageData?.promo_section.active ? <PromoSection promoData={pageData?.promo_section} /> :  ''}
        <HowItWorksContainer>
          <h1>HOW IT WORKS</h1>
          <HowItWorksSteps steps={pageData?.content_blocks} />
          <JoinButtonInvert onClick={handleClick}>{pageData?.header_section.button_text}</JoinButtonInvert>
        </HowItWorksContainer>
      </HomePageContentContainer>
    </PageContainer>
  )
}

export default HomePage;