import {
  Route,
  Routes,
  useLocation,
} from "react-router-dom";

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import Navigation from './routes/navigation/navigation.component';

import { InnerContainer, Main } from './app.styles'

import Grabbit from "./routes/grabbit/grabbit.route";
import HomePage from "./routes/home/home.route";
import Checkout from "./routes/checkout/checkout.route";
import Success from './routes/success/success.component';
import TimeOut from "./routes/timeout/timeout.component";
import Authentication from './routes/authentication/sign-in.route';
import Policies from "./routes/policies/policies.component";
import Footer from './components/footer/footer.component'
import HowItWorks from "./routes/how-it-works/how-it-works.route";
import Help from './routes/help/help.route';
import Suppliers from "./routes/suppliers/suppliers.component";
import OnboardFlow from "./routes/onboard/onboard-flow.component";
import Account from "./routes/account/account.component";
import CreateAccount from "./routes/authentication/create-account.route";
import Authenitication from './routes/authentication/authentication.route';
import NotFound from "./routes/not-found/not-found.route";
import FAQ from "./routes/faq/faq.route";
import PressRoom from "./routes/press/press.route";

//Redux
import { checkUserSession } from "./store/user/user.reducer";
import { fetchProductsAsync } from './store/products/products.reducer';
import { fetchQueueAsync } from "./store/queue/queue.reducer";
import { fetchSettingsAsync } from "./store/settings/settings.reducer";

import { selectCurrentUser } from "./store/user/user.selector";
import { GlobalStyles } from "./utils/styled/globalStyles";
import { selectCartCount } from "./store/cart/cart.selector";

import { LoggerContext } from "./utils/logger/logger.context";
import { logger } from "./utils/logger/logger.utils";
import AdminDrawer from "./components/admin-info-drawer/admin-info-drawer";
import { HelmetProvider } from "react-helmet-async";

const App = () => {
  const dispatch = useDispatch();
  const currentUser = useSelector(selectCurrentUser);
  const cartCount = useSelector(selectCartCount)
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(true);
  const [lastFetchTime, setLastFetchTime] = useState(Date.now());

  //Loading Listener
  useEffect(() => {
    const handleUnload = () => setIsLoading(true);
    window.addEventListener("beforeunload", handleUnload);
    return () => window.removeEventListener("beforeunload", handleUnload);
  }, []);

  useEffect(() => {
    const handleLoad = () => setIsLoading(false);
    window.addEventListener("load", handleLoad);
    return () => window.removeEventListener("load", handleLoad);
  }, []);

  //Fetch user data
  useEffect(() => {
    const fetchData = async () => {
      try {
        await dispatch(checkUserSession());
        await dispatch(fetchSettingsAsync());

        // Data fetch completed successfully
        setIsLoading(false); // Set isLoading to false once the data fetch is done
      } catch (error) {
        logger.error(error); // Handle any errors that may occur during data fetch
        setIsLoading(false); // Set isLoading to false even if there's an error
      }
    };

    fetchData();
  }, []);

  //Fetch product data
  useEffect(() => {
    const fetchProductData = async () => {
      try {
        await dispatch(fetchProductsAsync());
        await dispatch(fetchQueueAsync());

        // Data fetch completed successfully
        setIsLoading(false); // Set isLoading to false once the data fetch is done
      } catch (error) {
        // Handle any errors that may occur during data fetch
        logger.error(error);
        setIsLoading(false); // Set isLoading to false even if there's an error
        setLastFetchTime(Date.now()); // Set last fetch for data throttle
      }
    };

    fetchProductData();

    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        const currentTime = Date.now();
        // Throttle period in milliseconds (5 minutes)
        const throttlePeriod = 300000;

        if (currentTime - lastFetchTime > throttlePeriod) {
          fetchProductData();
        }
      }
    };
    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);
  const helmetContext = {};


  return (
    <LoggerContext.Provider value={logger}>
      <Main>
        <HelmetProvider context={helmetContext}>
          <GlobalStyles />
          <InnerContainer>
            {currentUser && (currentUser.role > 3) && <AdminDrawer />}
            <Routes>
              {/* Routes accessible to all users */}
              <Route path="/" element={<Navigation />}>
                <Route path="/" element={<HomePage />} />
                <Route path="/home" element={<HomePage />} />
                <Route path="/grabb" element={<Grabbit />} />
                <Route path="/policies/*" element={<Policies />} />
                <Route path="/how-it-works" element={<HowItWorks />} />
                <Route path="/help" element={<Help />} />
                <Route path="/suppliers" element={<Suppliers />} />
                <Route path="/sign-in" element={<Authentication />} />
                <Route path="/sign-up" element={<Authentication />} />
                <Route path="/auth/*" element={<Authenitication />} />
                <Route path="/create" element={<CreateAccount />} />
                <Route path="/cart" element={<Grabbit />} />
                <Route path="/checkout" element={<Checkout />} />
                <Route path="/faq" element={<FAQ />} />
                <Route path="/success/*" element={<Success />} />
                <Route path="/press/*" element={<PressRoom />} />
              </Route>

              {/* Routes accessible only to logged-in users*/}
              {currentUser ? (
                <>
                  <Route path="/" element={<Navigation />}>
                    <Route path="/grabb" element={<Grabbit />} />
                    <Route path="/timeout" element={<TimeOut />} />
                    <Route path="/account/*" element={<Account />} />
                    <Route path="/onboard" element={<OnboardFlow />} />
                  </Route>
                </>
              ) : null}

              {/* Routes accessible only to Admin users */}
              {(currentUser && currentUser.role >= 3) ? (
                <>
                  <Route path="/" element={<Navigation />}>
                  </Route>
                </>
              ) : null}

              {/* 404 Route */}
              <>
                <Route path="/" element={<Navigation />}>
                  <Route path="*" element={<NotFound />} />
                </Route>
              </>
            </Routes>
          </InnerContainer>
          <Footer />
        </HelmetProvider>
      </Main>
    </LoggerContext.Provider>

  );
};


export default App;