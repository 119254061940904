import { useEffect, useState } from 'react';
import { getDocumentByPath } from '../../utils/firebase/firebase.utils';
import { logger } from '../../utils/logger/logger.utils';
import {
  Step,
  PageContainer,
  HowItWorksH1, // Note: HowItWorksH1 seems unused
  StepContainer,
} from './how-it-works-steps.styles';

const HowItWorksSteps = ({ steps, columns = 2 }) => {
  const [stepData, setStepData] = useState({});

  // Fetch step data
  useEffect(() => {
    const fetchBlocks = async () => {
      const tempData = {};
      try {
        for (const step of steps) {
          const doc = await getDocumentByPath(step.path);
          tempData[step.id] = doc; // Use unique identifier like `id` for keys
        }
        setStepData(tempData);
      } catch (error) {
        logger.error('Error fetching content block data: ', error);
      }
    };

    fetchBlocks();
  }, [steps]); // Add `steps` as a dependency

  return (
    <PageContainer>
      <StepContainer columns={columns}>
        {Object.entries(stepData).map(([id, step], index) => (
          <Step key={id}> {/* Use `id` or a combination that's unique for the key */}
            <h3>{index + 1}</h3>
            <h4>{step.block_heading}</h4>
            {step.block_body}
          </Step>
        ))}
      </StepContainer>
    </PageContainer>
  );
};

export default HowItWorksSteps;
