import supplier from "../../assets/supplier.jpg";
import SupplierForm from "./supplier-form.component";

import { 
  PageContainer,
  SupplierImage,
  TopInfoContainer,
  InfoContainer,
  EmojiContainer,
  MainSupplierContainer,
 } from "./suppliers.styles";

 window.scrollTo({top: 0, left: 0, behavior: 'smooth'});

const Suppliers = () => {
  return (
    <PageContainer>
      <SupplierImage>
        <img src={supplier} alt="#"/>
      </SupplierImage>
      <MainSupplierContainer>
        <TopInfoContainer>
          <h1>THE ECOMMERCE THUNDERDOME</h1>
          <p>Grabbit is designed to help brands, wholesalers, retailers and others promote their products while connecting with a group of savvy consumers. It's an ideal venue to create buzz around a new product, host a unique promotion or list excess inventory and still make money, rather than trying to lose less through liquidation.</p>
        </TopInfoContainer>
        <InfoContainer>
            <EmojiContainer>🚀🚀🚀</EmojiContainer>
            <h2>Send Your Product Launch To The Moon</h2>
            <p>Grabbit fuses the motivation to save with the urgency of scarcity, the fear of missing out and the reward for timing things just right. Boost your new product launch with scale and built-in excitement.</p>
        </InfoContainer>
        <InfoContainer>
        <EmojiContainer>👁👄👁</EmojiContainer>
            <h2>Move Bonus Volume And Grab Some Eyeballs</h2>
            <p>Group buying schemes, discounts, BOGOs and other tired techniques degrade your brand. Grabbit can efficiently drive sales volume, engage a new audience and spur lapsed customers back into action.</p>
        </InfoContainer>
        <InfoContainer>
        <EmojiContainer>👵👴</EmojiContainer>
            <h2>Just Because It’s Dusty Doesn’t Mean It’s Done</h2>
            <p>Every warehouse needs some spring cleaning. Grabbit can help you move old inventory to make way for that new-new while still hitting your topline sale price with no net margin loss. This is what makes our moms proud.</p>
        </InfoContainer>
        <InfoContainer>
        <EmojiContainer>🙈🌊</EmojiContainer>
            <h2>Why Would You Liquidate When You Can Grabbit-ate?</h2>
            <p>That sentence may be forced and awkward, but nothing's more awkward than taking a hit. Liquidation is an embarrassing last resort. Don’t do it. With Grabbit you can still make a profit instead of taking a loss.</p>
        </InfoContainer>
        <p>Hit us up at <a href="mailto:supplier@gograbb.it" target="_blank" rel="noreferrer">supplier@gograbb.it</a> to make it happen. Or fill out this form if that’s more your thing.</p>
        <SupplierForm />
      </MainSupplierContainer>
    </PageContainer>
  )
};

export default Suppliers;