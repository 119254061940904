import { combineReducers } from "@reduxjs/toolkit";

import { userReducer } from "./user/user.reducer";
import { cartReducer } from "./cart/cart.reducer";
import { productsReducer } from './products/products.reducer';
import { queueReducer } from "./queue/queue.reducer";
import { modalsReducer } from "./modal/modals.reducer";
import { settingsReducer } from "./settings/settings.reducer";

export const rootReducer = combineReducers({
    user: userReducer,
    cart: cartReducer,
    products: productsReducer,
    queue: queueReducer,
    modals: modalsReducer,
    settings: settingsReducer,
});