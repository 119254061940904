import styled from "styled-components";
import { Link } from "react-router-dom";
import { device } from "../../utils/styled/breakpoints";
import { grabbitColor } from "../../utils/styled/colors";
import { ReactComponent as HamburgerIcon } from '../../assets/menu.svg';
import { ReactComponent as GrabbitIcon } from '../../assets/logo.svg';
import { ReactComponent as GrabbitWordMark } from '../../assets/grabbit_wordmark.svg';


export const NavigationContainer = styled.div`
  height: 70px;
  width: 100vw;
  display: flex;
  justify-content: space-between;
  background-color: ${grabbitColor.flashYellow};
  color: ${grabbitColor.concentration};
  padding: 0px 20px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999;
  box-shadow: 0px 2px 8px ${grabbitColor.alpha('concentration', 0.25)};

`
export const LogoContainer = styled(Link)`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`

export const Logo = styled(GrabbitIcon)`
  width: auto;
  height: 50px;
  margin: 0 6px 0 0;
`

export const WordMark = styled(GrabbitWordMark)`
  cursor: pointer;
  height: 32px;
  display: flex;
 `

export const NavLinksContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`
export const NavLink = styled(Link)`
  padding: 10px 15px;
  cursor: pointer;
  color: ${grabbitColor.concentration};

  @media ${device.tabletMax} {
    display: none;
  }
`;

export const NavLinkButton = styled(Link)`
  padding: 10px 15px;
  margin-right: 10px;
  cursor: pointer;
  border-color: ${grabbitColor.ultrapatiencePurple};
  color: ${grabbitColor.ultrapatiencePurple};
  border: 2px solid;
  border-radius: 6px;

  @media ${device.tabletMax} {
    display: none;
  }
`;

export const BigMenuLinks = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
  padding: 1rem;
  transition: letter-spacing 0.3s ease;
  text-align: right;
`;

export const OffCanvasLink = styled(Link)`
    color: ${grabbitColor.concentration};
    font-size: 1.75rem;
    letter-spacing: 0px;
    font-weight: 700;
    margin-bottom: 1rem;
    transition: letter-spacing 0.3s ease;
    cursor: pointer;
    pointer-events: all;

  @media (hover: hover){
    &:hover{
    letter-spacing: 1px;
    font-weight: 900;
    }
  }
`


export const StyledHamburgerIcon = styled(HamburgerIcon)`
  width: 50px;
  height: 50px;
  transition: transform 0.3s ease;
  transform-origin: center;

  & g {
    transition: transform 0.3s ease;
    transform-origin: center;
  }

  &:hover {
    #top {
      transform: translateY(5px);
    }
    #mid {
      transform: translateY(0px);
    }
    #bot {
      transform: translateY(-5px);
    }
  }

  &.open {
    #top {
      transform: translateY(7px) translateX(-5px) rotate(45deg);
    }
    #mid {
      opacity: 0; // Hide the middle line
    }
    #bot {
      transform: translateY(-5px) translateX(-5px) rotate(-45deg);
    }
  }
`;



export const HamburgerMenu = styled.div`
  z-index: 10000;
  top: 1rem;
  left: 30rem;
  cursor: pointer;

  @media ${device.inBetween} {
    display: none;
  }
  
  @media ${device.tabletMax} {
    display: flex;
  }

`;


export const MenuContainer = styled.div`
  display: block;
  position: fixed; /* Use fixed instead of absolute */
  top: 70px;
  right: 0;
  background-color: ${grabbitColor.flashYellow};
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 16px;
  height: 100vh;
  width: 100vw; /* Set the width to 100vw */
  transform: translateX(100%); /* Initially, move it off the screen */

  @media ${device.inBetween} {
    display: none;
  }
  
  @media ${device.tabletMax} {
    display: unset;
  }

    &.opened {
    transition: transform 0.3s ease;
    width: 100vw;
    transform: translateX(0);
  }

  &.closed {
    transition: transform 0.3s ease;
    width: 0;
  }
`;




export const CloseButton = styled.button`
  position: relative;
  bottom: 1rem;
  left: 17rem;
  font-size: 1.5rem;
  cursor: pointer;
  background-color: transparent;
  border: none;
  z-index: 100000;

  @media ${device.mobileMax} {
    left: 22rem;
  }
`;
