import React, { useEffect, useState } from "react";
import { CheckoutFormContainer, CheckoutInfoContainer } from './checkout-form.styles';
import { PayPalButtons, usePayPalScriptReducer } from "@paypal/react-paypal-js";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { selectCartItems, selectCartTotal } from "../../../store/cart/cart.selector";
import { logger } from "../../../utils/logger/logger.utils";
import { applyPromoCode, clearAllCartItems, fetchCouponsAsync } from "../../../store/cart/cart.reducer";
import CouponCodeField, { PromoCodeValidation } from "../../../features/coupon-code/components/coupon-code-field";
import FormInput from "../../inputs/form-input/form-input.component";
import { selectActiveCoupons } from "../../../store/cart/cart.selector";

// Renders errors or successful transactions on the screen.
function Message({ content }) {
  return <p>{content}</p>;
}

const CheckoutForm = () => {
  const dispatch = useDispatch();
  const cartItems = useSelector(selectCartItems);
  const cartTotal = useSelector(selectCartTotal);
  const subtotalDisplay = (cartTotal / 100).toLocaleString("en-US", { style: "currency", currency: "USD" });
  const [message, setMessage] = useState("");
  const navigate = useNavigate();
  const [promocode, setPromocode] = useState('');
  const [isOpen, setIsOpen]= useState(false);
  const coupons = useSelector(selectActiveCoupons);

  useEffect(() => {
    dispatch(fetchCouponsAsync())
  
  }, [dispatch])
  

  const onCreateOrder = (data, actions) => {
    console.log('Data is: ', data)
    return actions.order.create({
      purchase_units: [
        {
          reference_id: cartItems[0].id,
          amount: {
            value: cartTotal / 100, //TODO FIX THIS HOLDOVER FROM STRIPE
          },
          shipping: {
            options: [
              {
                id: "001",
                type: "SHIPPING",
                label: "Ground",
                selected: true,
                amount: {
                  currency_code: "USD",
                  value: "1",
                },
              },
            ],
          } ,
        },
      ],
    });
  };

  const onApproveOrder = (data, actions) => {
    return actions.order.capture().then((details) => {
      const { status, purchase_units } = details;
      const { reference_id, amount } = purchase_units[0];
      dispatch(clearAllCartItems());
      navigate(`/success?status=${status}&reference_id=${reference_id}&val=${amount.value}`)
    });
  };

  const onSubmitCode = async () => {
    const isCodeValid = await PromoCodeValidation(coupons, promocode)

    if(isCodeValid){
      dispatch(applyPromoCode(promocode))
    }
    logger.log(isCodeValid)
  }

  const [{ isPending, isRejected, isResolved }] = usePayPalScriptReducer();

  if(!cartItems){
    return(
      <>
      Nope.
      </>
    )
  }
  return (
    <CheckoutFormContainer>
      <CheckoutInfoContainer>
        <div>
          <h3>{cartItems[0].name}</h3>
          <p>{subtotalDisplay}</p>
        </div>

        {/* {isOpen ?
        <div>
        <FormInput
          label="PROMOCODE"
          name="promocode"
          value={promocode}
          onChange={(event) => setPromocode(event.target.value)}
          key='promocode'
        /> 
        <div className='submit-button' onClick={onSubmitCode}>Submit</div>
        </div>
        : <p style={{ fontSize: '12px' }} className='code-link' onClick={() => setIsOpen(true)}>Add a promo or gift code</p>} */}

        </CheckoutInfoContainer>
      <PayPalButtons
        className="paypal-container"
        style={{
          layout: "vertical",
          disableMaxWidth: true,
        }}
        createOrder={(data, actions) => onCreateOrder(data, actions)}
        onApprove={(data, actions) => onApproveOrder(data, actions)}
        onError={(err) => {
          console.error(err);
          setMessage("An error occurred during the transaction.");
        }}
      />
      <Message content={message} />
    </CheckoutFormContainer>
  );
};

export default CheckoutForm;