import styled from 'styled-components';
import { device } from '../../utils/styled/breakpoints';
import { grabbitColor } from '../../utils/styled/colors';


export const MainContainer  = styled.div`
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
z-index: 1;
width: 100%;

& h2{
    font-size: 2rem;
    margin:  0;
    color: ${grabbitColor.flashYellow}
}

& p{
    font-size: 1.25rem;
    margin: 0 0 2rem 0;
    color: ${grabbitColor.flashYellow}
}
`

export const LaunchTimerContainer = styled.div`
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
width: 100%;
height: 100%;
max-width: 1256px;
max-height: 1256px;
border-radius: 24px;
font-weight: bold;
text-align: center;


hr{
    width: 100%;
    margin: 2rem auto;
}

& h1{
    margin: 1rem 0 0 0;
    font-size: 3rem;
    text-transform: uppercase;
    width: 75%;
}
`
export const EventImage = styled.div`
  width: 100%;
  height: 50vw;
  max-width: 500px;
  max-height: 500px;
  min-width: 300px;
  min-height: 300px;
  background-image: url(${(props) => (props.promoImage)});
  background-size: cover;
  background-position: center;
  z-index: 1;
`;

export const LaunchTimerWrapper = styled.div`
margin-bottom: 1rem;
color: ${grabbitColor.ultrapatiencePurple_600};

@media ${device.mobileS} {
    font-size: 24px;
}

@media ${device.mobileM} {
    font-size: 28px;
}

@media ${device.mobileL} {
    font-size: 28px;
}

@media ${device.mobileXL} {
    font-size: 30px;
}

@media ${device.inBetween} {
    font-size: 32px;
}

@media ${device.tablet} {
    font-size: 50px;
}

@media ${device.laptop} {
    font-size: 75px;
}

@media ${device.desktop} {
    font-size: 120px;
}
`

export const BackgroundWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
  border-radius: 20px;
  z-index: 0;
  top: 10rem;
  left: 0;
`