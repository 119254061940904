import React, { useState, useEffect } from 'react';

const RandomCharacters = ({ set = 'all', count = 5, speed = 1000 }) => {
  const [randomString, setRandomString] = useState('');

  const NUMBERS = '0123456789';
  const LETTERS = 'abcdefghijklmnopqrstuvwxyz';
  const ALL = NUMBERS + LETTERS;

  const generateRandomString = (length, charSet) => {
    let result = '';
    const charactersLength = charSet.length;
    for (let i = 0; i < length; i++) {
      result += charSet.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  };

  useEffect(() => {
    let interval;
    if (speed > 0) {
      interval = setInterval(() => {
        switch (set) {
          case 'numbers':
            setRandomString(generateRandomString(count, NUMBERS));
            break;
          case 'letters':
            setRandomString(generateRandomString(count, LETTERS));
            break;
          default:
            setRandomString(generateRandomString(count, ALL));
        }
      }, speed);
    } else {
      // Generate once if speed is 0 or not defined
      switch (set) {
        case 'numbers':
          setRandomString(generateRandomString(count, NUMBERS));
          break;
        case 'letters':
          setRandomString(generateRandomString(count, LETTERS));
          break;
        default:
          setRandomString(generateRandomString(count, ALL));
      }
    }

    return () => clearInterval(interval); // Clear interval on component unmount
  }, [set, count, speed]);

  return <>{randomString}</>;
};

export default RandomCharacters;
