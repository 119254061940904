import { Link } from 'react-router-dom';
import {
  IconContainer,
  FBicon,
  IGicon,
} from './social-icons.styles'

const SocialIcons = ({color}) => {

  return (
      <IconContainer>
          <Link to='https://facebook.com/readysetgrabbit' target='_blank' ><FBicon color={color} /></Link>
          <Link to='https://instagram.com/readysetgrabbit' target='_blank' ><IGicon color={color} /></Link>
      </IconContainer>
  )
};

export default SocialIcons;