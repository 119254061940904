import { createSelector } from 'reselect';

const selectCartReducer = (state) => state.cart;

export const selectCartItems = createSelector(
  [selectCartReducer],
  (cart) => cart.cartItems
);

export const selectIsCartOpen = createSelector(
  [selectCartReducer],
  (cart) => cart.isCartOpen
);

export const selectCartTotal = createSelector(
  [selectCartReducer],
  (cart) => {
    const cartItems = cart.cartItems;
    const codeValue = cart.codeValue || 0; // Default to 0 if codeValue is not defined

    return Array.isArray(cartItems)
      ? cartItems.reduce(
        (total, cartItem) => total + cartItem.quantity * cartItem.price,
        0
      ) 
      : '0';
  }
);

export const selectCartCount = createSelector(
  [selectCartItems], (cartItems) =>
  Array.isArray(cartItems)
    ? (cartItems.reduce(
      (total, cartItem) => total + cartItem.quantity,
      0
    ))
    : '0'
);

export const selectCartTimeOut = createSelector(
  [selectCartReducer],
  (cart) => cart.cartTimeOut
);

export const selectCheckoutSession = createSelector(
  [selectCartReducer],
  (cart) => cart.checkoutSession
);

export const selectActiveCoupons = createSelector(
  [selectCartReducer],
  (cart) => cart.activeCoupons
)