import styled from "styled-components";
import { grabbitColor } from "../../utils/styled/colors";

export const TabViewContainer = styled.div`
    width: 100%;
    max-width: 800px;
    height: 100%;
    display: flex;
    flex-direction: column;
    background-color: ${grabbitColor.enduranceDarkGray};
    padding: 1rem;
    border-radius: 24px;
    position: relative;
    margin: 1rem auto;
    transition: all .5s;
`

export const TabContainer = styled.div`
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 1rem 0;
`

export const Tab = styled.div`
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 200px;
    height: 2rem;
    padding: 1rem;
`
export const InkBar = styled.div`
    background-color: none;
    height: 5px;
    width: 100%;
    position: absolute;
    left: 0;
    bottom: -1rem;
    transition: all .5s;
    z-index: 1;

    &.active {
        background-color: ${grabbitColor.flashYellow};
    }
`

export const Separator = styled.div`
    background-color: ${grabbitColor.enduranceLightGray};
    height: 5px;
    width: 100%;
    margin-top: -5px;
    z-index: 0;
`

export const Panel = styled.div`
    width: 100%;
    padding: 1rem;
    display: none;

    &.active {
        display: block;
    }

`