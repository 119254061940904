import React, { useState, useEffect } from 'react'
import { PromoBoxContainer, PromoButton, PromoImage, PromoImageScaffold } from './promo-box.styles'
import { logger } from '../../utils/logger/logger.utils'
import { getDocumentByPath, getPage } from '../../utils/firebase/firebase.utils'
import Scaffold from '../scaffold/scaffold.component'
import { getImageFromStorage } from '../../utils/firebase/storage.utlis'

const PromoBox = ({ promo_id, className, ...props }) => {
    const [isPromoLoading, setPromoIsLoading] = useState(false);
    const [promoData, setPromoData] = useState('');
    const [product, setProduct] = useState({});
    const [image, setImage] = useState('');

    //Fetch promo data
    useEffect(() => {
        const fetchPromo = async () => {
            if(!promo_id){
                return
            }
            try {
                const doc = await getDocumentByPath(`${promo_id}`);
                setPromoData(doc);

            } catch (error) {
                logger.error('Error fetching promotion data: ', error)
            }
        }

        fetchPromo();
    }, [promo_id])

    //Fetch promo image from firebase storage
    useEffect(() => {
        const fetchPromoImage = async () => {
            try {
                const result = await getImageFromStorage(promoData.image);
                setImage(result);
                setPromoIsLoading(false);

            } catch (error) {
                logger.error("Error fetching images:", error);
            }
        };

        fetchPromoImage();
    }, [promoData]);


    //Get the product for the promo
    useEffect(() => {

        const fetchPromoProduct = async () => {
            if(!promoData){
                return
            }
            try {
                const swaggProduct = await getDocumentByPath(promoData.product.path);

                const newProduct = {
                    name: swaggProduct.name,
                    price: (swaggProduct.retailPrice * 100).toFixed(0),
                    imageUrl: image,
                    taxCode: swaggProduct.taxCode,
                    id: swaggProduct.id,
                    checkoutFields: swaggProduct.checkout_fields,
                    shippingCost: swaggProduct.shippingCost,
                    adjustable_quantity: {
                        enabled: true,
                        minimum: 0,
                        maximum: 10,
                    },
                }
                setProduct(newProduct);

            } catch (innerError) {
                logger.error('Error fetching product data: ', innerError);
            }
        };

        fetchPromoProduct();

    }, [promoData]);



    const paymentHandler = async (event) => {
        event.preventDefault();

        if (!product) {
            logger.error('No Product');
            return;
        }

        let cartProduct = {
            price: product.price,
            name: product.name,
            productId: product.id,
            images: [product.cart_image],
            id: product.id,
            taxCode: product.taxCode,
            checkoutFields: product.checkoutFields,
            shippingCost: product.shippingCost || 0,
            quantity: 1,
            adjustable_quantity: {
                enabled: true,
                minimum: 0,
                maximum: 10,
            },
        }
        let items = [cartProduct];

        const response = await fetch("/.netlify/functions/create-stripe-session", {
            method: "POST",
            headers: {
                "content-type": "application/json",
            },
            body: JSON.stringify({
                cartItems: items,
                method: 'hosted',
            }),
        });

        if (!response.ok) {
            const errorText = await response.text();
            logger.error('Error response from server:', errorText);
            return; // Return to prevent further execution in case of error
        }

        const session = await response.json();
        window.location.href = session.url;
    };

    //Scaffold for
    const PromoScaffold = () => {
        return (
            <>
                <Scaffold type={'text'} />
                <Scaffold type={'text'} />
                <PromoImageScaffold />
                <Scaffold type={'button'} />
            </>
        )
    }

    return (
        <PromoBoxContainer className={className} promoData={promoData} props={props}>
            {isPromoLoading ? <PromoScaffold /> :
                <>
                    <h2>{promoData.headline || ''}</h2>
                    <h3>{promoData.subhead || ''}</h3>
                    <PromoImage src={image} />
                    {/* {product.name ? <PromoButton onClick={paymentHandler}>{promoData.button_text || 'Buy Now'}</PromoButton> : ''} */}
                </>
            }

        </PromoBoxContainer>
    )
}

export default PromoBox