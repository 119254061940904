import React, { useState } from 'react'
import { Panel, TabViewContainer, Tab, TabContainer, InkBar, Separator } from './tab-view.styles'

const TabView = ({ tabData }) => {
    const [activeTab, setActiveTab] = useState(0);

    const data = tabData || [];

    const changeTab = ((tabIndex) => {
        setActiveTab(tabIndex)
    })

    return (
        <TabViewContainer>
            <TabContainer>
                {data.map((tab, index) => {
                    return (
                        <Tab className={activeTab === index ? 'active' : ''} key={index} onClick={() => changeTab(index)}>
                            {tab.heading}
                            <InkBar className={activeTab === index ? 'active' : ''} />
                        </Tab>
                    )
                })}
            </TabContainer>
            <Separator />
            {data.map((tab, index) => {
                return (
                    <Panel className={activeTab === index ? 'active' : ''} key={index}>
                        {tab.body}
                    </Panel>
                )
            })}
        </TabViewContainer>
    )
}

export default TabView