import React, { useEffect, useState } from 'react';

import TopInfoContainer from "../../top-info/top-info-grabb.component";
import GrabbDescription from "../../grabb-description/grabb-description.component";
import GrabbImage from "../../grabb-image/grabb-image.component";
import { CardContainer, InfoContainer } from "../../grabb-card.styles";
import { useSelector } from 'react-redux';
import { selectCartCount, selectCartItems, selectCartTotal } from '../../../../store/cart/cart.selector';
import Checkout from '../../../../routes/checkout/checkout.route';
import PriceDisplay from '../price-display/price-display.component';
import NameDisplay from '../name-display/name-display.component';
import { BottomContainer, GrabbPromo } from './default-grabb-display.styles';
import { logger } from '../../../../utils/logger/logger.utils';
import { getPage } from '../../../../utils/firebase/firebase.utils';
import { useNavigate } from 'react-router';

const DefaultDisplay = () => {
  const cartCount = useSelector(selectCartCount);
  const cartTotal = useSelector(selectCartTotal)
  const cartItems = useSelector(selectCartItems);
  const [page, setPage] = useState({});
  const navigate = useNavigate();
  const cartTotalDisplay = (cartTotal / 100).toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2, })


  useEffect(() => {
    const fetchPage = async () => {
      try {
        const pageData = await getPage('pages', 'grabb');
        setPage(pageData);
      } catch (error) {
        logger.error(error);
      }
    };

    fetchPage()

  }, [])

  useEffect(() => {
    if(cartCount > 0){
      navigate('/checkout')
    } 
  }, [cartCount])

  return (
    <CardContainer>
      {cartCount > 0 ?
        <GrabbImage price={cartTotalDisplay} image={cartItems[0].image}/>
        :
        <GrabbImage />
      }
      <InfoContainer>
          <>
            <NameDisplay />
            <PriceDisplay />
            <TopInfoContainer />
          </>
      </InfoContainer>
      <BottomContainer>
        {
          cartCount < 1 ?
            <>
              <GrabbDescription />
              <GrabbPromo promo_id={page?.promo_section?.path} />
            </>
            :
            ''
        }
      </BottomContainer>
    </CardContainer>
  );
};

export default DefaultDisplay;