import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { logger } from '../../utils/logger/logger.utils';
import { getCollectionAndDocuments } from '../../utils/firebase/firebase.utils';

const CART_INITIAL_STATE = {
  activeCoupons: [],
  promoCode: '',
  codeValue: 0,
  isCartOpen: false,
  cartItems: [],
  cartTimeOut: null,
  error: null,
};

const fetchCoupons = async () => {
  const allActiveCoupons = await getCollectionAndDocuments('coupon_codes')
  return allActiveCoupons;
};


const addPromoCode = (activeCoupons, promoCode) => {
  if(promoCode === undefined) return;
  const cleanedString = promoCode.toUpperCase();

  const promoCodeObject = activeCoupons.find((obj) => obj.code === cleanedString);
  return promoCodeObject.value;
}

const addCartItem = (state, productToAdd) => {
  logger.log('Adding item to cart');

  let cartItems = state.cartItems;

  // Find if the item already exists in the cart
  if (!cartItems) { cartItems = [] }
  const existingCartItem = cartItems.find(
    (cartItem) => cartItem.id === productToAdd.id
  );

  // If the item exists, increase its quantity
  if (existingCartItem) {
    return cartItems.map((cartItem) =>
      cartItem.id === productToAdd.id
        ? { ...cartItem, quantity: 1 }
        : cartItem
    );
  }

  // If the item does not exist, add it to the cart
  return [...cartItems, { ...productToAdd, quantity: 1 }];
};


const removeCartItem = (cartItems, cartItemToRemove) => {
  // find the cart item to remove
  const existingCartItem = cartItems.find(
    (cartItem) => cartItem.id === cartItemToRemove.id
  );

  // check if quantity is equal to 1, if it is remove that item from the cart
  if (existingCartItem.quantity === 1) {
    return cartItems.filter((cartItem) => cartItem.id !== cartItemToRemove.id);
  }

  // return back cartitems with matching cart item with reduced quantity
  return cartItems.map((cartItem) =>
    cartItem.id === cartItemToRemove.id
      ? { ...cartItem, quantity: cartItem.quantity - 1 }
      : cartItem
  );
};


const clearAllItems = (state) => {
  state.codeValue = 0;
  state.paymentStatus = null;
  state.cartTimeOut = null;
  state.cartItems = [];
}


const clearCartItem = (state, cartItemToClear) => {
  state.codeValue = 0;
  state.cartItems.filter((cartItem) => cartItem.id !== cartItemToClear.id);
}

export const cartSlice = createSlice({
  name: 'cart',
  initialState: CART_INITIAL_STATE,
  reducers: {
    setIsCartOpen(state, action) {
      state.isCartOpen = action.payload;
    },
    toggleIsCartOpen(state) {
      state.isCartOpen = !state.isCartOpen;
    },
    addItemToCart(state, action) {
      state.cartItems = addCartItem(state, action.payload);
    },
    removeItemFromCart(state, action) {
      state.cartItems = removeCartItem(state.cartItems, action.payload);
    },
    clearItemFromCart(state, action) {
      state.cartItems = clearCartItem(state, action.payload);
    },
    setCartTimeOut(state, action) {
      state.cartTimeOut = action.payload;
    },
    clearAllCartItems(state) {
      state.cartItems = clearAllItems(state);
    },
    applyPromoCode(state, action) {
      state.codeValue = addPromoCode(state.activeCoupons, action.payload);
    },
    removePromoCode(state) {
      state.codeValue = addPromoCode({ code: '', value: 0 });
    },
    getCouponsStart(state) {
      state.activeCoupons = [];
    },
    getCouponsSuccess(state, action) {
      state.activeCoupons = action.payload;
    },
    getCouponsFailure(state, action) {
      state.error = action.payload;
    }

  },
});


export const {
  setIsCartOpen,
  toggleIsCartOpen,
  addItemToCart,
  removeItemFromCart,
  clearItemFromCart,
  clearAllCartItems,
  setCartTimeOut,
  removePromoCode,
  getCouponsStart,
  getCouponsSuccess,
  getCouponsFailure,
  applyPromoCode,
} = cartSlice.actions;


export const fetchCouponsAsync = () => async (dispatch) => {
  try {
    dispatch(getCouponsStart());
    const coupons = await fetchCoupons();
    dispatch(getCouponsSuccess(coupons));
  } catch (error) {
    dispatch(getCouponsFailure(error));
  }
};

export const cartReducer = cartSlice.reducer;