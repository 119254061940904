import React from 'react'
import { useRealtimeData } from '../../utils/firebase/realtime-database.utils'
import RandomCharacters from '../random-characters/random-characters.component'


const RealTimePrice = () => {
    const realTimePrice = useRealtimeData({collectionName:'currentsale', documentName:'currentprice'}) || 0
    const displayPrice =  (realTimePrice).toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2, })
    
    return <h2>${realTimePrice ? displayPrice : <><RandomCharacters set='numbers' count={2} speed={1} />.<RandomCharacters set='numbers' count={2} speed={1} /></>}</h2>

}

export default RealTimePrice