import React, { useState } from 'react'
import { DrawerContainer, Line, OpenToggle } from '../admin-info-drawer/admin-info-drawer.styles'
import { useSelector } from 'react-redux';
import { selectQueueProducts } from '../../store/queue/queue.selector';
import { useRealtimeData } from '../../utils/firebase/realtime-database.utils';
import Toggle from '../inputs/toggle/toggle.component';
import Button from '../button/button.component';
import { triggerDropperProxy } from '../grabb-card/utility-functions/cart.utils';

const AdminDrawer = () => {
  const [isOpen, setIsOpen] = useState(false);

  const currentSaleProduct = useRealtimeData({collectionName: "currentsale", documentName: "product"})
  const viewersData = useRealtimeData({collectionName: "currentsale", documentName: "viewers"})
  const bonusViewersData = useRealtimeData({collectionName: "currentsale", documentName: "bonusviewers"})
  const paidQuantity = useRealtimeData({collectionName: "currentsale", documentName: "paidQuantity"})


  const viewers = viewersData ? Object.keys(viewersData).length - 1 : 0;
  const bonusViewers = parseInt(bonusViewersData, 10) || 0;
  const environment = process.env.NODE_ENV;
  const version = JSON.stringify(require("../../../package.json").version)

  const toggleOpen = () => {
    setIsOpen(!isOpen)
  }

  const trigger = async () => {
    triggerDropperProxy('grabbed');
  }

  return (
    <DrawerContainer open={isOpen}>
      <OpenToggle onClick={toggleOpen} open={isOpen} />
      <Line><h3>Environment</h3><p>{environment}</p></Line>
      <Line><h3>Version</h3><p>{version}</p></Line>
    </DrawerContainer>
  )
}

export default AdminDrawer