import React from 'react'
import { NameDisplayContainer } from './name-display.styles'
import { useSelector } from 'react-redux'
import Scaffold from '../../../scaffold/scaffold.component'
import { useRealtimeData } from '../../../../utils/firebase/realtime-database.utils'

const NameDisplay = ({name}) => {
    const grabbName = useRealtimeData({collectionName: "currentsale", documentName: "product"})?.name

    return (
        <>
        {name ?
        <NameDisplayContainer>{name}</NameDisplayContainer>
        :
        grabbName ?
        <NameDisplayContainer>{grabbName}</NameDisplayContainer>
        :
        <NameDisplayContainer><Scaffold type={'text'} /></NameDisplayContainer>
        }
        </>

    )
}

export default NameDisplay